import React from 'react'
import clsx from 'clsx'
import ComponentCard from './ComponentCard'
import Link from '../Link'
import Icon from '../Icon'
import Flex from '../Flex'
import Grid from '../Grid'
import Text from '../typography/Text'
import Detail from '../typography/Detail'
import AvatarInitials from '../AvatarInitials'
import IconCircle from '../IconCircle'

import useTeamHoldingAccount from '../../hooks/useTeamHoldingsAccount'

import { useTheme } from '@emotion/react'

import { ColorType } from '../../utils/themes'
import { getAmount } from '../../utils/functions'
import useIsSidekick from '../../hooks/useIsSidekick'
import useCurrentUser from '../../hooks/useCurrentUser'

type AccountCardType = {
  subtitle?: string
  bankAccount: any
  user?: any
  to?: string
  color?: ColorType
  disableLink?: boolean
  hero?: boolean
  loading?: boolean
  bottomElement?: JSX.Element
  disabled?: boolean
  className?: string
  onClick?: any
}

const AccountCard: React.FC<AccountCardType> = ({
  user,
  bankAccount,
  color = 'TURQUOISE_300',
  to,
  hero,
  disableLink,
  disabled,
  loading,
  bottomElement,
  className,
  ...rest
}) => {
  const fundsAvailable = bankAccount?.fundsAvailable
  const { data: currentUser } = useCurrentUser()
  const isSK = useIsSidekick()
  const { data } = useTeamHoldingAccount(true)
  const amount = hero
    ? getAmount(data?.teamHoldingAccount?.fundsAvailable || fundsAvailable)
    : getAmount(fundsAvailable)
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  const Avatar = () =>
    hero ? (
      <IconCircle icon="brand" color="TURQUOISE_300" size={56} scale={0.6} />
    ) : (
      <AvatarInitials user={user} color={color} size={56} />
    )

  const TextContent = () => {
    if ((isSK && user?.id === currentUser?.userProfile?.id)) {
      return <Text token="MY_SIDEKICK_CARD" />
    }

    return hero ? (
      <Text token="HERO_ACCOUNT" />
    ) : (
      <Text css={styles.textColor}>
        {user.givenName} {user.familyName}
      </Text>
    )
  }

  return (
    <Link to={to} disableLink={disableLink || !to} {...rest}>
      <ComponentCard
        className={classNames}
        loading={loading}
        css={styles.textColor}
      >
        <Flex centerY gap="1rem">
          <Avatar />
          <Grid gap={5} vertical stretch>
            <TextContent />
            <Flex vertical>
              <Detail token="AVAILABLE" css={styles.detail} uppercase />
              <Text currency={amount} />
            </Flex>
          </Grid>
          {!disableLink && !bottomElement && (
            <Icon icon="chevron" color="BLACK_700" />
          )}
          {bottomElement}
        </Flex>
      </ComponentCard>
    </Link>
  )
}

const computeStyles = ({ TEXT_PRIMARY_DARK, TEXT_PRIMARY_LIGHT }) => ({
  detail: {
    fontSize: 12,
    letterSpacing: 1,
    color: TEXT_PRIMARY_LIGHT,
  },
  textColor: {
    color: TEXT_PRIMARY_DARK,
    '&.is-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
})

export default AccountCard
