import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Actions from '../../components/Actions'
import Button from '../../components/Button'

import useTeamMembers from '../../hooks/useTeamMembers'
import FirstStepsCard from '../../components/cards/FirstStepsCard'
import QuickAllocationCard from '../../components/cards/QuickAllocationCard'
import RecentTransfersCard from '../../components/cards/RecentTransfersCard'
import MainAccountBalanceCard from '../../components/cards/MainAccountBalanceCard'

import MainLayoutLink from '../../components/layout/MainLayoutLink'
import useSubscriptionsAllowance from '../../hooks/useSubscriptionsAllowance'
import { useIsValidSubscription } from '../../hooks/useSubscriptions'
import useIsInternationalUser from '../../hooks/useIsInternationalUser'
import useFundingRequests from '../../hooks/useFundingRequests'
import AwaitingFundingRequestsNotification from '../../components/AwaitingFundingRequestsNotification'
import useIsSidekick from '../../hooks/useIsSidekick'

const DashboardScene = () => {
  const navigate = useNavigate()
  const { loading, teamMembers, error } = useTeamMembers(true, true)
  const { allow, loading: allowLoading } = useSubscriptionsAllowance()
  const { data: subscriptionValidData, loading: subscriptionValidLoading } =
    useIsValidSubscription()
  const isSK = useIsSidekick()

  const hasSubscriptionPlan = subscriptionValidData?.hasActiveSubscription
  const isInternational = useIsInternationalUser()

  const [search] = useSearchParams()
  const refresh = search.has('refresh')

  const canAllocate =
    typeof allow === 'boolean' ? allow : allow?.generalAllowance

  const canAllocateInternational = isInternational
    ? teamMembers.filter(({ userProfile }) => userProfile.isIdentityVerified)
        ?.length
    : true

  const { data: fundingRequests } = useFundingRequests(false, true)

  React.useEffect(() => {
    if (isSK) {
      navigate('/accounts')
    }
  }, [])

  React.useEffect(() => {
    if (isSK) {
      navigate('/accounts')
    }
  }, [isSK])
  
  return (
    <MainLayoutLink loading={loading} error={error}>
      {fundingRequests.length > 0 && (
        <AwaitingFundingRequestsNotification feature="settings.fundingRequests" />
      )}

      <FirstStepsCard />

      <MainAccountBalanceCard refresh={refresh} />

      <Actions>
        <Button
          disabled={!hasSubscriptionPlan}
          token="ADD_FUNDS"
          loading={subscriptionValidLoading}
          to="/add-funds"
          icon="plus"
          size={1}
          iconColor="white"
          feature="funds.add"
        />
        <Button
          disabled={!canAllocate || !canAllocateInternational}
          loading={allowLoading}
          token="ALLOCATE"
          to="/allocate"
          icon="transaction"
          size={1}
          iconColor="white"
          feature="funds.allocate"
        />
      </Actions>

      {teamMembers?.length > 0 && (
        <QuickAllocationCard teamMembers={teamMembers} />
      )}

      <RecentTransfersCard />
    </MainLayoutLink>
  )
}

export default DashboardScene
