import React from 'react'
import isEqual from 'lodash/isEqual'

import AvatarInitials from '../../AvatarInitials'
import Flex from '../../Flex'
import OptionCircle from '../../drawers/options/OptionCircle'
import FieldText from '../../typography/FieldText'
import ComponentCard from '../../cards/ComponentCard'
import { getFullName } from '../../../utils/functions'

export const SideckickItem = ({
  user,
  withInitials = true,
  ...rest
}) => (
  <Flex centerY gap="1rem">
    {withInitials && (
      <AvatarInitials
        color="YELLOW"
        size={50}
        user={user?.userProfile}
      />
    )}
    <Flex vertical gap="0.1rem">
      <FieldText {...rest}>{getFullName(user.userProfile)}</FieldText>
    </Flex>
  </Flex>
)

const SidekickOptionItem = ({
  user,
  selected,
  onClick,
  disabled,
  hideRadio,
  ...rest
}) => {
  return (
    <ComponentCard
      css={disabled && { opacity: 0.5 }}
      selected={selected}
      onClick={(e) => !disabled && onClick(e)}
    >
      <Flex centerY spread>
        <SideckickItem user={user} {...rest} />
        {!hideRadio && <OptionCircle selected={selected} />}
      </Flex>
    </ComponentCard>
  )
}

type SidekickOptionProps = {
  option: any
  onSelect?: Function
  disabled?: boolean
  selectedValue?: any
  hideRadio?: boolean
}

const SidekickOption: React.FC<SidekickOptionProps> = ({
  option,
  onSelect = () => {},
  disabled,
  selectedValue,
  hideRadio,
  ...rest
}) => (
  <SidekickOptionItem
    hideRadio={hideRadio}
    user={option}
    disabled={disabled}
    selected={isEqual(selectedValue, option)}
    onClick={() => onSelect(option)}
    {...rest}
  />
)

export default SidekickOption
