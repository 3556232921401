import '@trulioo/docv/style.css'
import React from 'react'
import Card from '../../../../../components/cards/Card'
import Heading from '../../../../../components/typography/Heading'
import Detail from '../../../../../components/typography/Detail'
import Flex from '../../../../../components/Flex'
import Icon from '../../../../../components/Icon'
import FlowContext from '../../../../../context/FlowContext'
import Element from '../../../../../components/Element'
import useTrulioo, { elementTruliooID } from '../../../../../hooks/useTrulioo'
import HeroSpinner from '../../../../../components/Spinner'
import isMobile from '../../../../../mobile/utils/isMobile'
import { ID_VERIFICATION_STATUS } from '../../../../../hooks/useTrulioo'
import isEmpty from 'lodash/isEmpty'

type KYCFlowStepDataProps = {
  showLoading?: boolean
}

const KYCFlowStepData: React.FC<KYCFlowStepDataProps> = ({ showLoading }) => {
  const { next, setFlowData } = React.useContext(FlowContext)
  const { status, loading, error } = useTrulioo()

  const checkStatus = async (status) => {
    if (status) {
      await setFlowData({ status })
      next()
    }
  }

  const navigatePlaystore = (e, os) => {
    e.preventDefault()
    window.location.href =
      os === 'ios'
        ? 'https://apps.apple.com/us/app/hero-financials/id6444052139'
        : 'https://play.google.com/store/apps/details?id=com.herofinancials'
  }

  React.useEffect(() => {
    checkStatus(status)
  }, [status])

  React.useEffect(() => {
    if (!isEmpty(error)) {
      checkStatus(ID_VERIFICATION_STATUS.NOMATCH)
    }
  }, [error])

  if (!isMobile()) {
    return (
      <Card loading={showLoading}>
        <Flex center gap="1rem">
          <Heading token="KYC_MOBILE_HEADING" />
          <Detail token="KYC_MOBILE" />
          <Flex center gap="2rem" css={styles.iconWrapper}>
            <Icon
              css={styles.link}
              icon="apple"
              size={1.3}
              onClick={(e) => navigatePlaystore(e, 'ios')}
            />
            <Icon
              css={styles.link}
              icon="android"
              size={1.3}
              onClick={(e) => navigatePlaystore(e, 'android')}
            />
          </Flex>
        </Flex>
      </Card>
    )
  }

  return (
    <Card>
      {loading && (
        <Element css={styles.loader}>
          <HeroSpinner />
        </Element>
      )}
      <Element id={elementTruliooID}></Element>
    </Card>
  )
}

const styles = {
  loader: {
    position: 'absolute',
    right: 'calc(50vw - 50px)',
    bottom: '50vh',
    zIndex: 1,
  },
  link: {
    cursor: 'pointer',
  },
  iconWrapper: {
    width: '100%',
  },
}

export default KYCFlowStepData
