export type NAT_ITEM_DICT = {
  label: string
  format: RegExp
}

const NATIONALITY_DICT: { [key: string]: NAT_ITEM_DICT } = {
  AR: {
    label: 'DNI/CUIT',
    format: new RegExp(/^(?:\d{7}|\d{8}|\d{9}|\d{11})$/),
  },
  BO: {
    label: 'CI',
    format: new RegExp(/^\d{5,20}$/),
  },
  CL: {
    label: 'CI/RUT',
    format: new RegExp(/^\d{11}$/),
  },
  CN: {
    label: 'Citizen ID number',
    format: new RegExp(/^\d{5,20}$/),
  },
  CO: {
    label: 'CO',
    format: new RegExp(/^\d{6,10}$/),
  },
  EC: {
    label: 'CI',
    format: new RegExp(/^\d{5,20}$/),
  },
  EG: {
    label: 'ID',
    format: new RegExp(/^\d{14}$/),
  },
  IN: {
    label: 'PAN',
    format: new RegExp(/\b[A-Za-z0-9]{10}\b/),
  },
  ID: {
    label: 'NIK',
    format: new RegExp(/^\d{16}$/),
  },
  MX: {
    label: 'CURP',
    format: new RegExp(/\b[A-Za-z0-9]{10,18}\b/),
  },
  MA: {
    label: 'CNIE',
    format: new RegExp(/\b[A-Za-z0-9]{5,20}\b/),
  },
  NG: {
    label: 'NIN',
    format: new RegExp(/^\d{11}$/),
  },
  PY: {
    label: 'CI',
    format: new RegExp(/^\d{5,20}$/),
  },
  PE: {
    label: 'DNI',
    format: new RegExp(/^\d{8,9}$/),
  },
  ZA: {
    label: 'ID',
    format: new RegExp(/^\d{5,20}$/),
  },
  TR: {
    label: 'T.C Kimlik No',
    format: new RegExp(/^\d{5,20}$/),
  },
  UY: {
    label: 'CI',
    format: new RegExp(/^\d{6,8}$/),
  },
  US: {
    label: 'SSN',
    format: new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/),
  },
} as const

export default NATIONALITY_DICT
