import React from 'react'
import isEqual from 'lodash/isEqual'

import Flex from '../../Flex'
import OptionCircle from './OptionCircle'
import FieldText from '../../typography/FieldText'
import ComponentCard from '../../../components/cards/ComponentCard'
import { SelectDrawerFieldContext } from '../../form/fields/SelectDrawerField'

export const DocumentTypeItem = ({ documentType, ...rest }) => (
  <FieldText token={documentType?.value} {...rest} />
)

export const SelectDrawerDocumentTypeOptionItem = ({
  selected,
  documentType,
  onClick,
  ...rest
}) => (
  <ComponentCard selected={selected} onClick={onClick}>
    <Flex spread>
      <DocumentTypeItem documentType={documentType} {...rest} />
      <OptionCircle selected={selected} />
    </Flex>
  </ComponentCard>
)

const SelectDrawerDocumentTypeOption = ({ option, ...rest }) => (
  <SelectDrawerFieldContext.Consumer>
    {({ select, selected }) => (
      <SelectDrawerDocumentTypeOptionItem
        documentType={option}
        selected={isEqual(selected, option)}
        onClick={() => select(option)}
        {...rest}
      />
    )}
  </SelectDrawerFieldContext.Consumer>
)

export default SelectDrawerDocumentTypeOption
