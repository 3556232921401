import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'

import Card, { CardProps } from './Card'

export type ComponentCardProps = CardProps & {
  transparent?: boolean
  selected?: boolean
  className?: string
}

const ComponentCard: React.FC<ComponentCardProps> = ({
  transparent,
  selected,
  className,
  ...rest
}) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    'is-transparent': transparent,
    'is-selected': selected,
  })

  return <Card css={styles.root} {...rest} className={classNames} />
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  CARD_BORDER,
  PRIMARY_ALT,
}: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
    border: `1px solid ${CARD_BORDER}`,
    borderRadius: 10,
    height: 'max-content',

    '&.is-transparent': {
      backgroundColor: 'transparent',
      border: 'none',
    },

    '&.is-selected': {
      borderColor: PRIMARY_ALT,
    },
  },
})

export default ComponentCard
