import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'

import FundAmountForm from '../../components/form/forms/FundAmountForm'

import FlowContext from '../../context/FlowContext'

const FundAccountInputAmountStep = ({ ...rest }) => {
  const { currentData, setFlowData, next } = React.useContext(FlowContext)

  const handleSubmit = async () => next()

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  const defaultInitialValues = {
    ...currentData?.data,
  }

  return (
    <FlowStepLayout>
      <FundAmountForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={defaultInitialValues}
        hideFooter={currentData?.meta?.loading}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default FundAccountInputAmountStep
