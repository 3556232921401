import React from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import FlowCardLayout, {
  FlowStep,
} from '../../components/layout/FlowCardLayout'
import Text from '../../components/typography/Text'
import ConfirmModal from '../../components/modals/ConfirmModal'
import FundAccountInputAmountStep from './fund-input-amount'
import FundAccountAddFundingMethodStep from './fund-add-funding-method'
import FundAccountSelectFundingMethodStep from './fund-select-funding-method'
import FundAccount3DSAuth from './fund-account-3ds-auth'
import FundAccountConfirmPaymentStep from './fund-confirm-payment'
import FundAccountStatusStep from './fund-account-status'

import NexPayFundAddPaymentProfile from './nexpay/nexpay-fund-new-payment-profile'
import NexPayFundAccountAddDocuments from './nexpay/nexpay-fund-payment-profile-documents'
import NexPayFundAccountPayerStep from './nexpay/nexpay-fund-account-payer'
import NexPayFundAccountWhoReceiveStep from './nexpay/nexpay-fund-who-receive-funds'
import NexPayFundAccountPaymentMethodStep from './nexpay/nexpay-fund-payment-method'
import NexPayFundAccountReviewStep from './nexpay/nexpay-fund-account-confirm'
import NexPayFundAccountCompletePaymentStep from './nexpay/nexpay-fund-account-complete'

import usePrompt from '../../hooks/usePrompt'
import useIsInternationalUser from '../../hooks/useIsInternationalUser'
import useIsDomesticUser from '../../hooks/useIsDomesticUser'
import { fundingSources } from '../../queries'
import { FlowStateType } from '../../store/flow'
import NexPayFundNewPaymentProfileThanksStep from './nexpay/nexpay-fund-new-payment-profile-thanks'
import useCurrentUser from '../../hooks/useCurrentUser'
import KYCSTatusType from '../../utils/kyc-status'
import KYCFlowStepData from '../settings/team/flows/kyc/kyc-step-data'
import KYCFlowStepSuccess from '../settings/team/flows/kyc/kyc-step-finish'

const FundAccountScene = ({ ...rest }) => {
  const flowState = useSelector(
    (state: FlowStateType) => state.flow.fundAccount
  )
  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta?.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)
  const isInternationalUser = useIsInternationalUser()
  const isDomesticUser = useIsDomesticUser()
  const { data: fundingSourcesData, loading: fundingSourceLoading } =
    useQuery(fundingSources)
  const { data: user, loading: userLoading } = useCurrentUser()

  React.useEffect(() => {
    if (
      flowState?.meta?.keepDirty &&
      fundingSourcesData?.fundingSources?.length === 0
    )
      if (flag) setShowConfirm(flag)
  }, [flag])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  const needKYC =
    user?.userProfile?.kycValidationStatus === KYCSTatusType.None ||
    user?.userProfile?.kycValidationStatus === KYCSTatusType.Failed

  const loading = userLoading || fundingSourceLoading

  return (
    <>
      {isDomesticUser && (
        <FlowCardLayout
          name="fundAccount"
          loading={loading}
          cancel="/"
          {...rest}
        >
          <FlowStep
            condition={() => needKYC}
            element={() => <KYCFlowStepData showLoading={loading} />}
          />
          <FlowStep
            condition={() => needKYC}
            element={() => <KYCFlowStepSuccess lastStep={false} />}
          />

          <FlowStep element={() => <FundAccountInputAmountStep />} />
          <FlowStep
            element={() => (
              <FundAccountSelectFundingMethodStep
                fundingSources={fundingSourcesData?.fundingSources}
              />
            )}
          />
          <FlowStep
            element={() => <FundAccountAddFundingMethodStep />}
            condition={(data) =>
              data?.selectedFundingMethod === 'new' ||
              fundingSourcesData?.fundingSources?.length === 0 ||
              data?.isNewFundingSource
            }
          />
          <FlowStep
            condition={(data) => data?.fundingSourceModel?.with3DSChallenge}
            element={() => <FundAccount3DSAuth />}
          />
          <FlowStep element={() => <FundAccountConfirmPaymentStep />} />
          <FlowStep element={() => <FundAccountStatusStep />} />
        </FlowCardLayout>
      )}

      {isInternationalUser && (
        <FlowCardLayout
          name="fundAccount"
          loading={loading}
          cancel="/"
          {...rest}
        >
          <FlowStep
            condition={() => needKYC }
            element={() => <KYCFlowStepData showLoading={loading} />}
          />
          <FlowStep
            condition={() => needKYC}
            element={() => <KYCFlowStepSuccess lastStep={false} />}
          />
          <FlowStep element={() => <FundAccountInputAmountStep />} />
          <FlowStep element={() => <NexPayFundAccountPayerStep currentUser={user} />} />
          <FlowStep
            condition={(data) => data?.newPaymentProfile?.new}
            element={() => <NexPayFundAddPaymentProfile />}
          />
          <FlowStep
            condition={(data) => data?.newPaymentProfile?.newFromCurrent}
            element={() => <NexPayFundAddPaymentProfile newFromCurrent={true}/>}
          />
          <FlowStep
            condition={(data) => data?.newPaymentProfile?.new ||  data?.newPaymentProfile?.newFromCurrent}
            element={() => <NexPayFundAccountAddDocuments />}
          />
          <FlowStep
            condition={(data) => !data?.newPaymentProfile?.new &&  !data?.newPaymentProfile?.newFromCurrent}
            element={() => <NexPayFundAccountWhoReceiveStep />}
          />
          <FlowStep
            condition={(data) => !data?.newPaymentProfile?.new &&  !data?.newPaymentProfile?.newFromCurrent}
            element={() => <NexPayFundAccountPaymentMethodStep />}
          />
          <FlowStep
            condition={(data) => !data?.newPaymentProfile?.new && !data?.newPaymentProfile?.newFromCurrent }
            element={() => <NexPayFundAccountReviewStep />}
          />
          <FlowStep
            condition={(data) => !data?.newPaymentProfile?.new && !data?.newPaymentProfile?.newFromCurrent }
            element={() => <NexPayFundAccountCompletePaymentStep />}
          />
          <FlowStep
            condition={(data) => data?.newPaymentProfile?.new || data?.newPaymentProfile?.newFromCurrent }
            element={() => <NexPayFundNewPaymentProfileThanksStep />}
          />
        </FlowCardLayout>
      )}
      {showConfirm && (
        <ConfirmModal
          title={<Text token="CONFIRM" />}
          content={<Text token="DATA_LOSS_CONFIRM_MESSAGE" />}
          confirm={next}
          cancel={closeModal}
          backdrop
        />
      )}
    </>
  )
}

export default FundAccountScene
