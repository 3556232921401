import useTeamHoldingAccount from './useTeamHoldingsAccount'

const useCanceledFundingRequests = (cache?: boolean, cacheOnly?: boolean) => {
  const { data, loading, refetch } = useTeamHoldingAccount(cache, cacheOnly)
  const transactions =
    data?.teamHoldingAccount?.holdingAccountTransactions?.edges || []
  const processingFundingRequests = transactions.filter(
    ({ node }) =>
      (node.type === 'NexPayDeposit' || node.type === 'TransferMateDeposit') &&
      node.status === 'Canceled'
  )

  return { data: processingFundingRequests, loading, refetch }
}

export default useCanceledFundingRequests
