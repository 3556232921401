import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'

import ListItem from './lists/ListItem'
import Button from './Button'
import DrawerCard from './drawers/DrawerCard'

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const { colors, theme } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language)
  const [drawer, setDrawer] = React.useState(false)

  const selectLanguage = (language) => {
    setSelectedLanguage(language)
    setDrawer(false)
  }

  React.useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage])

  return (
    <>
      <Button
        button
        label={selectedLanguage}
        css={styles.trigger}
        uppercase
        onClick={() => setDrawer(true)}
      />
      <DrawerCard handleClickOutside={() => setDrawer(false)} isOpened={drawer}>
        <ListItem onClick={() => selectLanguage('en')} gap="0.5rem">
          <span>🇺🇸</span> <span>English</span>
        </ListItem>
        <ListItem onClick={() => selectLanguage('ja')} gap="0.5rem">
          <span>🇯🇵</span> <span>日本</span>
        </ListItem>
        <ListItem onClick={() => selectLanguage('de')} gap="0.5rem">
          <span>🇩🇪</span> <span>Deutsch</span>
        </ListItem>
        <ListItem onClick={() => selectLanguage('fr')} gap="0.5rem">
          <span>🇫🇷</span> <span>Français</span>
        </ListItem>
        <ListItem onClick={() => selectLanguage('es')} gap="0.5rem">
          <span>🇪🇸</span> <span>Español</span>
        </ListItem>
        <ListItem onClick={() => selectLanguage('pt')} gap="0.5rem">
          <span>🇵🇹</span> <span>Português</span>
        </ListItem>
        <ListItem onClick={() => selectLanguage('it')} gap="0.5rem">
          <span>🇮🇹</span> <span>Italiano</span>
        </ListItem>
      </DrawerCard>
    </>
  )
}

const computeStyles = ({ BLACK_600 }) => ({
  root: {
    display: 'flex ',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },

  trigger: {
    background: 'transparent',
    fontSize: 15,
    padding: '12px 16px',
    textTransform: 'uppercase',

    fontWeight: 'bold',
    color: BLACK_600,
  },
})

export default LanguageSelector
