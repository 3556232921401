import { onError } from '@apollo/client/link/error'
import { toast } from 'react-hot-toast'

import store from '../../store'

const errorExceptions = [
  '"Error: Terms Of Service not yet accepted."',
  'Connection closed',
  'Timeout disconnect',
  '"Error: UserIsNotATeamMember"',
]

const validateNetworkError = async (forward, operation, error) => {
  if (error?.errors) {
    for (let i = 0; i < error?.errors?.length; i++) {
      if (errorExceptions.indexOf(error?.errors?.[i]?.message) === -1) {
        toast.error(error?.errors?.[i]?.message)
      }
    }
  } else if (error) {
    switch (error.response?.status) {
      case 400:
        await store.dispatch.auth.signOut()

        return forward(operation)

      default:
        toast.error(error.message)
    }
  }
}

const errorMiddleware = (withErrorHandler) =>
  onError(({ forward, operation, graphQLErrors, networkError }) => {
    const { response } = operation.getContext()
    if (graphQLErrors && response?.status !== 401 && withErrorHandler) {
      graphQLErrors.forEach((error) => {
        if (errorExceptions.indexOf(error.message) === -1) {
          toast.error(error.message)
        }
      })
    }
    validateNetworkError(forward, operation, networkError)
  })

export default errorMiddleware
