import React from 'react'
import { useQuery } from '@apollo/client'
import { Outlet } from 'react-router-dom'

import AnimatedSpinner from './AnimatedSpinner'

import {
  getCards,
  getUserProfile,
  subscription,
  teamHoldingAccount,
  teamMembers,
} from '../queries'
import withMobile from '../hocs/withMobile'
import InitialMobileCall from './InitialMobileCall'

export const InitialCall = () => {
  // We do this so the cache is already filled with these information
  // So hooks such as useCurrentUser just consume the cache instead of having
  // to load the neccessary information to know what role the current user is

  const { loading: userProfileLoading } = useQuery(getUserProfile)
  const { loading: teamMembersLoading } = useQuery(teamMembers)
  const { loading: teamHoldingAccountLoading } = useQuery(teamHoldingAccount)

  const { loading: subscriptionValidLoading } = useQuery(subscription)
  const { loading: cardsLoading } = useQuery(getCards)

  const loading =
    userProfileLoading ||
    teamMembersLoading ||
    teamHoldingAccountLoading ||
    subscriptionValidLoading ||
    cardsLoading

  return (
    <>
      {loading && (
        <div css={styles.root}>
          <AnimatedSpinner />
        </div>
      )}
      {!loading && <Outlet />}
    </>
  )
}

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
  },
}

export default withMobile(InitialMobileCall, InitialCall)
