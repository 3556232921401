import { useQuery } from '@apollo/client'
import { teamMembers as teamMembersQuery, teamMembersById } from '../queries'

export enum RoleType {
  HERO = 'HERO',
  SIDEKICK = 'SIDEKICK',
}

export const hasBankAccountsValidation = (member) =>
  member?.bankAccounts && member?.bankAccounts?.length

export const isHero = (role) => role === RoleType.HERO

export const filterTeamMembersByRole = (teamMembers, roleFilter: RoleType) =>
  teamMembers.filter((member) => member.role === roleFilter)

export const filterTeamMembersByIDVerified = (teamMembers) =>
  teamMembers.filter((member) => member.isIdentityVerified)

const useTeamMembers = (
  cache = true,
  hasBankAccounts?,
  roleFilter?: RoleType,
  id?: string,
  isIDVerified?: boolean,
  cacheOnly?: boolean
) => {
  const fetchPolicy = cacheOnly
    ? 'cache-only'
    : cache
    ? 'cache-first'
    : 'no-cache'
  const { data, loading, refetch, error } = useQuery(teamMembersQuery, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  })

  let teamMembers = data?.teamMembers

  const exist = teamMembers && teamMembers.length

  if (exist && hasBankAccounts) {
    teamMembers = teamMembers.filter(hasBankAccountsValidation)
  }

  if (exist && roleFilter) {
    teamMembers = filterTeamMembersByRole(teamMembers, roleFilter)
  }

  if (exist && isIDVerified) {
    teamMembers = filterTeamMembersByIDVerified(teamMembers)
  }

  let teamMember

  if (exist && id) {
    teamMember = teamMembers.find((member) => member.userProfile.id === id)
  }

  return { teamMembers, teamMember, loading, refetch, error }
}

export default useTeamMembers

export const useTeamMemberById = (cache = true, id) => {
  const fetchPolicy = cache ? 'cache-first' : 'no-cache'
  return useQuery(teamMembersById, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  })
}
