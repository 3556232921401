import useAxios from 'axios-hooks'

const useUploadDocuments = (): [
  Function,
  { uploadFileLoading: boolean; error: any }
] => {
  const [{ loading: uploadFileLoading, error }, uploadFile] = useAxios(
    {
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: false,
    }
  )

  const uploadFiles = async (modelFiles) => {
    const uploadConfigs = modelFiles.map((file) => {
      const s3config = {}

      file?.fields.forEach(({ key, value }) => {
        s3config[key] = value
      })

      return {
        url: file?.url,
        data: {
          ...s3config,
          file: file.model,
        },

        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    })

    for (const config in uploadConfigs) {
      await uploadFile(uploadConfigs[config])
    }
  }

  return [
    uploadFiles,
    {
      uploadFileLoading,
      error,
    },
  ]
}

export default useUploadDocuments
