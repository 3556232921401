import React from 'react'
import { useTheme } from '@emotion/react'

import Button from '../Button'
import DocumentIcon from '../DocumentIcon'
import Flex from '../Flex'
import DocumentStatusPill from '../DocumentStatusPill'
import Text from '../typography/Text'
import Detail from '../typography/Detail'
import ComponentCard from './ComponentCard'
import Fullname from '../Fullname'

export const DOCUMENT_TYPES = {
  PASSPORT: 'Passport',
  LETTER_OF_ACCEPTANCE: 'School Documents',
  ID_DOCUMENT: 'Government Id',
}

type DocumentCardProps = {
  document: any
  user: any
  remove: Function
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  document,
  user,
  remove,
}) => {
  const { theme } = useTheme()
  const type = DOCUMENT_TYPES[document.type]

  return (
    <ComponentCard>
      <Flex gap={16}>
        <Flex vertical>
          <DocumentIcon
            document={document}
            iconColor="BLACK_900"
            color="BLUE_50"
            size={56}
            scale={1.5}
          />
        </Flex>

        <Flex vertical gap={8} stretch>
          <Text token={type} />
          <Text>
            <Fullname user={user} />
          </Text>
          <Detail>Pages: {document.files?.length}</Detail>
          <Detail>
            Updated: <Text date={document.createdAt} dateFormat="MM-dd-yyyy" />
          </Detail>

          <Flex>
            <DocumentStatusPill status={document.status} />
          </Flex>
        </Flex>

        {remove && (
          <Flex vertical>
            <Button
              button
              icon="moreVertical"
              iconColor={theme.TEXT_PRIMARY_DARK}
              size={1}
              css={styles.moreOptions}
              onClick={() => remove()}
            />
          </Flex>
        )}
      </Flex>
    </ComponentCard>
  )
}

const styles = {
  moreOptions: {
    backgroundColor: 'transparent',
  },
}

export default DocumentCard
