import {
  OperationVariables,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/client'
import { apiClientWithoutErrorHandler } from '../config/api'
import {
  subscriptionPlans as subscriptionPlansQuery,
  subscription,
  invoices,
} from '../queries'
import {
  subscribe,
  unsubscribe,
  updateSubscription,
} from '../queries/mutations'

const NOT_SUBSCRIBED: string = '"Error: User is not subscribed"'
export const CANCELED_STATUS: string = 'Canceled'
export const DEFAULT_CURRENCY: string = 'CAD'

const useSubscriptions = (cache: boolean = true, subscriptionCode?: string) => {
  const fetchPolicy = cache ? 'cache-first' : 'no-cache'
  const queryOptions: QueryHookOptions<any, OperationVariables> = {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  }

  const {
    loading: subscriptionPlansLoading,
    data: subscriptionPlansData,
    refetch: subscriptionPlansRefetch,
  } = useQuery(subscriptionPlansQuery, queryOptions)

  // Add a apollo client without the error handler to prevent
  const {
    loading: subscriptionStatusLoading,
    data: subscriptionStatusData,
    refetch: subscptionStatusRefetch,
  } = useQuery(subscription, {
    ...queryOptions,
    client: apiClientWithoutErrorHandler,
  })

  const refetch = async () =>
    await Promise.all([subscptionStatusRefetch(), subscriptionPlansRefetch()])

  let selectedSubscripton
  if (subscriptionCode) {
    const filterSubscription = subscriptionPlansData?.subscriptionPlans?.find(
      (sub) => sub.code === subscriptionCode
    )
    selectedSubscripton = filterSubscription
  }

  const data = {
    ...subscriptionPlansData,
    ...subscriptionStatusData,
    selectedSubscripton,
  }
  return {
    data,
    loading: subscriptionPlansLoading || subscriptionStatusLoading,
    refetch,
  }
}

export const useIsValidSubscription = (cacheOnly?: boolean) => {
  const queryOptions: QueryHookOptions<any, OperationVariables> = {
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-first',
    notifyOnNetworkStatusChange: true,
  }
  const { loading, data, refetch, error } = useQuery(subscription, {
    ...queryOptions,
    client: apiClientWithoutErrorHandler,
  })

  let currentSubscriptionData = {
    isValid: !!data?.subscription,
    hasActiveSubscription: !!data?.subscription,
  }

  if (
    error?.message === NOT_SUBSCRIBED ||
    data?.subscription?.state === CANCELED_STATUS
  )
    currentSubscriptionData.isValid = false

  return {
    data: currentSubscriptionData,
    subscription: data?.subscription,
    loading,
    refetch,
    error,
  }
}

export const useInvoices = (cache = true, invoiceId?: string) => {
  const fetchPolicy = cache ? 'cache-first' : 'no-cache'
  const queryOptions: QueryHookOptions<any, OperationVariables> = {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  }

  const { loading, data, refetch, error } = useQuery(invoices, queryOptions)

  let response = data?.invoices

  if (invoiceId && response)
    response = response.find((invoice) => invoice.id === invoiceId)

  return { data: response, loading, refetch, error }
}

export const useSubscribe = (edit = false) => {
  const queryOptions = {
    notifyOnNetworkStatusChange: true,
  }
  return useMutation(edit ? updateSubscription : subscribe, queryOptions)
}

export const useUnsubscribe = () => {
  const queryOptions = {
    notifyOnNetworkStatusChange: true,
  }
  return useMutation(unsubscribe, queryOptions)
}

export default useSubscriptions
