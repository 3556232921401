import React from 'react'
import isEqual from 'lodash/isEqual'

import AvatarInitials from '../../AvatarInitials'
import Flex from '../../Flex'
import OptionCircle from '../../drawers/options/OptionCircle'
import FieldText from '../../typography/FieldText'
import DocumentStatusPill from '../../DocumentStatusPill'
import ComponentCard from '../../cards/ComponentCard'
import { useTranslation } from 'react-i18next'
import { getFullName } from '../../../utils/functions'
import IconCircle from '../../IconCircle'

export const PaymentProfileItem = ({
  user,
  status,
  withInitials = true,
  ...rest
}) => (
  <Flex centerY gap="1rem">
    {withInitials && <AvatarInitials color="YELLOW" size={50} user={user} />}
    <Flex vertical gap="0.25rem">
      <FieldText {...rest}>{getFullName(user)}</FieldText>
      {status && (
        <Flex>
          <DocumentStatusPill status={status} />
        </Flex>
      )}
    </Flex>
  </Flex>
)

const PaymentProfileOptionItem = ({
  user,
  selected,
  onClick,
  disabled,
  hideRadio,
  ...rest
}) => {
  return (
    <ComponentCard
      css={disabled && { opacity: 0.5 }}
      selected={selected}
      onClick={(e) => !disabled && onClick(e)}
    >
      <Flex centerY spread>
        <PaymentProfileItem user={user} {...rest} />
        {!hideRadio && <OptionCircle selected={selected} />}
      </Flex>
    </ComponentCard>
  )
}

export const NewPaymentProfileOption: React.FC<any> = ({
  option,
  selectedValue,
  onSelect = () => {},
}) => {
  const { t } = useTranslation()
  const newData = { new: true, givenName: t('SOMEONE_NEW'), familyName: '' }

  return (
    <ComponentCard
      selected={(selectedValue || option)?.new}
      onClick={() => onSelect(newData)}
    >
      <Flex centerY spread>
        <Flex centerY gap="1rem">
          <IconCircle size={50} icon="user" color="PRIMARY_ALT" />
          <FieldText token="SOMEONE_NEW" />
        </Flex>
        <OptionCircle selected={(selectedValue || option)?.new} />
      </Flex>
    </ComponentCard>
  )
}

type CurrentUserProfileOptionProps = {
  option: any
  onSelect?: Function
  disabled?: boolean
  selectedValue?: any
  hideRadio?: boolean
  currentUser: { userProfile: any }
}


export const CurrentUserProfileOption: React.FC<CurrentUserProfileOptionProps> = ({
  option,
  onSelect = () => {},
  disabled,
  selectedValue,
  hideRadio,
  currentUser,
  ...rest
}) => {

  const newData = { newFromCurrent: true, ...currentUser?.userProfile }

  return (
    <PaymentProfileOptionItem
      hideRadio={hideRadio}
      user={currentUser?.userProfile }
      disabled={disabled}
      selected={selectedValue?.newFromCurrent}
      onClick={() => onSelect(newData)}
      {...rest}
    />
  )
}


type PaymentProfileOptionProps = {
  option: any
  onSelect?: Function
  disabled?: boolean
  selectedValue?: any
  hideRadio?: boolean
  status?: string
}


const PaymentProfileOption: React.FC<PaymentProfileOptionProps> = ({
  option,
  onSelect = () => {},
  disabled,
  selectedValue,
  hideRadio,
  ...rest
}) => (
  <PaymentProfileOptionItem
    hideRadio={hideRadio}
    user={option}
    disabled={disabled}
    selected={isEqual(selectedValue, option)}
    onClick={() => onSelect(option)}
    {...rest}
  />
)

export default PaymentProfileOption
