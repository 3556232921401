import React from 'react'
import { useMutation } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Flex from '../../../../components/Flex'
import Button from '../../../../components/Button'
import ComponentCard from '../../../../components/cards/ComponentCard'
import Grid from '../../../../components/Grid'
import Text from '../../../../components/typography/Text'
import { getAmount } from '../../../../utils/functions'
import UploadProofOfPaymentForm from '../../../../components/form/forms/UploadProofOfPaymentForm'

import {
  cancelNexPayFundsTransfer as cancelNexPayFundsTransferMutation,
  cancelTransferMateFundsTransfer as cancelTransferMateFundsTransferMutation,
  initializeProofOfPaymentUpload,
} from '../../../../queries'

import useUploadDocuments from '../../../../hooks/useUploadDocuments'
import DrawerCard from '../../../../components/drawers/DrawerCard'
import BigMenuItem from '../../../../components/lists/items/BigMenuItem'

type PendingRequestCardProps = {
  fundingRequest: any
  setUploading?: Function
  setCanceling?: Function
  refetch?: Function
  hideCancel?: boolean
  hideDate?: boolean
}

const PendingRequestCard: React.FC<PendingRequestCardProps> = ({
  fundingRequest,
  setUploading,
  setCanceling,
  hideCancel,
  hideDate,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [filesToUpload, setFilesToUpload] = React.useState([])
  const [showDrawer, setShowDrawer] = React.useState(false)

  const [initProofUpload, { data: initiatedProofOfPaymentData }] = useMutation(
    initializeProofOfPaymentUpload
  )

  const [cancelNexPayFundsTransfer, { loading: cancelingNexPay }] = useMutation(
    cancelNexPayFundsTransferMutation
  )

  const [cancelTransferMateFundsTransfer, { loading: cancelingTransferMate }] =
    useMutation(cancelTransferMateFundsTransferMutation)

  const [uploadFiles] = useUploadDocuments()

  React.useEffect(() => {
    if (initiatedProofOfPaymentData && filesToUpload.length) {
      const files =
        initiatedProofOfPaymentData?.initializeProofOfPaymentUpload?.files

      const modelFiles = files?.map((file) => {
        const fileToUpload = filesToUpload.find(
          (fileToUpload) => fileToUpload.model.name === file.filename
        )
        return { ...file, model: fileToUpload.model }
      })

      uploadFiles(modelFiles)
    }
  }, [initiatedProofOfPaymentData])

  const handleSubmit = async (formData) => {
    const variables = {
      input: {
        ...formData,
        files: formData?.files?.map?.((file) => file.model.name) || [],
      },
    }

    setFilesToUpload(formData?.files)
    setUploading(true)
    await initProofUpload({ variables })
  }

  const handleCancelNexPayFundsTransfer = async () => {
    setShowDrawer(false)
    setCanceling(true)
    try {
      if (fundingRequest?.node?.type) {
        if (fundingRequest.node.type.includes('NexPay')) {
          await cancelNexPayFundsTransfer({
            variables: { cursor: fundingRequest.cursor },
          })
        } else {
          await cancelTransferMateFundsTransfer({
            variables: { cursor: fundingRequest.cursor },
          })
        }
      }
    } catch (err) {
      setCanceling(false)
    }
  }

  const isBankTransfer = fundingRequest.node?.detail?.settlementMethod === 'BT'

  const canceling = cancelingTransferMate || cancelingNexPay

  return (
    <ComponentCard loading={canceling}>
      <Grid css={styles.pendingContainer}>
        <Flex css={styles.pendingContent} gap="1rem" vertical>
          <Flex vertical>
            <Text css={styles.detailsLabel} token="PAYMENT_ID" />
            <Text css={styles.detailsValue}>
              {fundingRequest.node?.detail?.paymentId}
            </Text>
          </Flex>
          <Flex vertical>
            <Text css={styles.detailsLabel} token="AMOUNT" />
            <Text
              css={styles.detailsValue}
              currency={getAmount(fundingRequest?.node?.amount)}
            />
          </Flex>
          {!hideDate && (
            <Text
              css={styles.detailsValue}
              date={fundingRequest.node?.updatedAt}
            />
          )}
          <Flex css={styles.pendingAction}>
            {isBankTransfer && (
              <UploadProofOfPaymentForm
                data={{ cursor: fundingRequest.cursor }}
                submit={handleSubmit}
              />
            )}
            {!isBankTransfer && (
              <a
                href={fundingRequest.node?.detail?.paymentLink}
                target="_blank"
                rel="noreferrer"
                css={styles.linkButton}
              >
                <Text token="PAY_NOW" />
              </a>
            )}
          </Flex>
        </Flex>
        {!hideCancel && (
          <Button
            button
            icon="moreVertical"
            iconColor={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_COLOR}
            size={1}
            onClick={() => setShowDrawer(true)}
            css={styles.moreOptions}
          />
        )}
      </Grid>
      <DrawerCard
        handleClickOutside={() => setShowDrawer(false)}
        isOpened={showDrawer}
      >
        <BigMenuItem
          label={<Text token="CANCEL_FUNDING_REQUEST" />}
          onClick={handleCancelNexPayFundsTransfer}
          icon="repeat"
          color={theme.NEGATIVE_ACTION_LIGHT}
          iconColor={theme.NEGATIVE_ACTION_BUTTON_PRIMARY}
          scale={0.909}
        />
      </DrawerCard>
    </ComponentCard>
  )
}

const computeStyles: TComputeStyles = ({
  FIELD_BORDER,
  PRIMARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
}: TTheme) => ({
  moreOptions: {
    alignSelf: 'flex-start',
    background: 'none',
    gridColumnStart: 2,
    gridColumnEnd: -1,
    gridRowStart: 1,
    gridRowEnd: -1,
  },
  pendingContent: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
    gridRowStart: 1,
    gridRowEnd: -1,
  },
  pendingContainer: {
    gridTemplateColumns: '1fr 50px',
  },
  pendingAction: {
    borderTop: `1px solid ${FIELD_BORDER}`,
    paddingTop: '1rem',
  },
  detailsLabel: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
  detailsValue: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
  linkButton: {
    alignItems: 'center',
    backgroundColor: PRIMARY,
    border: 0,
    borderRadius: 16,
    color: TEXT_SECONDARY,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 400,
    justifyContent: 'center',
    padding: '12px 16px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    width: 'min-content',
  },
})

export default PendingRequestCard
