import React from 'react'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import NexPayPaymentProfileForm from '../../../components/form/forms/NexPayPaymentProfileForm'
import DevButton from '../../../components/dev/DevButton'
import useRandomUser from '../../../hooks/useRandomUser'
import usePaymentProfiles from '../../../hooks/usePaymentProfiles'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { useMutation } from '@apollo/client'
import { findPaymentProfiles, addPaymentProfile } from '../../../queries'
import { paymentProfileParser } from '../../fund-account/nexpay/nexpay-fund-new-payment-profile'
import { useNavigate } from 'react-router-dom'

const SettingsAddPaymentProfilesScene = ({ ...rest }) => {
  const navigate = useNavigate()
  const { data: user, loading: userLoading } = useCurrentUser()

  const [{ data: randomUserData, loading: randomUserLoading }, getRandomUser] =
    useRandomUser()

  const { data: paymentProfiles, loading: paymentProfileLoading } =
    usePaymentProfiles()

  const [addNewPaymentProfile, { loading: addPaymentProfileLoading }] =
    useMutation(addPaymentProfile)

  const profiles = paymentProfiles?.paymentProfiles || []

  const loading =
    userLoading || paymentProfileLoading || addPaymentProfileLoading

  const handleNewPaymentProfile = async (values) => {
    const { data } = await addNewPaymentProfile({
      variables: {
        input: { ...values, locale: user.userProfile.locale },
      },
      update: (store, { data }) => {
        store.writeQuery({
          query: findPaymentProfiles,
          data: { paymentProfiles: [...profiles, data?.addPaymentProfile] },
        })
      },
    })

    navigate(
      `/settings/payment-profiles/${data?.addPaymentProfile?.id}/documents`
    )
  }

  return (
    <CardLayoutLink loading={loading} {...rest}>
      <DevButton
        label="Fill with Random User"
        onClick={getRandomUser}
        loading={randomUserLoading}
        type="button"
        button
      />

      {!randomUserLoading && (
        <NexPayPaymentProfileForm
          initialValues={paymentProfileParser(randomUserData)}
          onSubmit={handleNewPaymentProfile}
          hideFooter={loading}
          hidePrevBtn
        />
      )}
    </CardLayoutLink>
  )
}

export default SettingsAddPaymentProfilesScene
