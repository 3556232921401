export const HERO = 'HERO'
export const SIDEKICK = 'SIDEKICK'

const IsInternationalRegex = /\+international[^@]*@(heroinnovationgroup|herofinancials|euroasiapay)\.com$/i

const userIsHero          = user=> user?.role === HERO
const userIsSidekick      = user=> user?.role === SIDEKICK
const userIsAny           = user=> userIsHero(user) || userIsSidekick(user)
const userIsInternational = user=>IsInternationalRegex.test(user?.userProfile?.email)

export const onlyDev = () => process.env.NODE_ENV === 'development'

// All conditions must be met. Take OR conditions under a function.
const FEATURES = {
  firstSteps: [userIsHero],
  mainHoldingAccount: [userIsHero],

  sections: {
    dashboard: [userIsAny],
    accounts: [userIsAny],
    cards: [userIsAny],
    products: [userIsHero],
  },

  settings: {
    fundingRequests: [userIsHero],
    manageMyTeam: [userIsHero],
    manageMySubscription: [userIsHero],
    permissions: [userIsHero],
    documents: [userIsHero, userIsInternational],
    paymentProfiles: [userIsInternational],
  },

  dashboard: {},

  cards: {},

  products: {
    add: [userIsHero],
  },

  funds: {
    add: [userIsHero],
    allocate: [userIsHero],
    manage: [userIsHero],
  },

  documents: {
    add: [userIsHero],
    delete: [userIsHero],
  },
}

export type FeatureType = keyof typeof FEATURES

export default FEATURES
