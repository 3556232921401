import React from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@emotion/react'
import { useQuery, useMutation } from '@apollo/client'

import Button from '../../../components/Button'
import Actions from '../../../components/Actions'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import DocumentCard from '../../../components/cards/DocumentCard'
import DrawerCard from '../../../components/drawers/DrawerCard'
import BigMenuItem from '../../../components/lists/items/BigMenuItem'
import Text from '../../../components/typography/Text'
import List from '../../../components/lists/List'

import { findUserDocuments, deleteUserDocument } from '../../../queries'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useTeamMembers from '../../../hooks/useTeamMembers'

type ManageUserDocumentsSceneProps = {
  onAddDoc?: Function
  hideDeleteDocs?: boolean
  id?: string
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  back?: string | boolean
}

const ManageUserDocumentsScene: React.FC<ManageUserDocumentsSceneProps> = ({
  onAddDoc,
  hideDeleteDocs,
  id,
  ...rest
}) => {
  const { theme } = useTheme()
  const params = useParams()
  const { data: user } = useCurrentUser()

  const userId = id || params.id

  const { teamMember, loading: teamMemberLoading } = useTeamMembers(
    false,
    null,
    null,
    userId
  )

  const [removeId, setRemoveId] = React.useState()
  const fetchPolicy = 'network-only'
  const variables = {
    ...(userId && { fromUser: userId }),
  }

  const {
    loading: findUsersLoading,
    data,
    error,
    refetch: refetchDocuments,
  } = useQuery(findUserDocuments, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
    variables,
  })

  const [deleteDocument, { loading: deleteLoading, data: deleteResponse }] =
    useMutation(deleteUserDocument, { variables: { id: removeId } })

  const handleDelete = () => {
    deleteDocument({ variables: { id: removeId } })
    setRemoveId(null)
  }

  React.useEffect(() => {
    if (deleteResponse) refetchDocuments()
  }, [deleteResponse])

  const loading = deleteLoading || findUsersLoading || teamMemberLoading

  const to = userId
    ? `/settings/team/${userId}/documents/upload`
    : `/settings/profile/documents/upload`

  const btnProps = onAddDoc ? { onClick: onAddDoc } : { to }

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      <Actions feature="documents.add">
        <Button
          token="ADD_DOCUMENT"
          icon="plus"
          iconColor={theme.BACKGROUND_ALT}
          size={1}
          {...btnProps}
        />
      </Actions>

      {data &&
        data.userDocuments?.map((document) => (
          <DocumentCard
            document={document}
            user={teamMember ? teamMember?.userProfile : user?.userProfile}
            remove={hideDeleteDocs ? undefined : () => setRemoveId(document.id)}
          />
        ))}

      <DrawerCard
        isOpened={removeId}
        handleClickOutside={() => setRemoveId(null)}
      >
        <List padding="10px" gap="1.5rem">
          <BigMenuItem
            label={<Text token="DELETE_DOCUMENT" color={theme.TEXT_PRIMARY} />}
            icon="trashCan"
            color={theme.NEGATIVE_ACTION_LIGHT}
            iconColor={theme.NEGATIVE_ACTION_BUTTON_SECONDARY_TEXT}
            onClick={handleDelete}
          />
        </List>
      </DrawerCard>
    </CardLayoutLink>
  )
}

export default ManageUserDocumentsScene
