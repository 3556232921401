import React from 'react'
import PullToRefresh from 'pulltorefreshjs'

const DEFAULT_ELEMENT: string = 'body'
const DEFAULT_DIST: number = 80

type RefresherType = {
  enable: boolean
  distThreshold?: number
  elementWrapper?: string
}

const Refresher: React.FC<RefresherType> = ({
  enable,
  distThreshold = DEFAULT_DIST,
  elementWrapper = DEFAULT_ELEMENT,
}) => {
  React.useEffect(() => {
    if (enable) {
      PullToRefresh.init({
        mainElement: elementWrapper,
        onRefresh() {
            if (!window.location.pathname.includes('/settings/kyc')) {
            window.location.reload()
          }
        },
        distThreshold,
        distMax: distThreshold + 20,
        instructionsPullToRefresh: ' ',
        instructionsReleaseToRefresh: ' ',
        instructionsRefreshing: ' ',
      })
    }
    return () => {
      if (enable) PullToRefresh.destroyAll()
    }
  }, [])

  return null
}

export default Refresher
