import React from 'react'
import * as Yup from 'yup'
import { DateTime } from 'luxon'

import { isEmpty } from 'lodash'

import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import Form, { FormProps } from '../Form'
import Field from '../Field'
import CountrySelectField from '../fields/CountrySelectField'
import GenderSelectField from '../fields/GenderSelectField'
import DateField from '../fields/DateField'

import RadioField from '../fields/RadioField'
import FormButton from '../Button'
import FieldCard from '../../cards/FieldCard'
import FormFooter from '../FormFooter'

import FlowContext from '../../../context/FlowContext'

import { cleanNullProps } from '../../../utils/functions'
import Text, { TranslationToken } from '../../typography/Text'

import PhoneField, {
  PHONE_VALIDATOR,
  PHONE_VALIDATOR_REQUIRED,
} from '../fields/PhoneField'

import NATIONALITY_DICT from '../../../utils/nationality'
import useIsDomesticUser from '../../../hooks/useIsDomesticUser'

export type NewMemberInputDataFormProps = {
  user?: any
  submit?: any
  disabled?: boolean
  loading?: boolean
  error?: any
} & Partial<FormProps>

const fixedSchema = {
  givenName: Yup.string().required(),
  familyName: Yup.string().required(),
  gender: Yup.string().required(),
  dateOfBirth: Yup.string()
    .test('DOB', 'SIDEKICK_MUST_BE_5_YEARS_OLD', (value) => {
      return DateTime.fromISO(value).diffNow('years').years < -5
    })
    .required(),
  email: Yup.string().email().when('receivesEmail', {
    is: 'yes',
    then: Yup.string().email().required(),
  }),
  mobilePhoneNumber: PHONE_VALIDATOR.when('receivesEmail', {
    is: 'yes',
    then: PHONE_VALIDATOR_REQUIRED,
  }),
  receivesEmail: Yup.string().required()
}

const createPayload = (formData, heroMobilePhoneNumber) => {
  const payload = { ...formData }

  payload.receivesEmail = payload.receivesEmail === 'yes'
  payload.canOrderCards = payload.receivesEmail
    ? payload.canOrderCards === 'yes'
    : false

  if (!payload.receivesEmail) {
    delete payload.email
    // delete payload.mobilePhoneNumber
    payload.mobilePhoneNumber = heroMobilePhoneNumber
  }

  return cleanNullProps(payload)
}

export const regionLabelDict: { [key: string]: TranslationToken } = {
  canada: 'PROVINCE',
  default: 'REGION',
}

const isRequiredIfValues = {
  is: ( ...args )=>args.some(a=>!isEmpty( a ) ),
  then: Yup.string().required('THIS_IS_A_REQUIRED_FIELD')
}

const NewMemberInputDataForm: React.FC<NewMemberInputDataFormProps> = ({
  user,
  submit,
  disabled,
  loading,
  error,
  initialValues,
  ...rest
}) => {
  const isInternational = !useIsDomesticUser()
  const { prev } = React.useContext(FlowContext)
  const [number, setNumber] = React.useState(
    NATIONALITY_DICT[initialValues?.nationality]
  )

  const handleSubmit = (formData) =>
    submit(createPayload(formData, user?.userProfile?.mobilePhoneNumber))

  const initial = {
    receivesEmail: 'yes',
    canOrderCards: 'no',
    ...initialValues,
    principalAddress: {
      country: 'Canada'
    }
  }

  const numberValidation = number
    ? Yup.string()
        .matches(number?.format, 'INVALID_FIELD')
        .required('THIS_IS_A_REQUIRED_FIELD')
    : Yup.string().required('THIS_IS_A_REQUIRED_FIELD')

  const internationalSchema = isInternational
    ? {
      number: numberValidation,
      nationality: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
      principalAddress: Yup.object().shape({
        addressLine1: Yup.string().when( [ 'addressLine1', 'addressLine2', 'city', 'postalCode', 'region' ], isRequiredIfValues ),
        addressLine2: Yup.string(),
        city: Yup.string().when( [ 'addressLine1', 'addressLine2', 'city', 'postalCode', 'region' ], isRequiredIfValues ),
        postalCode: Yup.string().when( [ 'addressLine1', 'addressLine2', 'city', 'postalCode', 'region' ], isRequiredIfValues ),
        region: Yup.string().when( [ 'addressLine1', 'addressLine2', 'city', 'postalCode', 'region' ], isRequiredIfValues ),
      }, [
        [ 'addressLine1', 'addressLine1' ],[ 'addressLine1', 'region' ],  [ 'addressLine1', 'city' ],  [ 'addressLine1', 'postalCode' ],
        [ 'city', 'region' ], [ 'city', 'city' ], [ 'city', 'postalCode' ],
        [ 'postalCode', 'region' ],[ 'postalCode', 'postalCode' ],
        [ 'region', 'region' ]
    ])
    }
    : {
      principalAddress: Yup.object().shape({
        addressLine1: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
        addressLine2: Yup.string(),
        city: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
        country: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
        postalCode: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
        region: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
      }),
    }

  const validationSchema = Yup.object().shape({
    ...fixedSchema,
    ...internationalSchema,
  })

  return (
    <Form
      onSubmit={handleSubmit}
      disabled={disabled || loading}
      loading={loading}
      validationSchema={validationSchema}
      initialValues={initial}
      {...rest}
    >
      {(form) => {
        if (form.values.nationality) {
          setNumber(NATIONALITY_DICT[form.values.nationality])
        }
        return (
          <>
            <FieldCard label={<Text token="PERSONAL_INFORMATION" />}>
              <Field label={<Text token="FIRST_NAME" />} name="givenName" />
              <Field label={<Text token="FAMILY_NAME" />} name="familyName" />
              {isInternational && (
                <>
                  <CountrySelectField
                    abbreviation
                    label={<Text token="NATIONALITY" />}
                    name="nationality"
                  />
                  <Field
                    disabled={!form.values.nationality}
                    label={<Text token={number?.label || 'DOCUMENT_NUMBER'} />}
                    name="number"
                  />
                </>
              )}
              <GenderSelectField
                label={<Text token="WHAT_DOES_YOUR_SIDEKICK_IDENTIFY_AS" />}
                name="gender"
              />
              <DateField
                label={<Text token="DATE_OF_BIRTH" />}
                name="dateOfBirth"
              />
            </FieldCard>

            <FieldCard
              label={<Text token="ADDRESS" />}
              description={<Text token="ADDRESS_NEW_INTERNATIONAL_USER_DESCRIPTION" />}
            >
              <Field
                label={<Text token="ADDRESS_LINE_1" />}
                name="principalAddress.addressLine1"
              />
              <Field
                label={<Text token="ADDRESS_LINE_2" />}
                name="principalAddress.addressLine2"
              />
              <Field
                label={<Text token="CITY" />}
                name="principalAddress.city"
              />

              <Field
                label={<Text token="POSTAL_OR_ZIP_CODE" />}
                name="principalAddress.postalCode"
              />

              <Field
                label={<Text token="PROVINCE_STATE" />}
                name="principalAddress.region"
              />

              <CountrySelectField
                label={<Text token="COUNTRY" />}
                name="principalAddress.country"
                disabled
              />
            </FieldCard>

            <FieldCard
              label={<Text token="LOGIN_CREDENTIALS" />}
              description={<Text token="LOGIN_CREDENTIALS_INFO" />}
            >
              <RadioField
                label={<Text token="CREATE_LOGIN_FOR_THIS_SIDEKICK" />}
                name="receivesEmail"
                value="yes"
              />
              <RadioField
                label={<Text token="SIDEKICK_NOT_NEED_ACCESS_HERO_APP" />}
                name="receivesEmail"
                value="no"
              />
            </FieldCard>

            {form?.values?.receivesEmail === 'yes' && (
              <>
                <FieldCard label={<Text token="CONTACT_INFO" />}>
                  <Field label={<Text token="EMAIL" />} name="email" />

                  <PhoneField
                    label={<Text token="PHONE_NUMBER" />}
                    name="mobilePhoneNumber"
                  />
                </FieldCard>

                <FieldCard
                  label={<Text token="SIDEKICK_CARDS" />}
                  description={<Text token="SIDEKICK_CARDS_INFO" />}
                >
                  <RadioField
                    label={<Text token="YES" />}
                    name="canOrderCards"
                    value="yes"
                  />
                  <RadioField
                    label={<Text token="NO" />}
                    name="canOrderCards"
                    value="no"
                  />
                </FieldCard>
              </>
            )}

            <FormFooter>
              <ButtonGroup>
                <Button
                  button
                  onClick={prev}
                  label={<Text token="PREVIOUS_STEP" />}
                />
                <FormButton
                  label={<Text token="NEXT" />}
                  loading={loading}
                  css={{ flex: 2 }}
                />
              </ButtonGroup>
            </FormFooter>
          </>
        )
      }}
    </Form>
  )
}

export default NewMemberInputDataForm
