import React from 'react'
import { useMutation } from '@apollo/client'
import useUploadDocuments from '../../../hooks/useUploadDocuments'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import NexPayPaymentProfileDocumentForm from '../../../components/form/forms/NexPayPaymentProfileDocumentForm'
import FlowContext from '../../../context/FlowContext'
import { initializePaymentProfileDocumentUpload } from '../../../queries'
import countries from '../../../utils/countries'

const NexPayFundAccountAddDocuments = () => {
  const [filesToUpload, setFilesToUpload] = React.useState()

  const [uploadFiles, { uploadFileLoading }] = useUploadDocuments()

  const [mutateInit, { loading: initLoading, data: initData }] = useMutation(
    initializePaymentProfileDocumentUpload
  )

  const { next, currentData } = React.useContext(FlowContext)

  React.useEffect(() => {
    if (initData && filesToUpload) {
      const files = initData?.initializePaymentProfileDocumentUpload?.files

      const modelFiles = files?.map((file) => {
        const fileToUpload = filesToUpload.find(
          (fileToUpload) => fileToUpload.model.name === file.filename
        )
        return { ...file, model: fileToUpload.model }
      })

      uploadFiles(modelFiles)
    }
  }, [initData])

  const loading = uploadFileLoading || initLoading

  const handleNewPaymentProfile = async (docValues) => {
    setFilesToUpload(docValues.files)

    const variables = {
      input: {
        paymentProfileId: currentData?.data?.paymentProfile.id,
        type: docValues.type.value,
        countryCode: countries.find(
          (countryItem) => currentData?.data?.paymentProfile?.country === countryItem.country
        )?.abbreviation,
        files: docValues.files.map((file) => file.model.name),
      },
    }

    await mutateInit({ variables })
    next && next()
  }
  return (
    <FlowStepLayout loading={loading}>
      <NexPayPaymentProfileDocumentForm
        onSubmit={handleNewPaymentProfile}
        hideFooter={loading}
      />
    </FlowStepLayout>
  )
}

export default NexPayFundAccountAddDocuments
