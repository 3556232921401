import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'
import CountrySelectField from '../fields/CountrySelectField'
import Text from '../../typography/Text'
import FormHeader from '../../typography/FormHeader'

import { cleanNullProps } from '../../../utils/functions'
import CurrencyField from '../fields/CurrencyField'
import useIsDomesticUser from '../../../hooks/useIsDomesticUser'

export type FundAmountFormProps = {
  data?: any
  submit?: Function
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
  hideFooter?: boolean
}

const FundAmountForm: React.FC<FundAmountFormProps> = ({
  submit,
  loading,
  data,
  hideFooter,
  ...rest
}) => {
  const { theme } = useTheme()
  const isDomesticUser = useIsDomesticUser(true)
  const formRef = React.useRef()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const defaultInitialValues = {
    ...data,
  }

  React.useEffect(() => {
    if (isDomesticUser) {
      formRef?.current?.setFieldValue('country', data?.country || 'Canada')
    }
  }, [isDomesticUser])

  const schema = Yup.object().shape({
    amount: Yup.number()
      .moreThan(!isDomesticUser ? 99 : 0)
      .required(),
    country: Yup.string().required(),
  })

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      innerRef={formRef}
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      {(form) => {
        return (
          <>
            <FormHeader token="HOW_MUCH_WOULD_YOU_LIKE_TO_ADD" />
            <CurrencyField
              label={<Text token="AMOUNT" uppercase />}
              prefix="$ "
              name="amount"
              css={styles.field}
            />

            <CountrySelectField
              label={<Text token="COUNTRY_YOU_ARE_PAYING_FROM" />}
              name="country"
              css={styles.field}
              disableList={['CA']}
              disabled={isDomesticUser}
            />

            {!hideFooter && (
              <FormFooter css={styles.footer}>
                <FormButton token="NEXT" />
              </FormFooter>
            )}
          </>
        )
      }}
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default FundAmountForm
