import React from 'react'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import usePaymentProfiles from '../../../hooks/usePaymentProfiles'
import PaymentProfileOption from '../../../components/lists/items/PaymentProfileOption'
import Button from '../../../components/Button'
import Actions from '../../../components/Actions'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

const SettingsPaymentProfilesScene = ({ ...rest }) => {
  const { theme } = useTheme()
  const { data, loading, refetch } = usePaymentProfiles()
  const navigate = useNavigate()

  const [search, setSearch] = useSearchParams()

  const isRefetching = JSON.parse(search.get('refetch'))

  React.useEffect(() => {
    if (isRefetching) {
      refetch()
      setSearch()
    }
  }, [isRefetching])

  const { paymentProfiles } = data || {}
  const handleSelect = (value) =>
    navigate(`/settings/payment-profiles/${value?.id}/documents`)

  return (
    <CardLayoutLink loading={loading} {...rest}>
      <Actions>
        <Button
          token="ADD_PAYMENT_PROFILE"
          icon="plus"
          iconColor={theme.BACKGROUND_ALT}
          size={1}
          to="add-new"
        />
      </Actions>

      {paymentProfiles &&
        paymentProfiles.map((profile) => {
          const status = profile.isIdentityVerified
            ? 'Verified'
            : profile?.paymentProfileDocuments?.length
            ? 'Pending'
            : undefined

          return (
            <PaymentProfileOption
              key={profile.id}
              option={profile}
              onSelect={(value) => handleSelect(value)}
              status={status}
              hideRadio
            />
          )
        })}
    </CardLayoutLink>
  )
}

export default SettingsPaymentProfilesScene
