import React from 'react'

import Button from '../../../components/Button'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import FormHeader from '../../../components/typography/FormHeader'
import FormFooter from '../../../components/form/FormFooter'
import Detail from '../../../components/typography/Detail'
import Text from '../../../components/typography/Text'

import FlowContext from '../../../context/FlowContext'
import useWaitForNotification from '../../../hooks/useWaitForNotification'
import HeroSpinner from '../../../components/Spinner'
import Flex from '../../../components/Flex'
import PendingRequestCard from '../../settings/fundingRequests/PendingRequestCard/PendingRequestCard'
import { useLazyQuery } from '@apollo/client'
import { teamHoldingAccount } from '../../../queries'
import IconCircle from '../../../components/IconCircle'

const NexPayFundAccountCompletePaymentStep = () => {
  const { next, currentData } = React.useContext(FlowContext)

  const [uploading, setUploading] = React.useState(false)

  const [fetch, { data, loading: loadingTeamHoldingAccount }] = useLazyQuery(
    teamHoldingAccount,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { data: notificationError } = useWaitForNotification([
    'FundsTransferFailed',
  ])

  const { data: foundPendingNotification } = useWaitForNotification([
    'FundsTransferPendingProofOfPayment',
  ])
  const { data: foundPendingTransferNotification } = useWaitForNotification([
    'FundsTransferInitiated',
  ])

  const { data: foundNotification } = useWaitForNotification([
    'FundsTransferProcessing',
  ])

  const isPaymentProcessed =
    !!foundPendingNotification || !!foundPendingTransferNotification

  const isBankTransfer =
    currentData.data.paymentMethod.selectedQuote.settlementMethod === 'BT'

  const payment =
    data?.teamHoldingAccount?.holdingAccountTransactions?.edges?.find?.(
      (edge) => edge?.node?.transactionId === currentData.data.transactionId
    )

  React.useEffect(() => {
    if (!!foundPendingNotification || !!foundPendingTransferNotification) {
      fetch()
    }
  }, [foundPendingNotification, foundPendingTransferNotification])

  React.useEffect(() => {
    if (foundNotification) {
      setUploading(false)
    }
  }, [foundNotification])

  return (
    <FlowStepLayout loading={uploading}>
      {!isPaymentProcessed && (
        <Flex vertical verticalAlignment="center" gap="1rem">
          <Text token="WE_ARE_PROCESSING_YOUR_PAYMENT_REQUEST" />
          <HeroSpinner size={48} />
          <Detail token="WE_ARE_PROCESSING_YOUR_PAYMENT_REQUEST_DETAILS" />
        </Flex>
      )}
      {((isPaymentProcessed && loadingTeamHoldingAccount) || uploading) && (
        <Flex vertical verticalAlignment="center" gap="1rem">
          <HeroSpinner size={48} />
          {uploading && <Text token="UPLOADING_PROOF_OF_PAYMENT" />}
        </Flex>
      )}

      {isPaymentProcessed && isBankTransfer && payment && !foundNotification && (
        <>
          <FormHeader token="CONFIRM_PAYMENT" />
          <PendingRequestCard
            hideCancel
            hideDate
            fundingRequest={payment}
            setUploading={setUploading}
          />
        </>
      )}
      {isPaymentProcessed && isBankTransfer && payment && !!foundNotification && (
        <>
          <FormHeader token="CONFIRM_PAYMENT" />
          <Text token="PROOF_OF_PAYMENT_UPLOADED" />
        </>
      )}

      {isPaymentProcessed && !isBankTransfer && payment && (
        <>
          <Text token="USE_THE_LINK_BELOW_TO_PAY" />

          <PendingRequestCard hideCancel hideDate fundingRequest={payment} />

          <Detail token="RETURN_TO_UPLOAD_POP" />
        </>
      )}

      {notificationError && (
        <Flex vertical center gap="1rem">
          <IconCircle
            icon="error"
            size={33}
            scale={2}
            iconColor="ERROR"
          />
          <Text token="SOMETHING_WENT_WRONG" />
          <Detail token="TRANSACTION_FUNDS_FAILED" />
        </Flex>
      )}

      <FormFooter>
        <Button
          button
          onClick={next}
          token={
            !isPaymentProcessed ||
            (isBankTransfer && !foundNotification) ||
            !isPaymentProcessed
              ? 'ILL_DO_IT_LATER'
              : 'NEXT'
          }
        />
      </FormFooter>
    </FlowStepLayout>
  )
}

export default NexPayFundAccountCompletePaymentStep
