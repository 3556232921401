import React from 'react'
import * as Yup from 'yup'
import { TPlainStyle } from '@emotion/react'

import Form from '../Form'

import Text from '../../typography/Text'
import FormHeader from '../../typography/FormHeader'
import FormFooter from '../FormFooter'
import CountrySelectField from '../fields/CountrySelectField'
import Button from '../Button'
import SelectDrawerField from '../fields/SelectDrawerField'
import SelectDrawerDocumentTypeOption, {
  DocumentTypeItem,
} from '../../drawers/options/SelectDrawerDocumentTypeOption'

import FilesField from '../fields/FilesField'
import countries from '../../../utils/countries'
import SelectDrawerMemberOption, {
  MemberItem,
} from '../../drawers/options/SelectDrawerMemberOption'

// Helpers
export const getMemberId = (member) => member?.userProfile?.id
export const getMemberById = (members, id) =>
  members?.find((member) => getMemberId(member) === id)
export const isCountryRequired = (documentType) =>
  documentType === 'ID_DOCUMENT' ||
  documentType === 'PASSPORT' ||
  documentType === 'LETTER_OF_ACCEPTANCE'

// Types
type UploadDocumentFormProps = {
  teamMembers: Array<any>
  onSubmit: Function
  user: any
  loading?: boolean
  footer?: JSX.Element
  hideTitle?: boolean
}

export const documentIdTypes = [
  { label: 'Government ID', value: 'ID_DOCUMENT' },
  { label: 'Passport / Visa', value: 'PASSPORT' },
]

const documentTypes = [
  ...documentIdTypes,
  { label: 'School Documents', value: 'LETTER_OF_ACCEPTANCE' },
]

const schema = Yup.object().shape({
  user: Yup.object().required(),
  documentType: Yup.object().required(),
  files: Yup.array().min(1),
  country: Yup.string().when('documentType', (documentType, schema) => {
    if (
      documentType?.value === 'PASSPORT' ||
      documentType?.value === 'LETTER_OF_ACCEPTANCE'
    )
      return schema.required().not(['-'])

    return schema
  }),
})

const UploadDocumentForm: React.FC<UploadDocumentFormProps> = ({
  user,
  teamMembers,
  onSubmit,
  loading,
  footer,
  hideTitle,
  ...rest
}) => {
  const formRef = React.useRef(null)

  const [members] = React.useState(teamMembers)

  const handleSubmit = (formData) => {
    const user = formData.user
    const documentType = formData.documentType
    const files = formData.files

    const result = {
      input: {
        forUser: user?.userProfile.id,
        type: documentType?.value,
        files: files.map((file) => file.model.name),

        ...(isCountryRequired(documentType?.value) && {
          countryCode: formData.country,
        }),
      },
    }

    onSubmit(result, files)
  }

  const selectedCountry = user?.userProfile?.principalAddress?.country
  const country = countries.find(
    (countryItem) => selectedCountry === countryItem.country
  )

  return (
    <Form
      loading={loading}
      innerRef={formRef}
      initialValues={{
        user,
        country: country?.abbreviation,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {(form) => {
        return (
          <>
            {!hideTitle && (
              <FormHeader css={styles.heading} token="WHOS_DOCUMENT" />
            )}

            <SelectDrawerField
              label="User"
              name="user"
              result={() => <MemberItem user={user} withInitials={false} />}
              required
              disabled
            >
              {members &&
                members.map((member) => {
                  return <SelectDrawerMemberOption option={member} />
                })}
            </SelectDrawerField>

            <SelectDrawerField
              label="Document Type"
              name="documentType"
              result={(documentType) => (
                <DocumentTypeItem documentType={documentType} />
              )}
              required
              disabled={loading}
            >
              {documentTypes &&
                documentTypes.map((documentType) => {
                  return (
                    <SelectDrawerDocumentTypeOption option={documentType} />
                  )
                })}
            </SelectDrawerField>

            {isCountryRequired(form?.values?.documentType?.value) && (
              <CountrySelectField
                label={<Text token="COUNTRY" />}
                name="country"
                abbreviation
                required
                disabled={loading}
              />
            )}

            <Text token="MAX_FILE_SIZE_2MB" css={styles.fileTypesDescription} />

            <FilesField
              name="files"
              accept=".pdf, .jpg, .png, .jpeg, .bmp, .tif"
              disabled={loading}
            />

            <FormFooter>
              {footer}
              <Button loading={loading} token="UPLOAD" />
            </FormFooter>
          </>
        )
      }}
    </Form>
  )
}

const styles: TPlainStyle = {
  addFunds: {
    padding: 8,
  },
  text: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  price: {
    fontSize: '32px',
    marginBottom: 10,
  },
  priceSmall: {
    fontSize: '18px',
  },
  fileTypesDescription: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
}

export default UploadDocumentForm
