import React from 'react'
import useAxios, { UseAxiosResult } from 'axios-hooks'
import { useSelector } from 'react-redux'

type Address = {
  addressLine1: string
  city: string
  country: string
  postalCode: string
  region: string
}

type UserData = {
  givenName: string
  familyName: string
  gender: string
  dateOfBirth: string
  email: string
  mobilePhoneNumber: string
  principalAddress: Address
}

interface UserFactory {
  (): UseAxiosResult<UserData>
}

const useRandomUser: UserFactory = () => {
  const request = useAxios('https://randomuser.me/api?nat=ca', {
    manual: true,
  })

  const sessionUser = useSelector((state) => state.auth.user)

  const baseDomain = sessionUser?.attributes?.email?.split?.('@')?.[0] || 'jay'
  const [requestState, ...requestRest] = request
  const { data, ...requesStateRest } = requestState

  const user = React.useMemo(() => {
    const user = data?.results?.[0]

    if (!user) return null
    return {
      givenName: user?.name?.first,
      familyName: user?.name?.last,
      number: 'PEGJ770826MQTRZL34',
      nationality: 'MX',
      gender: user?.gender?.toUpperCase(),
      dateOfBirth: '1984-03-03',
      email: `${baseDomain}+auto+${user?.name?.first?.toLowerCase()}.${user?.name?.last?.toLowerCase()}@herofinancials.com`,
      mobilePhoneNumber: '+18884521598',
      principalAddress: {
        addressLine1: `${user?.location?.street?.number} ${user?.location?.street?.name}`,
        city: user?.location?.city,
        country: user?.location?.country,
        postalCode: user?.location?.postcode?.replaceAll?.(' ', ''),
        region: user?.location?.state,
      },
    }
  }, [data])

  return [{ data: user, ...requesStateRest }, ...requestRest]
}

export default useRandomUser
