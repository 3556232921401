import React from 'react'
import isEqual from 'lodash/isEqual'

import Text from '../../typography/Text'
import Element from '../../Element'
import Flex from '../../Flex'
import OptionCircle from './OptionCircle'
import FieldText from '../../typography/FieldText'
import ComponentCard from '../../cards/ComponentCard'
import { SelectDrawerFieldContext } from '../../form/fields/SelectDrawerField'
import IconCircle from '../../IconCircle'
import { useTheme } from '@emotion/react'

const CARD_STYLE_DICT = {
  'Bank Transfer': {
    icon: 'bank',
    scale: 1.7,
  },
  'VISA Credit-Debit': {
    icon: 'visa',
    scale: 0.05,
  },
  'VISA Credit': {
    icon: 'visa',
    scale: 0.05,
  },
  'VISA Debit': {
    icon: 'visa',
    scale: 0.05,
  },
  'Mastercard Credit-Debit': {
    icon: 'mastercard',
    scale: 0.05,
  },
  'Mastercard Credit': {
    icon: 'mastercard',
    scale: 0.05,
  },
  'Mastercard Debit': {
    icon: 'mastercard',
    scale: 0.05,
  },
  default: {
    icon: 'card',
    scale: 1.4,
  },
}

export const PaymentMethodItem: React.FC<any> = ({ card, amount, ...rest }) => {
  const cardIcon = CARD_STYLE_DICT[card.type] || CARD_STYLE_DICT.default

  return (
    <Flex centerY gap="0.5rem">
      <IconCircle
        icon={cardIcon?.icon}
        size={56}
        scale={cardIcon?.scale}
        iconColor="TEXT_PRIMARY_DARK"
        color="FUNDING_METHOD_OPTION_ICON_BACKGROUND"
      />
      <Flex vertical gap="0.1rem">
        <FieldText token={card.type} />
        <FieldText
          size={14}
          light
          token="RATE"
          interpolation={{
            money: card.rate.toFixed(2),
            fromCurrency: card.fromCurrency,
            toCurrency: amount.currencyKey,
          }}
        />
        <FieldText
          token="PAYERS_PAYS"
          size={14}
          interpolation={{
            currency: card.fromCurrency,
            money: card.fromAmount,
          }}
        />
      </Flex>
    </Flex>
  )
}

const SelectPaymentMethodItem = ({
  card,
  selected,
  onClick,
  disabled,
  amount,
  hideRadio,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [])

  return (
    <Element css={styles.cardWrapper}>
      <ComponentCard
        css={disabled && { opacity: 0.5 }}
        selected={selected}
        onClick={(e) => !disabled && onClick(e)}
      >
        <Flex centerY spread>
          <PaymentMethodItem card={card} amount={amount} {...rest} />
          {!hideRadio && <OptionCircle selected={selected} />}
        </Flex>
      </ComponentCard>
      <Text
        css={styles.provider}
        token="POWERED_BY_PROVIDER"
        interpolation={{
          provider: card.provider,
        }}
      />
    </Element>
  )
}

type SelectPaymentMethodProps = {
  option: any
  onSelect?: Function
  disabled?: boolean
  amount: Object
  selectedValue?: any
  hideRadio?: boolean
}

const SelectPaymentMethod: React.FC<SelectPaymentMethodProps> = ({
  option,
  amount,
  onSelect = () => {},
  disabled,
  selectedValue,
  hideRadio,
  ...rest
}) => {
  return (
    <SelectDrawerFieldContext.Consumer>
      {({ selected, select }) => (
        <SelectPaymentMethodItem
          hideRadio={hideRadio}
          card={option}
          amount={amount}
          disabled={disabled}
          selected={isEqual(selectedValue || selected, option)}
          onClick={() => (select ? select(option) : onSelect(option))}
          {...rest}
        />
      )}
    </SelectDrawerFieldContext.Consumer>
  )
}

const computeStyles = ({ PRIMARY, CARD_BORDER_DARK }) => ({
  cardWrapper: {
    background: CARD_BORDER_DARK,
    borderRadius: '10px',
    paddingBottom: 8,
  },
  provider: {
    padding: '1rem',
    fontSize: '0.8rem',
    color: PRIMARY,
  },
})

export default SelectPaymentMethod
