import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Flex from '../../../components/Flex'
import ChangePasswordForm from '../../../components/form/forms/ChangePasswordForm'
import { updateUserPassword } from '../../../services/auth'
import { useNavigate } from 'react-router-dom'

const ChangePasswordScene = ({ ...rest }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const error = useSelector(
    (state: { auth: { error: { [key: string]: string } } }) => state.auth.error
  )
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
      dispatch.auth.CLEAR_ERROR()
    }
  }, [error])

  const handleSubmit = async (formData) => {
    setLoading(true)
    try {
      await updateUserPassword(formData.oldPassword, formData.password)
      setLoading(false)
      toast.success('PASSWORD_CHANGE_SUCCESS')
      navigate('/settings/profile/login-details')
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      <Flex vertical centerY>
        <Flex vertical padding="1rem" css={styles.content}>
          <ChangePasswordForm loading={loading} submit={handleSubmit} />
        </Flex>
      </Flex>
    </CardLayoutLink>
  )
}

const styles: any = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default ChangePasswordScene
