import React from 'react'

import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import StatusCard from './StatusCard'
import Text, { TranslationToken } from '../typography/Text'
import clsx from 'clsx'

export type LinkUserStatusProps = {
  accepted?: boolean
  acceptedToken?: string
  nonAcceptedToken?: string
  className?: string
  label?: string | JSX.Element
}

const LinkUserStatus: React.FC<LinkUserStatusProps> = ({
  accepted,
  nonAcceptedToken = 'PENDING',
  acceptedToken = 'ACCEPTED',
  className,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    'is-accepted': accepted,
  })
  return (
    <StatusCard
      className={classNames}
      css={styles.root}
      status={
        <Text
          token={
            (accepted ? acceptedToken : nonAcceptedToken) as TranslationToken
          }
        />
      }
      {...rest}
    />
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_SECONDARY,
  INVITATION_PENDING,
  INVITATION_ACCEPTED,
}: TTheme) => ({
  root: {
    '& > :last-child': {
      color: TEXT_SECONDARY,
      backgroundColor: INVITATION_PENDING,
    },
    '&.is-accepted > :last-child': {
      backgroundColor: INVITATION_ACCEPTED,
    },
  },
})

export default LinkUserStatus
