import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import NexPayPayerIdentityForm, {
  paymentProfileFieldName,
} from '../../../components/form/forms/NexPayPayerIdentityForm'
import FlowContext from '../../../context/FlowContext'
import usePaymentProfiles from '../../../hooks/usePaymentProfiles'
import countries from '../../../utils/countries'

export const newPaymentProfileFieldName = 'newPaymentProfile'


const NexPayFundAccountPayerStep = ( { currentUser })=> {

  const {
    data: paymentProfiles,
    loading: paymentProfileLoading
  } = usePaymentProfiles(true)

  const profiles = paymentProfiles?.paymentProfiles || []

  const {
    setFlowDataAttribute,
    currentData
  } = React.useContext(FlowContext)


  const handlePayerChange = async (selected) => {

    if (selected?.new || selected?.newFromCurrent) {

      setFlowDataAttribute(newPaymentProfileFieldName, selected, true)
      setFlowDataAttribute(paymentProfileFieldName, selected, true)

    } else {

      setFlowDataAttribute(newPaymentProfileFieldName, {}, true)
      setFlowDataAttribute(paymentProfileFieldName, selected, true)

    }

  }

  const { abbreviation } =
    countries.find(({ country }) => country === currentData?.data?.country) ||
    {}

  return (
    <FlowStepLayout loading={paymentProfileLoading}>
      <NexPayPayerIdentityForm
        selectedCountryAbbreviation={abbreviation}
        paymentProfiles={profiles}
        currentUser={ currentUser }
        onChange={handlePayerChange}
        hideFooter={currentData?.meta?.loading || paymentProfileLoading}
      />
    </FlowStepLayout>
  )
}

export default NexPayFundAccountPayerStep
