import React from 'react'

import Form from '../../form/Form'

import * as Yup from 'yup'

import Button from '../../Button'
import FormFooter from '../../form/FormFooter'
import FormHeader from '../../typography/FormHeader'

import PaymentProfileOption, {
  NewPaymentProfileOption,
  CurrentUserProfileOption
} from '../../lists/items/PaymentProfileOption'

import FlowContext from '../../../context/FlowContext'
import Detail from '../../typography/Detail'
import PaymentProfileVerifyIdBanner from '../../PaymentProfileVerifyIdBanner'

export const paymentProfileFieldName = 'paymentProfile'

const schema = Yup.object({
  [paymentProfileFieldName]: Yup.mixed().required(),
})

type NexPayPayerIdentityFormProps = {
  onChange: Function
  selectedCountryAbbreviation: string
  paymentProfiles: Array<any>
  hideFooter?: boolean
  currentUser: { userProfile: { email: string } }
}

const NexPayPayerIdentityForm: React.FC<NexPayPayerIdentityFormProps> = ({
  onChange,
  selectedCountryAbbreviation,
  paymentProfiles,
  currentUser,
  hideFooter,
  ...rest
}) => {
  const formRef = React.useRef(null)
  const { next, prev, currentData } = React.useContext(FlowContext)

  const handleSubmit = async () => {
    if (formRef?.current.isValid) {
      next()
    }
  }

  const handleNextBtn = async () => {
    await formRef?.current?.submitForm()
  }

  const handleChange = async (name, value) => {
    await formRef?.current.setFieldValue(name, value)
    onChange(value)
  }

  return (
    <Form
      initialValues={{
        paymentProfile: currentData?.data?.[paymentProfileFieldName],
      }}
      innerRef={formRef}
      validateOnChange
      validationSchema={schema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {(form) => {

        const hasProfileForCurrentUser
          = paymentProfiles?.some( ({ email } )=>currentUser?.userProfile.email == email ) // eslint-disable-line eqeqeq

        return (
          <>
            <FormHeader token="VERIFY_PAYER_IDENTITY" />

            <Detail token="WHO_IS_MAKING_THE_PAYMENT" />

            {paymentProfiles &&
              paymentProfiles.map((profile) => {
                const status =
                  !profile.isIdentityVerified &&
                  profile?.paymentProfileDocuments?.length
                    ? 'Pending'
                    : undefined

              const countryMatch =
                  profile?.nationality === selectedCountryAbbreviation
                const disabled = !profile.isIdentityVerified || !countryMatch

                return (
                  <>
                    <PaymentProfileOption
                      disabled={disabled}
                      key={profile.id}
                      option={profile}
                      status={status}
                      onSelect={(value) =>
                        handleChange(paymentProfileFieldName, value)
                      }
                      selectedValue={form?.values?.[paymentProfileFieldName]}
                    />
                    {!countryMatch && (
                      <Detail token="PAYMENT_PROFILE_COUNTRY_NOT_MATCH" />
                    )}

                    {countryMatch && !profile.isIdentityVerified && !status && (
                      <PaymentProfileVerifyIdBanner user={profile} />
                    )}
                  </>
                )
              })}


            {!hasProfileForCurrentUser && <CurrentUserProfileOption
              onSelect={(value) => handleChange(paymentProfileFieldName, value)}
              selectedValue={form?.values?.[paymentProfileFieldName]}
              option={currentData?.data?.[paymentProfileFieldName]}
              currentUser={currentUser}
            />}
            <NewPaymentProfileOption
              onSelect={(value) => handleChange(paymentProfileFieldName, value)}
              selectedValue={form?.values?.[paymentProfileFieldName]}
              option={currentData?.data?.[paymentProfileFieldName]}
            />
            {!hideFooter && (
              <FormFooter>
                <Button button token="PREVIOUS_STEP" onClick={prev} />
                <Button button onClick={handleNextBtn} token="NEXT" />
              </FormFooter>
            )}
          </>
        )
      }}
    </Form>
  )
}

export default NexPayPayerIdentityForm
