import React, { Children } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import filter from 'lodash/filter'

import FlowContext from '../../context/FlowContext'
import { FlowStateType } from '../../store/flow'

export type FlowProps = {
  name: string
  stepChildren: any
  keepDirty?: boolean
  cancel?: Function
  to?: any
  start?: number
  ref?: any
} & React.PropsWithChildren

export type FlowStepProps = {
  title?: JSX.Element
  element?: JSX.Element | React.FC
  condition?: Function
} & React.PropsWithChildren

export const FlowStep: React.FC<FlowStepProps> = () => null

const Flow: React.FC<FlowProps> = ({
  name,
  cancel,
  stepChildren,
  keepDirty,
  to,
  start = 0,
  children,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentData = useSelector((state: FlowStateType) => state.flow[name])

  const steps = Children.map(stepChildren, (child) => child.props) || []

  const getCurrentSteps = () =>
    filter(steps, (step) => {
      if (!step.condition) return true

      return step.condition(currentData?.data, currentData?.meta)
    })

  const [currentSteps, setCurrentSteps] = React.useState(getCurrentSteps())

  const [currentIndex, setCurrentIndex] = React.useState(
    start || (currentData?.meta?.step ? currentData?.meta?.step - 1 : 0)
  )
  const [currentStep, setCurrentStep] = React.useState(steps[start])

  const next = () => {
    if (currentData?.meta.completed) return navigate(to || cancel)

    if (currentIndex + 1 < currentSteps.length)
      setCurrentIndex(Math.min(currentIndex + 1, currentSteps.length - 1))
    else navigate(to || cancel)
  }

  const prev = () =>
    setCurrentIndex(currentIndex - 1 > 0 ? currentIndex - 1 : 0)

  const setStep = (index) => setCurrentIndex(index)

  const reloadSteps = () => setCurrentSteps(getCurrentSteps())

  React.useLayoutEffect(() => {
    setCurrentStep(currentSteps[currentIndex])
    dispatch.flow.SET_FLOW_META(name, { step: currentIndex + 1 })
  }, [currentIndex, currentSteps.length])

  React.useLayoutEffect(() => setCurrentSteps(getCurrentSteps()), [currentData])

  React.useEffect(() => {
    dispatch.flow.SET_FLOW(name, { meta: { keepDirty } })
    return () => dispatch.flow.CLEAR_FLOW(name)
  }, [])

  const nextFlow = async (to) => {
    await setKeepDirty(true)

    navigate(to)
  }

  const setFlowData = async (data) =>
    await dispatch.flow.SET_FLOW_DATA(name, data)

  const setFlowDataAttribute = async (attribute, data, overlap) =>
    await dispatch.flow.SET_FLOW_DATA_ATTRIBUTE(name, { attribute, data , overlap})

  const setFlowMeta = async (meta) =>
    await dispatch.flow.SET_FLOW_META(name, meta)

  const setKeepDirty = async (keepDirty) =>
    await dispatch.flow.SET_FLOW_META(name, { keepDirty })

  const CurrentFlowStep = currentStep?.element
  const currentOutlet = <CurrentFlowStep {...currentStep?.props} />

  return (
    <FlowContext.Provider
      value={{
        next,
        prev,
        currentIndex,
        currentStep,
        steps: currentSteps,
        name,
        cancel,
        currentData,
        currentOutlet,
        setFlowData,
        setFlowDataAttribute,
        setFlowMeta,
        setKeepDirty,
        nextFlow,
        setStep,
        reloadSteps,
      }}
    >
      {children}
    </FlowContext.Provider>
  )
}

export default Flow
