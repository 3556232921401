import React from 'react'

import FormHeader from '../../../components/typography/FormHeader'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import FlowContext from '../../../context/FlowContext'
import countries from '../../../utils/countries'
import useTeamMembers from '../../../hooks/useTeamMembers'
import NexPayReceivePaymentForm from '../../../components/form/forms/NexPayReceivePaymentForm'

const NexPayFundAccountWhoReceiveStep: React.FC = () => {
  const { teamMembers, loading } = useTeamMembers(
    true,
    false
  )
  const { currentData } = React.useContext(FlowContext)

  const { abbreviation } =
    countries.find(({ country }) => country === currentData?.data?.country) ||
    {}
  return (
    <FlowStepLayout loading={loading}>
      <FormHeader token="WHO_WILL_RECEIVE_THESE_FUNDS" />
      <NexPayReceivePaymentForm
        selectedCountryAbbreviation={abbreviation}
        teamMembers={teamMembers}
        hideFooter={loading}
      />
    </FlowStepLayout>
  )
}

export default NexPayFundAccountWhoReceiveStep
