import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import ListItemLink, { ListItemLinkProps } from './ListItemLink'
import withFeature from '../../hocs/withFeature'
import clsx from 'clsx'

export type NotificationsListItemLinkProps = {
  notificationsCount?: number
} & ListItemLinkProps

const NotificationsListItemLink: React.FC<NotificationsListItemLinkProps> = ({
  notificationsCount,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const NOTIFICATIONS_COUNT_OVERFLOW_LIMIT = 100

  const OVERFLOW_NOTIFICATIONS_COOUNT_LABEL = '+99'

  const countOverflow = notificationsCount > NOTIFICATIONS_COUNT_OVERFLOW_LIMIT
  const classNames = clsx({
    'is-overflow': countOverflow,
  })

  const countLabel = countOverflow
    ? OVERFLOW_NOTIFICATIONS_COOUNT_LABEL
    : notificationsCount

  return (
    <ListItemLink {...rest}>
      {!!notificationsCount && (
        <span className={classNames} css={styles.count}>
          {countLabel}
        </span>
      )}
    </ListItemLink>
  )
}

const computeStyles: TComputeStyles = ({
  NOTIFICATION_DOT,
  TEXT_SECONDARY,
}: TTheme) => ({
  root: {},
  count: {
    alignItems: 'center',
    background: NOTIFICATION_DOT,
    borderRadius: '50%',
    color: TEXT_SECONDARY,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    lineHeight: 1,
    width: 24,
    '&.is-overflow': {
      fontSize: 11,
    },
  },
})

export default withFeature(NotificationsListItemLink)
