import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import UploadDocumentForm from '../../../components/form/forms/UploadDocumentForm'
import ConfirmModal from '../../../components/modals/ConfirmModal'

import {
  teamMembers,
  initializeUserDocumentUpload,
  findUserDocuments,
} from '../../../queries'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useTeamMembers from '../../../hooks/useTeamMembers'
import useUploadDocuments from '../../../hooks/useUploadDocuments'

type UploadUserDocumentsSceneProps = {
  onFinish?: Function
  id?: string
  footer?: JSX.Element
  hideTitle?: boolean
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  back?: string | boolean
}

const UploadUserDocumentsScene: React.FC<UploadUserDocumentsSceneProps> = ({
  onFinish,
  id,
  footer,
  hideTitle,
  ...rest
}) => {
  const navigate = useNavigate()
  const params = useParams()

  const [alert, setAlert] = React.useState(false)

  const { loading, data, error } = useQuery(teamMembers)

  const userId = id || params.id

  const {
    teamMember,
    loading: teamMemberLoading,
    refetch,
  } = useTeamMembers(true, null, null, userId)

  const [filesToUpload, setFilesToUpload] = React.useState([])
  const [aboutToNavigate, setAboutToNavigate] = React.useState(false)
  const { data: user, loading: userLoading } = useCurrentUser()

  const fetchPolicy = 'network-only'
  const variables = {
    ...(userId && { fromUser: userId }),
  }

  const { loading: findUsersLoading, data: findUsersDocumentsData } = useQuery(
    findUserDocuments,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy,
      variables,
    }
  )

  const [mutateInit, { loading: initLoading, data: initData }] = useMutation(
    initializeUserDocumentUpload
  )

  const [uploadFiles, { uploadFileLoading }] = useUploadDocuments()

  React.useEffect(() => {
    if (uploadFileLoading) {
      setAboutToNavigate(true)
      setTimeout(() => {
        if (onFinish) {
          onFinish()
        } else {
          navigate(
            userId
              ? `/settings/team/${userId}/documents?refresh`
              : '/settings/profile/documents?refresh'
          )
        }
      }, 8000)
    }
  }, [uploadFileLoading])

  React.useEffect(() => {
    if (initData && filesToUpload.length) {
      const files = initData?.initializeUserDocumentUpload?.files

      const modelFiles = files?.map((file) => {
        const fileToUpload = filesToUpload.find(
          (fileToUpload) => fileToUpload.model.name === file.filename
        )
        return { ...file, model: fileToUpload.model }
      })

      uploadFiles(modelFiles)
    }
  }, [initData])

  const handleSubmit = async (formData, files) => {
    const hasOneOfSelectedType = findUsersDocumentsData?.userDocuments?.find(
      (doc) => doc.type === formData.input.type
    )

    if (hasOneOfSelectedType) {
      setAlert(true)
    } else {
      setFilesToUpload(files)
      await mutateInit({ variables: formData })
      await refetch()
    }
  }

  return (
    <CardLayoutLink
      loading={loading || findUsersLoading || initLoading}
      error={error}
      {...rest}
    >
      <UploadDocumentForm
        user={userId ? teamMember : user}
        teamMembers={data?.teamMembers}
        footer={footer}
        hideTitle={hideTitle}
        loading={
          loading ||
          initLoading ||
          uploadFileLoading ||
          aboutToNavigate ||
          teamMemberLoading ||
          userLoading
        }
        onSubmit={handleSubmit}
      />

      <ConfirmModal
        title="FILE_TYPE_EXISTS"
        content="FILE_TYPE_EXISTS_MESSAGE"
        confirm={() => setAlert(false)}
        handleClickOutside={() => setAlert(false)}
        isOpened={alert}
        confirmLabel="OK"
      />
    </CardLayoutLink>
  )
}

export default UploadUserDocumentsScene
