import React from 'react'
import isEqual from 'lodash/isEqual'

import AvatarInitials from '../../AvatarInitials'
import Flex from '../../Flex'
import OptionCircle from './OptionCircle'
import FieldText from '../../typography/FieldText'
import ComponentCard from '../../../components/cards/ComponentCard'
import { SelectDrawerFieldContext } from '../../form/fields/SelectDrawerField'
import { useTranslation } from 'react-i18next'
import { getFullName } from '../../../utils/functions'
import IconCircle from '../../IconCircle'

export const MemberItem = ({ user, withInitials = true, ...rest }) => (
  <Flex centerY gap="1rem">
    {withInitials && (
      <AvatarInitials
        color="YELLOW"
        size={50}
        user={user?.userProfile || user}
      />
    )}
    <FieldText {...rest}>
      {user ? getFullName(user.userProfile || user) : '-'}
    </FieldText>
  </Flex>
)

const SelectDrawerUserOptionItem = ({
  user,
  selected,
  onClick,
  disabled,
  hideRadio,
  ...rest
}) => {
  return (
    <ComponentCard
      css={disabled && { opacity: 0.5 }}
      selected={selected}
      onClick={(e) => !disabled && onClick(e)}
    >
      <Flex centerY spread>
        <MemberItem user={user} {...rest} />
        {!hideRadio && <OptionCircle selected={selected} />}
      </Flex>
    </ComponentCard>
  )
}

export const SelectDrawerNewMemberOption: React.FC<any> = ({
  option,
  selectedValue,
  onSelect = () => {},
}) => {
  const { t } = useTranslation()
  const newData = { new: true, givenName: t('SOMEONE_NEW'), familyName: '' }

  return (
    <SelectDrawerFieldContext.Consumer>
      {({ selected, select }) => (
        <ComponentCard
          selected={(selectedValue || option)?.new}
          onClick={() => (select ? select(newData) : onSelect(newData))}
        >
          <Flex centerY spread>
            <Flex centerY gap="1rem">
              <IconCircle size={50} icon="user" color="PRIMARY_ALT" />
              <FieldText token="SOMEONE_NEW" />
            </Flex>
            <OptionCircle selected={(selectedValue || option)?.new} />
          </Flex>
        </ComponentCard>
      )}
    </SelectDrawerFieldContext.Consumer>
  )
}

type SelectDrawerMemberOptionProps = {
  option: any
  onSelect?: Function
  disabled?: boolean
  selectedValue?: any
  hideRadio?: boolean
}

const SelectDrawerMemberOption: React.FC<SelectDrawerMemberOptionProps> = ({
  option,
  onSelect = () => {},
  disabled,
  selectedValue,
  hideRadio,
  ...rest
}) => (
  <SelectDrawerFieldContext.Consumer>
    {({ selected, select }) => (
      <SelectDrawerUserOptionItem
        hideRadio={hideRadio}
        user={option}
        disabled={disabled}
        selected={isEqual(selectedValue || selected, option)}
        onClick={() => (select ? select(option) : onSelect(option))}
        {...rest}
      />
    )}
  </SelectDrawerFieldContext.Consumer>
)

export default SelectDrawerMemberOption