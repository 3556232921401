import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const BankIcon = ({ size = 1, color = '#398D8D', width, height }) => {
  const defaultWidth = 20
  const defaultHeight = 15
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3334 6.00002V10C27.3334 10.3536 27.1929 10.6928 26.9429 10.9428C26.6928 11.1929 26.3537 11.3334 26.0001 11.3334H24.6667V20.912C25.4445 21.186 26.1184 21.694 26.5958 22.3662C27.0733 23.0385 27.331 23.8421 27.3334 24.6667V27.3334C27.3334 27.687 27.1929 28.0261 26.9429 28.2762C26.6928 28.5262 26.3537 28.6667 26.0001 28.6667H2.00008C1.64646 28.6667 1.30732 28.5262 1.05727 28.2762C0.807224 28.0261 0.666748 27.687 0.666748 27.3334V24.6667C0.669197 23.8421 0.926846 23.0385 1.40432 22.3662C1.88179 21.694 2.55567 21.186 3.33341 20.912V11.3334H2.00008C1.64646 11.3334 1.30732 11.1929 1.05727 10.9428C0.807224 10.6928 0.666748 10.3536 0.666748 10V6.00002C0.666759 5.72025 0.754863 5.44758 0.918563 5.2207C1.08226 4.99382 1.31325 4.82424 1.57875 4.73602L13.5787 0.736024C13.8523 0.645121 14.1479 0.645121 14.4214 0.736024L26.4214 4.73602C26.6869 4.82424 26.9179 4.99382 27.0816 5.2207C27.2453 5.44758 27.3334 5.72025 27.3334 6.00002ZM23.9445 22.0712L23.3334 21.856V22C23.5404 22.0002 23.7462 22.0245 23.9445 22.0712ZM3.33341 24.6667V26H24.6667V24.6667C24.6664 24.3132 24.5258 23.9742 24.2758 23.7243C24.0259 23.4743 23.6869 23.3337 23.3334 23.3334H4.66675C4.31323 23.3337 3.9743 23.4743 3.72433 23.7243C3.47436 23.9742 3.33377 24.3132 3.33341 24.6667ZM6.00008 20.6667H8.66675V11.3334H6.00008V20.6667ZM11.3334 20.6667H16.6667V11.3334H11.3334V20.6667ZM19.3334 20.6667H22.0001V11.3334H19.3334V20.6667ZM3.33341 8.66669H24.6667V6.96136L14.0001 3.40536L3.33341 6.96002V8.66669Z"
        fill={color}
      />
    </svg>
  )
}

export default BankIcon
