import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'

import { cleanNullProps } from '../../../utils/functions'
import FilesField from '../fields/FilesField'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'
import Flex from '../../Flex'

export type UploadProofOfPaymentFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
}

const schema = Yup.object().shape({
  cursor: Yup.string().required(),
  files: Yup.array().min(1).required(),
})

const UploadProofOfPaymentForm: React.FC<UploadProofOfPaymentFormProps> = ({
  data = {},
  submit,
  loading,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { files, cursor } = data
  const defaultInitialValues = {
    files: files || [],
    cursor,
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      css={styles.root}
      {...rest}
    >
      {(form) => (
        <Flex vertical gap="1rem">
          <Flex vertical>
            <Heading
              token="PLEASE_UPLOAD_PROOF_OF_PAYMENT"
              css={styles.pendingHeadline}
            />
            <Text token="MAX_FILE_SIZE_2MB" css={styles.fileTypesDescription} />
          </Flex>
          <FilesField
            name="files"
            accept=".pdf, .jpg, .png, .jpeg, .bmp, .tif"
            disabled={loading}
          />
          {!!form?.values?.files?.length && <FormButton token="SUBMIT" />}
        </Flex>
      )}
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  root: {
    width: '100%',
  },
  pendingHeadline: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '24px',
  },
  fileTypesDescription: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
})

export default UploadProofOfPaymentForm
