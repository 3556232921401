import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import { Props as BannerProps } from './Banner'
import Text from './typography/Text'
import InfoAlert from './InfoAlert'
import Link from './Link'
import withFeature from '../hocs/withFeature'

const AwaitingFundingRequestsNotification: React.FC<BannerProps> = () => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <InfoAlert
      label="FUNDING_REQUESTS"
      token="SOME_FUNDING_REQUESTS_ARE_STILL_AWAITING"
      actions={[
        <Link key="funding_request_link" to="/settings/funding-requests">
          <Text token="UPLOAD_NOW" css={styles.action} />
        </Link>,
      ]}
    />
  )
}

const computeStyles: TComputeStyles = ({ LINK }: TTheme) => ({
  root: {
    justifyContent: 'center',
  },
  action: {
    background: 'none',
    color: LINK,
    fontSize: 12,
    width: 'min-content',
    padding: 0,
  },
})

export default withFeature(AwaitingFundingRequestsNotification)
