import { gql } from '@apollo/client'

export * from './mutations'
export * from './subscriptions'

export const getCards = gql`
  query cards {
    cards {
      cardType
      id
      last4Digits
      status {
        changedBy
        statusCode
      }
      cardholder {
        email
        familyName
        gender
        givenName
        hasAcceptedTOS
        isEmailVerified
        id
        isMobilePhoneVerified
        lastTOSAcceptanceTimestamp
        lastTOSVersionAccepted
        locale
        middleNames
        mobilePhoneNumber
        principalAddress {
          addressLine1
          addressLine2
          country
          postalCode
          city
          region
        }
        schoolLocation
        schoolName
        dateOfBirth
        alternatePhoneNumber
        alternateAddress {
          addressLine2
          addressLine1
          city
          country
          postalCode
          region
        }
      }
    }
  }
`

export const getCardById = gql`
  query card($id: String) {
    cards(id: $id) {
      cardType
      cardholderId
      id
      last4Digits
    }
  }
`

export const cardPin = gql`
  query cardPin($id: ID!) {
    cardPin(id: $id) {
      pin
    }
  }
`

export const getUserProfile = gql`
  query getUserProfile {
    userProfile {
      alternatePhoneNumber
      dateOfBirth
      email
      familyName
      nationality
      studentId
      number
      gender
      givenName
      hasAcceptedTOS
      id
      isEmailVerified
      isIdentityVerified
      kycValidationStatus
      lastTOSAcceptanceTimestamp
      lastTOSVersionAccepted
      locale
      middleNames
      mobilePhoneNumber
      program
      principalAddress {
        addressLine1
        addressLine2
        city
        country
        postalCode
        region
      }
      alternateAddress {
        addressLine1
        city
        addressLine2
        country
        region
        postalCode
      }
    }
  }
`

export const subscriptionPlans = gql`
  query subscriptionPlans {
    subscriptionPlans {
      billingPeriod
      code
      features
      planKey
      price {
        billingPeriodCostInCents
        currency
        rebate
        setupFeeInCents
      }
    }
  }
`

export const subscription = gql`
  query subscription {
    subscription {
      id
      state
      subscriptionPlan {
        billingPeriod
        code
        features
        planKey
        price {
          billingPeriodCostInCents
          currency
          rebate
          setupFeeInCents
        }
      }
    }
  }
`

export const subscriptionBillingInfo = gql`
  query subscriptionBillingInfo {
    subscriptionBillingInfo {
      cardType
      nameOnCard
      last4Digits
      expirationMonth
      expirationYear
    }
  }
`

export const teamMembersById = gql`
  query teamMembers($id: ID!) {
    teamMembers(id: $id) {
      role
      settings {
        receivesEmail
        canOrderCards
      }
      userProfile {
        id
        givenName
        middleNames
        familyName
        nationality
        studentId
        number
        email
        mobilePhoneNumber
        alternatePhoneNumber
        program
        principalAddress {
          addressLine1
          addressLine2
          city
          region
          country
          postalCode
        }
        alternateAddress {
          addressLine1
          addressLine2
          city
          region
          country
          postalCode
        }
        dateOfBirth
        gender
        locale
        isEmailVerified
        hasAcceptedTOS
        lastTOSVersionAccepted
        lastTOSAcceptanceTimestamp
      }
      bankAccounts {
        bankAccountId
        type
        isBlockListEnabled
        isCashWithdrawalDisabled
        fundsAvailable {
          basicUnit
          fraction
          currencyKey
        }
        transactions(first: 20) {
          edges {
            node {
              description
              id
              mcc
              mccCategory
              status
              transactionDateTime
              transactionType
              billingAmount {
                basicUnit
                currencyKey
                fraction
              }
            }
            cursor
          }
        }
        categories {
          key
          fundsAvailable {
            basicUnit
            fraction
            currencyKey
          }
          categoryTransactions {
            edges {
              cursor
              node {
                id
                mccCategory
                status
                transactionDateTime
                billingAmount {
                  basicUnit
                  fraction
                  currencyKey
                }
                description
                mcc
                transactionType
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }
      }
    }
  }
`

export const teamMembers = gql`
  query teamMembers {
    teamMembers {
      role
      settings {
        receivesEmail
        canOrderCards
      }
      userProfile {
        id
        givenName
        middleNames
        familyName
        nationality
        studentId
        number
        email
        mobilePhoneNumber
        alternatePhoneNumber
        program
        principalAddress {
          addressLine1
          addressLine2
          city
          region
          country
          postalCode
        }
        alternateAddress {
          addressLine1
          addressLine2
          city
          region
          country
          postalCode
        }
        dateOfBirth
        gender
        locale
        isEmailVerified
        isIdentityVerified
        kycValidationStatus
        hasAcceptedTOS
        hasAcceptedInvitation
        lastTOSVersionAccepted
        lastTOSAcceptanceTimestamp
      }
      userDocuments {
        id
        type
        countryCode
        status
        expirationDate
        createdAt
      }
      bankAccounts {
        bankAccountId
        type
        isBlockListEnabled
        isCashWithdrawalDisabled
        fundsAvailable {
          basicUnit
          fraction
          currencyKey
        }
        transactions(first: 20) {
          edges {
            cursor
            node {
              mccCategory
              status
              transactionDateTime
              billingAmount {
                basicUnit
                fraction
                currencyKey
              }
              description
              mcc
              transactionType
            }
          }
        }
        categories {
          key
          fundsAvailable {
            basicUnit
            fraction
            currencyKey
          }
          categoryTransactions {
            edges {
              cursor
              node {
                id
                mccCategory
                status
                transactionDateTime
                billingAmount {
                  basicUnit
                  fraction
                  currencyKey
                }
                description
                mcc
                transactionType
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }
      }
    }
  }
`

export const openCardOrders = gql`
  query openCardOrders {
    openCardOrders {
      id
      userProfile {
        id
        givenName
        middleNames
        familyName
        email
        mobilePhoneNumber
      }
      orderedBy {
        id
        givenName
        middleNames
        familyName
        email
        mobilePhoneNumber
      }
      bankAccountType
      shippingMethod
      shippingAddress {
        addressLine1
        addressLine2
        region
        country
        postalCode
      }
      addOns
      status
      dateOrdered
      dateProcessed
      trackingInfo
    }
  }
`

export const invoices = gql`
  query invoices {
    invoices {
      balanceInCents
      cardType
      currency
      dateClosed
      dateCreation
      id
      last4Digits
      paidInCents
      state
      subtotalInCents
      taxInCents
      totalInCents
    }
  }
`

export const fundingSource = gql`
  query fundingSource($id: ID!) {
    fundingSource(id: $id) {
      account_holder_name
      account_number
      address_city
      address_country
      address_line1
      address_line2
      address_postal_code
      address_state
      avs_check_status
      bin
      brand
      card_holder_type
      card_product_name
      card_subtype_code
      country
      currency
      cvv_check_status
      expiry_month
      fast_funds_enabled
      expiry_year
      id
      funding_type
      institution_number
      issuer_country_code
      issuer_name
      last_four_digits
      name
      push_funds_enabled
      risk_level
      routing_number
      status {
        changedBy
        code
        statusCode
      }
      token
      transit_number
      type
      with3DSChallenge
    }
  }
`

export const fundingSources = gql`
  query fundingSources {
    fundingSources {
      account_holder_name
      account_number
      address_city
      address_country
      address_line1
      address_line2
      address_postal_code
      address_state
      avs_check_status
      bin
      brand
      card_holder_type
      card_product_name
      card_subtype_code
      country
      currency
      cvv_check_status
      expiry_month
      expiry_year
      fast_funds_enabled
      funding_type
      id
      issuer_country_code
      institution_number
      issuer_name
      last_four_digits
      name
      push_funds_enabled
      routing_number
      risk_level
      type
      transit_number
      token
      with3DSChallenge
      status {
        changedBy
        code
        statusCode
      }
    }
  }
`

export const fundsTransfer = gql`
  query fundsTransfer($id: ID!) {
    fundsTransfer(id: $id) {
      amountInCents
      completedAt
      createdAt
      currency
      fundingSourceId
      id
      status {
        changedBy
        statusCode
      }
    }
  }
`

export const fundsTransfers = gql`
  query fundsTransfers {
    fundsTransfers {
      amountInCents
      completedAt
      createdAt
      currency
      fundingSourceId
      id
      status {
        statusCode
        changedBy
      }
    }
  }
`

export const teamHoldingAccountFunds = gql`
  query teamHoldingAccount {
    teamHoldingAccount {
      fundsAvailable {
        basicUnit
        fraction
        currencyKey
      }
    }
  }
`

export const teamHoldingAccount = gql`
  query teamHoldingAccount {
    teamHoldingAccount {
      fundsAvailable {
        basicUnit
        fraction
        currencyKey
      }
      holdingAccountTransactions {
        edges {
          cursor
          node {
            transactionId
            amount {
              basicUnit
              fraction
              currencyKey
            }
            status
            updatedAt
            createdAt
            type
            createdBy {
              givenName
              familyName
            }
            fundingSource {
              account_holder_name
              last_four_digits
            }
            paymentProfile {
              id
              givenName
              familyName
              email
            }
            detail {
              paymentId
              settlementMethod
              fromAmount
              fromCurrency
              rate
              reference
              paymentLink
              transactionBankDetails {
                accountNumber
                bankAddress
                bankName
                beneficiaryName
                iban
                swift
              }
              dueDate
            }
          }
        }
      }
    }
  }
`

export const notifications = gql`
  query notifications {
    notifications {
      edges {
        cursor
        node {
          id
          userId
          notificationType
          hasBeenRead
          code
          timestamp
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      stats {
        totalCount
        unreadCount
      }
    }
  }
`

export const notification = gql`
  query notification($id: ID!) {
    notification(id: $id) {
      id
      userId
      notificationType
      hasBeenRead
      code
      timestamp
    }
  }
`

export const recurringAllowances = gql`
  query recurringAllowances {
    recurringAllowances {
      id
      repeatsEvery
      targetBankAccount
      endsAfter
      frequency
      nextScheduledAllowance
      amount {
        basicUnit
        currencyKey
        fraction
      }
      categories {
        categoryKey
        ammountAllocated {
          basicUnit
          currencyKey
          fraction
        }
      }
    }
  }
`

export const getRecurringAllowance = gql`
  query getRecurringAllowance($id: ID!) {
    recurringAllowance(id: $id) {
      id
      repeatsEvery
      targetBankAccount
      nextScheduledAllowance
      endsAfter
      frequency
      amount {
        basicUnit
        currencyKey
        fraction
      }
      categories {
        categoryKey
        ammountAllocated {
          basicUnit
          currencyKey
          fraction
        }
      }
    }
  }
`
export const mobileAppMetadata = gql`
  query getMetadata {
    metadata {
      parameters {
        key
        value
      }
    }
  }
`

export const findUserDocuments = gql`
  query findUserDocuments($fromUser: ID) {
    userDocuments(fromUser: $fromUser) {
      id
      type
      countryCode
      status
      expirationDate
      files {
        filename
        size
      }
      createdAt
    }
  }
`

export const getBerkeley3dsAuthentication = gql`
  query getBerkeley3dsAuthentication($id: ID!) {
    berkeley3dsAuthentication(id: $id) {
      id
      acs_trans_id
      acs_url
      authentication_status
      authentication_type
      authentication_value
      cavv
      cavv_algorithm
      chargeback_protection_available
      creq
      ds_trans_id
      eci
      error
      error_code
      error_detail
      sdk_trans_id
      xid
      status
    }
  }
`

export const findPickupLocations = gql`
  query findPickupLocations {
    pickupLocations {
      code
      name
      address
      city
      postalCode
      region
      country
    }
  }
`

export const findOpenCardPickups = gql`
  query findOpenCardPickups {
    openCardPickups {
      id
      bankAccountType
      status
      userProfile {
        id
        givenName
        familyName
        email
      }
      pickupLocation {
        name
        address
        city
        postalCode
        country
      }
      arrivalDate
      createdAt
      createdBy {
        id
        givenName
        familyName
      }
    }
  }
`

// nexpay and payment profile

export const findPaymentProfiles = gql`
  query findPaymentProfiles {
    paymentProfiles {
      id
      givenName
      familyName
      email
      phoneNumber
      number
      nationality
      dateOfBirth
      addressLine1
      addressLine2
      city
      region
      postalCode
      country
      taxNumber
      locale
      isIdentityVerified
      paymentProfileDocuments {
        id
        type
        status
      }
    }
  }
`

export const getPaymentProfile = gql`
  query getPaymentProfile($id: ID!) {
    paymentProfile(id: $id) {
      id
      givenName
      familyName
      email
      phoneNumber
      number
      nationality
      dateOfBirth
      addressLine1
      addressLine2
      city
      region
      postalCode
      country
      taxNumber
      locale
      isIdentityVerified
      paymentProfileDocuments {
        id
        type
        status
      }
    }
  }
`
export const findAvailableCountries = gql`
  query findAvailableCountries($provider: InternationalFundingProvider) {
    availableCountries(provider: $provider) {
      name
      countryCode
      providers
    }
  }
`
