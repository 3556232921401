import { useQuery } from '@apollo/client'
import { findPaymentProfiles } from '../queries'


const usePaymentProfiles = (cache?: boolean, cacheOnly?: boolean) => {
  const { data, loading, refetch, error } = useQuery(findPaymentProfiles, {
    fetchPolicy: cacheOnly
      ? 'cache-only'
      : cache
      ? 'cache-first'
      : 'network-only',
  })
  const count = data?.paymentProfiles?.length
  const pendingIdVerificationCount =
    count -
    (data?.paymentProfiles?.filter(
      ({ isIdentityVerified }) => isIdentityVerified
    )?.length || 0)
  return { data, pendingIdVerificationCount, loading, refetch, error }
}

export default usePaymentProfiles
