import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Flex from '../../../../components/Flex'
import ComponentCard from '../../../../components/cards/ComponentCard'
import Text from '../../../../components/typography/Text'
import { getAmount } from '../../../../utils/functions'

type CompletedPaymentCardProps = {
  fundingRequest: any
}

const CompletedPaymentCard: React.FC<CompletedPaymentCardProps> = ({
  fundingRequest,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <ComponentCard vertical gap="1rem">
      <Flex vertical>
        <Text css={styles.detailsLabel} token="PAYMENT_ID" />
        <Text css={styles.detailsValue}>
          {fundingRequest.node?.detail?.paymentId}
        </Text>
      </Flex>
      <Flex vertical>
        <Text css={styles.detailsLabel} token="AMOUNT" />
        <Text
          css={styles.detailsValue}
          currency={getAmount(fundingRequest?.node?.amount)}
        />
      </Flex>
      <Text css={styles.detailsValue} date={fundingRequest.node?.updatedAt} />
    </ComponentCard>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY }: TTheme) => ({
  detailsLabel: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
  detailsValue: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
})

export default CompletedPaymentCard
