import React from 'react'

import Pill, { PillProps } from './Pill'

const STATUSES = {
  Pending: 'warning-alt',
  Verified: 'primary-alt',
  Declined: 'warning',
}

const DocumentStatusPill: React.FC<PillProps> = ({ status, ...rest }) => (
  <Pill variant={STATUSES[status]} token={status ? status.toUpperCase() : undefined} {...rest} />
)

export default DocumentStatusPill
