import { teamHoldingAccount } from '../queries'
import { OperationVariables, QueryHookOptions, useQuery } from '@apollo/client'

const useTeamHoldingAccount = (cache?, cacheOnly?) => {
  const queryOptions: QueryHookOptions<any, OperationVariables> = {
    fetchPolicy: cacheOnly
      ? 'cache-only'
      : cache
      ? 'cache-first'
      : 'network-only',
    notifyOnNetworkStatusChange: true,
  }
  return useQuery(teamHoldingAccount, queryOptions)
}

export default useTeamHoldingAccount
