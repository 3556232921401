import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const AppleIcon = ({ size = 1, color = '#8A929C', width, height }) => {
  const defaultWidth = 64
  const defaultHeight = 64
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 16.933333 16.933333"
    >
      <path
        fill={color}
        d="M 5.4137623,0.26344989 C 5.2329339,0.26259996 5.2305465,0.38964327 5.2615933,0.76437401 5.363726,1.9971507 6.1036968,3.1700622 7.1110907,3.6958104 7.670157,3.9875822 8.4461647,4.1186038 8.516839,3.9332055 8.5354781,3.8843088 8.5362971,3.6715532 8.5186966,3.4603913 8.3997401,2.0320808 7.2276555,0.69132428 5.7845403,0.3328464 5.6150241,0.29073641 5.4959621,0.26387273 5.4137665,0.26344989 Z m 0.012328,3.94437581 c -0.069353,0.00127 -0.1388896,0.00495 -0.209984,0.010985 -1.0200295,0.086576 -1.9862971,0.5301827 -2.606968,1.1967522 -0.1810007,0.1943885 -0.327704,0.3714808 -0.326048,0.3935387 0.00167,0.02206 0.1495252,0.145326 0.3286662,0.273851 C 3.2822709,6.5640112 3.8241082,7.4160427 3.978671,8.2325831 4.0674,8.7013347 4.05708,9.4266602 3.955764,9.840882 3.7439415,10.706902 3.0956567,11.592897 2.3049068,12.097133 2.076217,12.242961 1.8890837,12.39515 1.8890837,12.435328 c 0,0.106514 0.3048308,0.861776 0.5246877,1.299971 0.4536691,0.904209 1.3329501,2.087736 1.840226,2.477065 0.5143262,0.394746 0.6185467,0.431091 1.2374332,0.431091 0.527895,0 0.6013515,-0.0153 1.3404082,-0.279781 0.752748,-0.269383 0.8037721,-0.279758 1.3683331,-0.277145 0.558813,0.0026 0.6270535,0.01679 1.4485106,0.300206 0.9294475,0.320684 1.3473595,0.385768 1.7070335,0.265834 0.375747,-0.125296 0.568768,-0.249329 0.956438,-0.614352 0.622464,-0.586104 1.4616,-1.775914 1.863241,-2.641993 0.290651,-0.626742 0.69439,-1.851973 0.847355,-2.571498 0.10475,-0.492724 0.125278,-0.762234 0.124463,-1.6303695 C 15.146369,8.249459 15.132838,8.1039672 14.999295,7.6050536 14.83947,7.0079131 14.514418,6.3029139 14.200591,5.8725687 13.464576,4.8632759 12.092201,4.1799988 10.966727,4.2625126 10.564231,4.2920223 10.097921,4.4225637 9.1304294,4.7766145 8.8280565,4.8872653 8.5095438,4.9777748 8.4225925,4.9777748 c -0.086953,0 -0.549827,-0.1381207 -1.0286504,-0.3070111 C 6.3919796,4.3173461 5.9115667,4.1994347 5.4260899,4.2078299 Z"
      />
    </svg>
  )
}

export default AppleIcon
