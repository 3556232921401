import gql from 'graphql-tag'

export const acceptTermsOfService = gql`
  mutation acceptTermsOfService {
    acceptTermsOfService(tosVersion: "") {
      userId
      lastTOSAcceptanceTimestamp
      hasAcceptedTOS
      lastTOSVersionAccepted
    }
  }
`

export const enableBlockList = gql`
  mutation enableBlockList($accountOwnerId: ID!, $bankAccountId: ID!) {
    enableBlockList(
      accountOwnerId: $accountOwnerId
      bankAccountId: $bankAccountId
    ) {
      bankAccountId
      isBlockListEnabled
      isCashWithdrawalDisabled
      type
    }
  }
`

export const disableBlockList = gql`
  mutation disableBlockList($accountOwnerId: ID!, $bankAccountId: ID!) {
    disableBlockList(
      accountOwnerId: $accountOwnerId
      bankAccountId: $bankAccountId
    ) {
      bankAccountId
      isBlockListEnabled
      isCashWithdrawalDisabled
      type
    }
  }
`

export const enableCashWithrawals = gql`
  mutation enableCashWithrawals($accountOwnerId: ID!, $bankAccountId: ID!) {
    enableCashWithrawals(
      accountOwnerId: $accountOwnerId
      bankAccountId: $bankAccountId
    ) {
      bankAccountId
      isBlockListEnabled
      isCashWithdrawalDisabled
      type
    }
  }
`

export const disableCashWithrawals = gql`
  mutation disableCashWithrawals($accountOwnerId: ID!, $bankAccountId: ID!) {
    disableCashWithrawals(
      accountOwnerId: $accountOwnerId
      bankAccountId: $bankAccountId
    ) {
      bankAccountId
      isBlockListEnabled
      isCashWithdrawalDisabled
      type
    }
  }
`

export const lockCard = gql`
  mutation lockCard($id: ID!) {
    lockCard(id: $id) {
      cardType
      id
      last4Digits
      status {
        changedBy
        statusCode
      }
    }
  }
`

export const unlockCard = gql`
  mutation unlockCard($id: ID!) {
    unlockCard(id: $id) {
      cardType
      id
      last4Digits
      status {
        changedBy
        statusCode
      }
    }
  }
`

export const registerCard = gql`
  mutation registerCard($activationCode: ID!, $forUser: ID) {
    registerCard(activationCode: $activationCode, forUser: $forUser) {
      cardType
      id
      last4Digits
      status {
        changedBy
        statusCode
      }
      cardholder {
        alternatePhoneNumber
        dateOfBirth
        email
        familyName
        givenName
        gender
        hasAcceptedTOS
        id
        isEmailVerified
        isIdentityVerified
        kycValidationStatus
        isMobilePhoneVerified
        lastTOSAcceptanceTimestamp
        lastTOSVersionAccepted
        locale
        middleNames
        mobilePhoneNumber
        principalAddress {
          addressLine1
          addressLine2
          city
          country
          postalCode
          region
        }
        schoolLocation
        schoolName
        alternateAddress {
          addressLine1
          addressLine2
          city
          country
          postalCode
          region
        }
      }
    }
  }
`

export const replaceCard = gql`
  mutation replaceCard($activationCode: ID!, $forUser: ID) {
    replaceCard(activationCode: $activationCode, forUser: $forUser) {
      cardType
      id
      last4Digits
      status {
        changedBy
        statusCode
      }
      cardholder {
        alternatePhoneNumber
        dateOfBirth
        email
        familyName
        givenName
        gender
        hasAcceptedTOS
        id
        isEmailVerified
        isIdentityVerified
        kycValidationStatus
        isMobilePhoneVerified
        lastTOSAcceptanceTimestamp
        lastTOSVersionAccepted
        locale
        middleNames
        mobilePhoneNumber
        principalAddress {
          addressLine1
          addressLine2
          city
          country
          postalCode
          region
        }
        schoolLocation
        schoolName
        alternateAddress {
          addressLine1
          addressLine2
          city
          country
          postalCode
          region
        }
      }
    }
  }
`

export const updateUserProfile = gql`
  mutation updateUserProfile(
    $id: ID
    $givenName: String
    $middleNames: String
    $number: String
    $nationality: String
    $studentId: String
    $familyName: String
    $email: AWSEmail
    $mobilePhoneNumber: AWSPhone
    $alternatePhoneNumber: AWSPhone
    $principalAddress: AddressInput
    $alternateAddress: AddressInput
    $dateOfBirth: AWSDate
    $gender: GenderSelection
    $locale: String
  ) {
    updateUserProfile(
      input: {
        alternateAddress: $alternateAddress
        alternatePhoneNumber: $alternatePhoneNumber
        dateOfBirth: $dateOfBirth
        email: $email
        familyName: $familyName
        number: $number
        studentId: $studentId
        nationality: $nationality
        gender: $gender
        givenName: $givenName
        id: $id
        locale: $locale
        middleNames: $middleNames
        mobilePhoneNumber: $mobilePhoneNumber
        principalAddress: $principalAddress
      }
    ) {
      alternateAddress {
        addressLine1
        addressLine2
        city
        country
        postalCode
        region
      }
      alternatePhoneNumber
      dateOfBirth
      email
      familyName
      nationality
      studentId
      number
      gender
      givenName
      hasAcceptedTOS
      id
      lastTOSAcceptanceTimestamp
      lastTOSVersionAccepted
      locale
      middleNames
      mobilePhoneNumber
      program
      principalAddress {
        addressLine1
        addressLine2
        city
        country
        postalCode
        region
      }
    }
  }
`

export const subscribe = gql`
  mutation subscribe($code: String!, $coupon: String, $currency: CurrencyKey) {
    subscribe(code: $code, coupon: $coupon, currency: $currency) {
      id
      subscriptionPlan {
        billingPeriod
        code
        features
        planKey
        price {
          billingPeriodCostInCents
          currency
          rebate
          setupFeeInCents
        }
      }
    }
  }
`

export const addSideKick = gql`
  mutation addSideKick(
    $givenName: String
    $familyName: String
    $nationality: String
    $studentId: String
    $number: String
    $email: AWSEmail
    $mobilePhoneNumber: AWSPhone
    $principalAddress: AddressInput
    $dateOfBirth: AWSDate
    $gender: GenderSelection
    $locale: String
    $receivesEmail: Boolean!
    $canOrderCards: Boolean!
  ) {
    addSideKick(
      input: {
        givenName: $givenName
        familyName: $familyName
        nationality: $nationality
        studentId: $studentId
        number: $number
        mobilePhoneNumber: $mobilePhoneNumber
        principalAddress: $principalAddress
        dateOfBirth: $dateOfBirth
        gender: $gender
        locale: $locale
        email: $email
      }
      receivesEmail: $receivesEmail
      canOrderCards: $canOrderCards
    ) {
      role
      settings {
        receivesEmail
        canOrderCards
      }
      userProfile {
        id
        givenName
        middleNames
        familyName
        nationality
        studentId
        number
        email
        mobilePhoneNumber
        alternatePhoneNumber
        isIdentityVerified
        kycValidationStatus
        program
        principalAddress {
          addressLine1
          addressLine2
          city
          region
          country
          postalCode
        }
        alternateAddress {
          addressLine1
          addressLine2
          city
          region
          country
          postalCode
        }
        dateOfBirth
        gender
        locale
        isEmailVerified
        hasAcceptedTOS
        hasAcceptedInvitation
        lastTOSVersionAccepted
        lastTOSAcceptanceTimestamp
      }
      userDocuments {
        id
        type
        countryCode
        status
        expirationDate
        createdAt
      }
      bankAccounts {
        bankAccountId
        type
        isBlockListEnabled
        isCashWithdrawalDisabled
        fundsAvailable {
          basicUnit
          fraction
          currencyKey
        }
        transactions(first: 20) {
          edges {
            cursor
            node {
              mccCategory
              status
              transactionDateTime
              billingAmount {
                basicUnit
                fraction
                currencyKey
              }
              description
              mcc
              transactionType
            }
          }
        }
        categories {
          key
          fundsAvailable {
            basicUnit
            fraction
            currencyKey
          }
          categoryTransactions {
            edges {
              cursor
              node {
                id
                mccCategory
                status
                transactionDateTime
                billingAmount {
                  basicUnit
                  fraction
                  currencyKey
                }
                description
                mcc
                transactionType
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }
      }
    }
  }
`

export const addSubscriptionBillingInfo = gql`
  mutation addSubscriptionBillingInfo($tokenId: String!) {
    addSubscriptionBillingInfo(tokenId: $tokenId) {
      cardType
      nameOnCard
      last4Digits
      expirationMonth
      expirationYear
    }
  }
`

export const addBankAccount = gql`
  mutation addBankAccount($userId: ID!, $accountType: BankAccountType!) {
    addBankAccount(userId: $userId, accountType: $accountType) {
      bankAccountId
      type
      isBlockListEnabled
      isCashWithdrawalDisabled
      fundsAvailable {
        basicUnit
        fraction
        currencyKey
      }
      transactions(first: 20) {
        edges {
          cursor
          node {
            mccCategory
            status
            transactionDateTime
            billingAmount {
              basicUnit
              fraction
              currencyKey
            }
            description
            mcc
            transactionType
          }
        }
      }
      categories {
        key
        fundsAvailable {
          basicUnit
          fraction
          currencyKey
        }
        categoryTransactions {
          edges {
            cursor
            node {
              id
              mccCategory
              status
              transactionDateTime
              billingAmount {
                basicUnit
                fraction
                currencyKey
              }
              description
              mcc
              transactionType
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`

export const orderCard = gql`
  mutation orderCard(
    $forUser: ID!
    $bankAccountType: BankAccountType!
    $shippingMethod: ShippingMethod!
    $shippingAddress: AddressInput!
    $addOns: [CardOrderAddOn!]
    $arrivalDate: String
  ) {
    orderCard(
      input: {
        forUser: $forUser
        bankAccountType: $bankAccountType
        shippingMethod: $shippingMethod
        shippingAddress: $shippingAddress
        addOns: $addOns
        arrivalDate: $arrivalDate
      }
    ) {
      id
      userProfile {
        id
        givenName
        middleNames
        familyName
        email
        mobilePhoneNumber
      }
      orderedBy {
        id
        givenName
        middleNames
        familyName
        email
        mobilePhoneNumber
      }
      bankAccountType
      shippingMethod
      shippingAddress {
        addressLine1
        addressLine2
        region
        country
        postalCode
      }
      addOns
      status
    }
  }
`

export const unsubscribe = gql`
  mutation unsubscribe {
    unsubscribe {
      id
      state
      subscriptionPlan {
        billingPeriod
        code
        features
        planKey
        price {
          billingPeriodCostInCents
          currency
          rebate
          setupFeeInCents
        }
      }
    }
  }
`

export const storeVerificationTransaction = gql`
  mutation storeVerificationTransaction($transactionId: ID!) {
    storeVerificationTransaction(transactionId: $transactionId) {
      id
      status
    }
  }
`

export const updateSubscription = gql`
  mutation updateSubscription($code: String!) {
    updateSubscription(code: $code) {
      id
      state
      subscriptionPlan {
        billingPeriod
        code
        features
        planKey
        price {
          billingPeriodCostInCents
          currency
          rebate
          setupFeeInCents
        }
      }
    }
  }
`

export const updateSideKickSettings = gql`
  mutation updateSideKickSettings(
    $canOrderCards: Boolean
    $receivesEmail: Boolean
    $userId: ID!
  ) {
    updateSideKickSettings(
      input: {
        canOrderCards: $canOrderCards
        receivesEmail: $receivesEmail
        userId: $userId
      }
    ) {
      role
      settings {
        canOrderCards
        receivesEmail
      }
      userProfile {
        alternatePhoneNumber
        dateOfBirth
        email
        familyName
        gender
        givenName
        hasAcceptedTOS
        id
        isEmailVerified
        isIdentityVerified
        kycValidationStatus
        isMobilePhoneVerified
        lastTOSAcceptanceTimestamp
        lastTOSVersionAccepted
        locale
        mobilePhoneNumber
        middleNames
        schoolLocation
        schoolName
        program
        principalAddress {
          region
          postalCode
          country
          city
          addressLine2
          addressLine1
        }
        alternateAddress {
          addressLine1
          addressLine2
          city
          country
          postalCode
          region
        }
      }
    }
  }
`

export const createBerkeleyFundingSource = gql`
  mutation createBerkeleyFundingSource($token: ID!) {
    createBerkeleyFundingSource(token: $token) {
      account_holder_name
      account_number
      address_city
      address_country
      address_line1
      address_line2
      address_postal_code
      address_state
      avs_check_status
      bin
      brand
      card_holder_type
      token
      transit_number
      type
      routing_number
      risk_level
      status {
        changedBy
        code
        statusCode
      }
      push_funds_enabled
      name
      last_four_digits
      issuer_name
      issuer_country_code
      institution_number
      id
      funding_type
      fast_funds_enabled
      expiry_year
      expiry_month
      cvv_check_status
      currency
      country
      card_subtype_code
      card_product_name
      with3DSChallenge
    }
  }
`

export const createBerkeleyFundsTransfer = gql`
  mutation createBerkeleyFundsTransfer(
    $fundingSourceId: ID!
    $amountInCents: Int!
    $currency: String
    $berkeley3dsAuthenticationId: ID
  ) {
    createBerkeleyFundsTransfer(
      fundingSourceId: $fundingSourceId
      amountInCents: $amountInCents
      currency: $currency
      berkeley3dsAuthenticationId: $berkeley3dsAuthenticationId
    ) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
      fundingSource {
        id
        status {
          statusCode
          code
          changedBy
        }
        type
        name
        last_four_digits
        expiry_year
        expiry_month
        currency
        brand
      }
    }
  }
`

export const allocateFunds = gql`
  mutation allocateFunds($totalAmount: CurrencyInput!, $targetAccount: ID!) {
    allocateFundsToSideKick(
      totalAmount: $totalAmount
      targetAccount: $targetAccount
    ) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
    }
  }
`

export const allocateFundsToSideKickCategories = gql`
  mutation allocateFundsToSideKickCategories(
    $totalAmount: CurrencyInput!
    $categoryAllocations: [CategoryAllocationsInput!]!
    $targetAccount: ID!
  ) {
    allocateFundsToSideKickCategories(
      totalAmount: $totalAmount
      categoryAllocations: $categoryAllocations
      targetAccount: $targetAccount
    ) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
    }
  }
`

export const moveFunds = gql`
  mutation moveFunds(
    $sourceAccount: ID!
    $targetAccount: ID!
    $amount: CurrencyInput!
  ) {
    moveFunds(
      sourceAccount: $sourceAccount
      targetAccount: $targetAccount
      amount: $amount
    ) {
      id
    }
  }
`

export const reallocateFunds = gql`
  mutation reallocateFunds(
    $targetAccount: ID!
    $sourceCategoryKey: String!
    $targetCategoryKey: String!
    $amount: CurrencyInput!
  ) {
    reallocateFunds(
      targetAccount: $targetAccount
      sourceCategoryKey: $sourceCategoryKey
      targetCategoryKey: $targetCategoryKey
      amount: $amount
    ) {
      bankAccountId
      reallocationId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      sourceCategoryKey
      targetCategoryKey
      userProfile {
        givenName
        familyName
      }
    }
  }
`

export const resendSideKickInvitation = gql`
  mutation resendSideKickInvitation($id: ID!) {
    resendSideKickInvitation(id: $id)
  }
`

export const cancelCardOrder = gql`
  mutation cancelCardOrder($cardOrderId: ID!) {
    cancelCardOrder(cardOrderId: $cardOrderId) {
      id
      userProfile {
        id
        givenName
        middleNames
        familyName
        email
        mobilePhoneNumber
      }
      orderedBy {
        id
        givenName
        middleNames
        familyName
        email
        mobilePhoneNumber
      }
      bankAccountType
      shippingMethod
      shippingAddress {
        addressLine1
        addressLine2
        region
        country
        postalCode
      }
      addOns
      status
    }
  }
`

export const createRecurringAllowance = gql`
  mutation createRecurringAllowance(
    $targetBankAccount: String!
    $frequency: AllowanceFrequency!
    $repeatsEvery: Int!
    $amount: CurrencyInput!
    $endsAfter: String
    $categories: [CategoryAllocationsInput!]
  ) {
    createRecurringAllowance(
      input: {
        targetBankAccount: $targetBankAccount
        frequency: $frequency
        repeatsEvery: $repeatsEvery
        endsAfter: $endsAfter
        amount: $amount
        categories: $categories
      }
    ) {
      id
      targetBankAccount
      frequency
      repeatsEvery
      nextScheduledAllowance
      endsAfter
      amount {
        basicUnit
        currencyKey
        fraction
      }
      categories {
        ammountAllocated {
          basicUnit
          currencyKey
          fraction
        }
        categoryKey
      }
    }
  }
`

export const updateRecurringAllowance = gql`
  mutation updateRecurringAllowance(
    $id: ID!
    $targetBankAccount: String!
    $frequency: AllowanceFrequency!
    $repeatsEvery: Int!
    $amount: CurrencyInput!
    $endsAfter: String
    $categories: [CategoryAllocationsInput!]
  ) {
    updateRecurringAllowance(
      id: $id
      input: {
        targetBankAccount: $targetBankAccount
        frequency: $frequency
        repeatsEvery: $repeatsEvery
        endsAfter: $endsAfter
        amount: $amount
        categories: $categories
      }
    ) {
      id
      targetBankAccount
      frequency
      repeatsEvery
      nextScheduledAllowance
      endsAfter
      amount {
        basicUnit
        currencyKey
        fraction
      }
      categories {
        ammountAllocated {
          basicUnit
          currencyKey
          fraction
        }
        categoryKey
      }
    }
  }
`

export const deleteRecurringAllowance = gql`
  mutation deleteRecurringAllowance($id: ID!) {
    deleteRecurringAllowance(id: $id) {
      id
      status
    }
  }
`

export const generateOneTimeLoginToken = gql`
  mutation generateOneTimeLoginToken {
    generateOneTimeLoginToken {
      token
    }
  }
`

export const initializeUserDocumentUpload = gql`
  mutation initializeUserDocumentUpload($input: CreateUserDocumentInput!) {
    initializeUserDocumentUpload(input: $input) {
      id
      type
      countryCode
      files {
        filename
        url
        fields {
          key
          value
        }
      }
    }
  }
`

export const deleteUserDocument = gql`
  mutation deleteUserDocument($id: ID!) {
    deleteUserDocument(id: $id) {
      id
      status
    }
  }
`

export const createBerkeley3dsAuthentication = gql`
  mutation createBerkeley3dsAuthentication(
    $fundingSourceId: ID!
    $amountInCents: Int!
    $currency: String
  ) {
    createBerkeley3dsAuthentication(
      fundingSourceId: $fundingSourceId
      amountInCents: $amountInCents
      currency: $currency
    ) {
      id
      method_url
      three_ds_method_data
      chargeback_protection_available
      correlation_id
      protocol_version
      status
    }
  }
`

export const pickupCard = gql`
  mutation pickupCard(
    $forUser: ID!
    $bankAccountType: BankAccountType!
    $pickupLocation: String!
    $arrivalDate: String!
  ) {
    pickupCard(
      input: {
        forUser: $forUser
        bankAccountType: $bankAccountType
        pickupLocation: $pickupLocation
        arrivalDate: $arrivalDate
      }
    ) {
      id
      bankAccountType
      status
      userProfile {
        id
        givenName
        familyName
        email
      }
      pickupLocation {
        name
        address
        city
        postalCode
        country
      }
      arrivalDate
      createdAt
      createdBy {
        id
        givenName
        familyName
      }
    }
  }
`

export const initializeProofOfPaymentUpload = gql`
  mutation initializeProofOfPaymentUpload($input: CreateProofOfPaymentInput!) {
    initializeProofOfPaymentUpload(input: $input) {
      id
      files {
        filename
        url
        fields {
          key
          value
        }
      }
    }
  }
`

export const addPaymentProfile = gql`
  mutation addPaymentProfile($input: PaymentProfileInput!) {
    addPaymentProfile(input: $input) {
      id
      givenName
      familyName
      nationality
      number
      email
      phoneNumber
      dateOfBirth
      addressLine1
      addressLine2
      city
      region
      postalCode
      country
      taxNumber
      locale
      isIdentityVerified
    }
  }
`
export const initializePaymentProfileDocumentUpload = gql`
  mutation initializePaymentProfileDocumentUpload(
    $input: CreatePaymentProfileDocumentInput!
  ) {
    initializePaymentProfileDocumentUpload(input: $input) {
      id
      type
      countryCode
      files {
        filename
        url
        fields {
          key
          value
        }
      }
    }
  }
`

export const requestNexPayQuote = gql`
  mutation requestNexPayQuote(
    $paymentProfileId: ID!
    $countryCode: String!
    $amountInCents: Int!
  ) {
    requestNexPayQuote(
      paymentProfileId: $paymentProfileId
      countryCode: $countryCode
      amountInCents: $amountInCents
    ) {
      id
      countryCode
      variants {
        id
        fromCurrency
        fromAmount
        rate
        settlementMethod
        type
      }
      amount {
        basicUnit
        currencyKey
        fraction
      }
    }
  }
`

export const requestTransferMateQuote = gql`
  mutation requestTransferMateQuote(
    $paymentProfileId: ID!
    $countryCode: String!
    $amountInCents: Int!
  ) {
    requestTransferMateQuote(
      paymentProfileId: $paymentProfileId
      countryCode: $countryCode
      amountInCents: $amountInCents
    ) {
      id
      countryCode
      variants {
        id
        fromCurrency
        fromAmount
        rate
        settlementMethod
        type
      }
      amount {
        basicUnit
        currencyKey
        fraction
      }
    }
  }
`

export const createNexPayFundsTransfer = gql`
  mutation createNexPayFundsTransfer(
    $paymentProfileId: ID!
    $forUser: ID
    $amountInCents: Int!
    $currency: String
    $input: InternationalTrasferInput!
  ) {
    createNexPayFundsTransfer(
      paymentProfileId: $paymentProfileId
      forUser: $forUser
      amountInCents: $amountInCents
      currency: $currency
      input: $input
    ) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
      paymentProfile {
        id
        givenName
        familyName
      }
      detail {
        paymentId
        settlementMethod
        fromAmount
        fromCurrency
        rate
        reference
        paymentLink
        transactionBankDetails {
          accountNumber
          bankAddress
          bankName
          beneficiaryName
          iban
          swift
        }
        dueDate
      }
    }
  }
`

export const createTransferMateFundsTransfer = gql`
  mutation createTransferMateFundsTransfer(
    $paymentProfileId: ID!
    $forUser: ID
    $amountInCents: Int!
    $currency: String
    $input: InternationalTrasferInput!
  ) {
    createTransferMateFundsTransfer(
      paymentProfileId: $paymentProfileId
      forUser: $forUser
      amountInCents: $amountInCents
      currency: $currency
      input: $input
    ) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
      paymentProfile {
        id
        givenName
        familyName
      }
      detail {
        paymentId
        settlementMethod
        fromAmount
        fromCurrency
        rate
        reference
        paymentLink
        transactionBankDetails {
          accountNumber
          bankAddress
          bankName
          beneficiaryName
          iban
          swift
        }
        dueDate
      }
    }
  }
`

export const cancelNexPayFundsTransfer = gql`
  mutation cancelNexPayFundsTransfer($cursor: String!) {
    cancelNexPayFundsTransfer(cursor: $cursor) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
      paymentProfile {
        id
        givenName
        familyName
      }
      detail {
        paymentId
        settlementMethod
        fromAmount
        fromCurrency
        rate
        reference
        paymentLink
        transactionBankDetails {
          accountNumber
          bankAddress
          bankName
          beneficiaryName
          iban
          swift
        }
        dueDate
      }
    }
  }
`
export const cancelTransferMateFundsTransfer = gql`
  mutation cancelTransferMateFundsTransfer($cursor: String!) {
    cancelTransferMateFundsTransfer(cursor: $cursor) {
      transactionId
      amount {
        basicUnit
        fraction
        currencyKey
      }
      status
      updatedAt
      createdAt
      type
      createdBy {
        givenName
        familyName
      }
      paymentProfile {
        id
        givenName
        familyName
      }
      detail {
        paymentId
        settlementMethod
        fromAmount
        fromCurrency
        rate
        reference
        paymentLink
        transactionBankDetails {
          accountNumber
          bankAddress
          bankName
          beneficiaryName
          iban
          swift
        }
        dueDate
      }
    }
  }
`
