import React from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import CardLayoutLink from '../../components/layout/CardLayoutLink'
import Button from '../../components/Button'
import CardList from '../../components/cards/CardList'
import ListItemLink from '../../components/lists/ListItemLink'
import UserCard from '../../components/cards/UserCard'
import Text from '../../components/typography/Text'

import { getUserProfile } from '../../queries'

import { setDoesManuallyLogout } from '../../mobile/biometric/biometric'
import NotificationsListItemLink from '../../components/lists/NotificationsListItemLink'
import useFundingRequests from '../../hooks/useFundingRequests'
import usePaymentProfiles from '../../hooks/usePaymentProfiles'

const SettingsScene = ({ ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const dispatch = useDispatch()
  const [logginOut, setLogginOut] = React.useState(false)

  const { data, loading, error } = useQuery(getUserProfile)

  const handleSignOut = async () => {
    setLogginOut(true)
    await dispatch.auth.signOut()
    setDoesManuallyLogout()
    setLogginOut(false)
  }

  const { data: fundingRequests } = useFundingRequests(false, true)

  const { pendingIdVerificationCount } = usePaymentProfiles(false, true)

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      <UserCard
        user={data?.userProfile}
        subtitle={<Text token="USER_PROFILE" />}
        to="/settings/profile"
        color="RED_FROLI"
      />

      <CardList>
        <ListItemLink
          label={<Text token="MANAGE_MY_TEAM" />}
          to="/settings/team"
          feature="settings.manageMyTeam"
        />

        <ListItemLink
          label={<Text token="MANAGE_MY_SUBSCRIPTION" />}
          to="/settings/subscriptions"
          feature="settings.manageMySubscription"
        />

        <NotificationsListItemLink
          label={<Text token="FUNDING_REQUESTS" />}
          to="/settings/funding-requests"
          feature="settings.fundingRequests"
          notificationsCount={fundingRequests.length}
        />

        <NotificationsListItemLink
          label={<Text token="PAYMENT_PROFILES" />}
          to="/settings/payment-profiles"
          feature="settings.paymentProfiles"
          notificationsCount={pendingIdVerificationCount}
        />

        <ListItemLink
          label={<Text token="MANAGE_PERMISSIONS" />}
          to="/settings/permissions"
          feature="settings.permissions"
        />

        <ListItemLink
          label={<Text token="NOTIFICATION_SETTINGS" />}
          to="/settings/notifications"
          feature="settings.notificationSettings"
        />
        <ListItemLink
          label={<Text token="CONTACT_US" />}
          to="/settings/contact-us?from=/settings"
          feature="settings.contactUs"
        />
        <ListItemLink
          external
          label={<Text token="FAQS" />}
          to="https://herofinancials.zendesk.com/hc/en-ca"
        />
        <ListItemLink
          label={<Text token="TERMS_OF_SERVICE" />}
          to="/settings/tos"
          feature="settings.tos"
        />
      </CardList>

      <Button
        loading={logginOut}
        token="LOG_OUT"
        button
        onClick={handleSignOut}
        icon="user"
        iconColor={theme.TEXT_SECONDARY}
        size={1}
        css={styles.button}
      />
    </CardLayoutLink>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_SECONDARY }: TTheme) => ({
  button: {
    color: TEXT_SECONDARY,
  },
})

export default SettingsScene
