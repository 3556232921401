import React from 'react'

import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import NexPayPaymentMethod from '../../../components/form/forms/NexPayPaymentMethod'
import { useMutation, useQuery } from '@apollo/client'
import {
  requestNexPayQuote,
  requestTransferMateQuote,
  findAvailableCountries,
} from '../../../queries'
import FlowContext from '../../../context/FlowContext'
import { calculateAmountInCents } from '../../../utils/funds'

const NexPayFundAccountPaymentMethodStep = () => {
  const [quotes, setQuotes] = React.useState({})
  const { currentData } = React.useContext(FlowContext)
  const { data: countries, loading: loadingCountries } = useQuery(
    findAvailableCountries
  )

  const [calculateTransferMateQuote, { loading: transferMateLoading }] =
    useMutation(requestTransferMateQuote)

  const [calculateNexPayQuote, { loading: nexPayLoading }] =
    useMutation(requestNexPayQuote)

  React.useEffect(() => {
    if (countries && countries.availableCountries && !loadingCountries) {
      const response = async () => {
        const { data } = currentData

        const country = countries.availableCountries.find(
          ({ name }) => name === data.country
        )

        const variables = {
          paymentProfileId: data.paymentProfile.id,
          amountInCents: calculateAmountInCents(data.amount),
          countryCode: country.countryCode,
        }

        let variants = quotes.variants || []

        if (country.providers.find((val) => val === 'NexPay')) {
          const { data: nexPayData } = await calculateNexPayQuote({
            variables,
          })

          const nexQuotes = nexPayData.requestNexPayQuote
          variants = variants.concat(
            nexQuotes.variants.map((quote) =>
              Object.assign(quote, {
                provider: 'NexPay',
                quoteId: nexQuotes.id,
              })
            )
          )

          setQuotes({ ...quotes, ...nexQuotes, variants })
        }

        if (country.providers.find((val) => val === 'TransferMate')) {
          const { data: transferMateData } = await calculateTransferMateQuote({
            variables,
          })

          const transferQuote = transferMateData.requestTransferMateQuote
          variants = variants.concat(
            transferQuote.variants.map((quote) =>
              Object.assign(quote, {
                provider: 'TransferMate',
                quoteId: transferQuote.id,
              })
            )
          )

          setQuotes({ ...quotes, ...transferQuote, variants })
        }
      }
      response()
    }
  }, [countries, loadingCountries])

  const loading = nexPayLoading || loadingCountries || transferMateLoading

  return (
    <FlowStepLayout loading={loading}>
      <NexPayPaymentMethod loading={loading} quotes={quotes} />
    </FlowStepLayout>
  )
}

export default NexPayFundAccountPaymentMethodStep
