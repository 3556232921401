import React from 'react'
import { useMutation } from '@apollo/client'
import useUploadDocuments from '../../../hooks/useUploadDocuments'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import NexPayPaymentProfileDocumentForm from '../../../components/form/forms/NexPayPaymentProfileDocumentForm'
import {
  initializePaymentProfileDocumentUpload,
  getPaymentProfile,
} from '../../../queries'
import countries from '../../../utils/countries'
import { useNavigate } from 'react-router-dom'
import NexPayPaymentProfileForm from '../../../components/form/forms/NexPayPaymentProfileForm'
import DocumentsList from '../../../components/lists/PaymentProfileDocumentsList'

const SettingsPaymentProfilesUploadDocumentsScene: React.FC = ({ ...rest }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: paymentProfileData, loading: paymentProfileLoading } = useQuery(
    getPaymentProfile,
    { variables: { id } }
  )

  const [filesToUpload, setFilesToUpload] = React.useState()
  const [generalLoading, setGeneralLoading] = React.useState<Boolean>(false)

  const [uploadFiles, { uploadFileLoading }] = useUploadDocuments()

  const [mutateInit, { loading: initLoading, data: initData }] = useMutation(
    initializePaymentProfileDocumentUpload
  )

  React.useEffect(() => {
    ;(async () => {
      if (initData && filesToUpload) {
        setGeneralLoading(true)

        const files = initData?.initializePaymentProfileDocumentUpload?.files

        const modelFiles = files?.map((file) => {
          const fileToUpload = filesToUpload.find(
            (fileToUpload) => fileToUpload.model.name === file.filename
          )
          return { ...file, model: fileToUpload.model }
        })

        try {
          await uploadFiles(modelFiles)
          setTimeout(async () => {
            setGeneralLoading(false)
            navigate({
              pathname: '/settings/payment-profiles',
              search: '?refetch=true',
            })
          }, 1000)
        } catch (err) {
          setGeneralLoading(false)
        }
      }
    })()
  }, [initData])

  const loading =
    uploadFileLoading || initLoading || generalLoading || paymentProfileLoading

  const handleNewPaymentProfile = async (docValues) => {
    setFilesToUpload(docValues.files)

    const variables = {
      input: {
        paymentProfileId: id,
        type: docValues.type.value,
        countryCode: countries.find(
          (countryItem) =>
            paymentProfileData?.paymentProfile?.country === countryItem.country
        )?.abbreviation,
        files: docValues.files.map((file) => file.model.name),
      },
    }

    await mutateInit({ variables })
  }

  const hasDocuments =
    paymentProfileData?.paymentProfile?.paymentProfileDocuments?.length > 0
  return (
    <CardLayoutLink loading={loading} {...rest}>
      {!loading && (
        <>
          <NexPayPaymentProfileForm
            initialValues={paymentProfileData?.paymentProfile}
            hideFooter
            disabled
          />
          {hasDocuments && (
            <DocumentsList
              list={paymentProfileData?.paymentProfile?.paymentProfileDocuments}
            />
          )}
        </>
      )}
      {!paymentProfileData?.paymentProfile?.isIdentityVerified && (
        <NexPayPaymentProfileDocumentForm
          submitToken="UPLOAD"
          loading={loading}
          onSubmit={handleNewPaymentProfile}
        />
      )}
    </CardLayoutLink>
  )
}

export default SettingsPaymentProfilesUploadDocumentsScene
