import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'

import ChooseProductForm from '../../../components/form/forms/ChooseProductForm'
import useIsInternationalUser from '../../../hooks/useIsInternationalUser'
import Text from '../../../components/typography/Text'

import FlowContext from '../../../context/FlowContext'
import useAddBankAccount from '../../../hooks/useAddBankAccount'
import useCurrentUser from '../../../hooks/useCurrentUser'

const OrderCardStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const { refetch, loading: userLoading } = useCurrentUser()
  const isInternational = useIsInternationalUser(true)

  const [addBankAccount, { loading: addBankAccountLoading }] =
    useAddBankAccount(currentData.data.owner)
  const handleSubmit = async (formData) => {
    await addBankAccount({
      variables: {
        userId: formData.owner,
        accountType: 'SideKickCardAccount',
      },
    })
    await refetch()
    await setFlowData(formData)
    next()
  }

  const loading = addBankAccountLoading || userLoading

  return (
    <FlowStepLayout>
      <Text token="FOLLOWING_PRODUCTS_AVAILABLE" />
      <ChooseProductForm
        isInternational={isInternational}
        submit={handleSubmit}
        loading={loading}
        data={{ ...currentData?.data }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default OrderCardStep
