import React from 'react'
import { TComputeStyles, useTheme } from '@emotion/react'

import Grid, { GridProps } from '../Grid'

import LayoutContext from '../../context/LayoutContext'
import { TInsets, getSafeArea } from '../../mobile/safearea/safearea-config'
import ApplicationContainer from '../layout/ApplicationContainer'
import Portal from '../Portal'

type FormFooterProps = {
  noBackground?: boolean
  hideonLoading?: boolean
} & GridProps

const FormFooter: React.FC<FormFooterProps> = ({
  children,
  noBackground,
  ...rest
}) => {
  const { setUseFooter } = React.useContext(LayoutContext)
  const [safeArea, setSafeArea] = React.useState<TInsets>({})

  const { theme } = useTheme()

  const styles = React.useMemo(
    () =>
      computeStyles({
        SAFEBOTTOM: safeArea?.bottom || 0,
        BACKGROUND: !noBackground ? theme.BACKGROUND : 'transparent',
      }),
    [safeArea, theme, noBackground]
  )

  setUseFooter(true)

  React.useEffect(() => {
    ;(async () => {
      const { insets } = await getSafeArea()
      setSafeArea(insets)
    })()
    return () => {
      setUseFooter(false)
    }
  }, [])

  return (
    <Portal type="default">
      <div css={styles.root}>
        <ApplicationContainer>
          <Grid gap={12} padding={12} horizontal {...rest}>
            {children}
          </Grid>
        </ApplicationContainer>
      </div>
    </Portal>
  )
}

const computeStyles: TComputeStyles = ({ SAFEBOTTOM, BACKGROUND }) => ({
  root: {
    background: BACKGROUND,
    bottom: 0,
    paddingBottom: SAFEBOTTOM,
    position: 'fixed',
    width: '100vw',
  },
})

export default FormFooter
