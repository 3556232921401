import React from 'react'

import Form from '../Form'
import * as Yup from 'yup'
import Button from '../../Button'
import FormButton from '../Button'
import FormFooter from '../FormFooter'
import SidekickOption from '../../lists/items/SidekicksOption'
import FlowContext from '../../../context/FlowContext'
import VerifyIdBanner from '../../VerifyIdBanner'
import Detail from '../../typography/Detail'
import NationalityNumberBanner from '../../NationalityNumberBanner'
import { DOCUMENT_TYPES } from '../../../utils/docs'
import { hasBankAccountsValidation, isHero } from '../../../hooks/useTeamMembers'
import { isEmpty } from 'lodash'

const fieldReceiverName = 'receiver'

const CheckIdMissing = ({ userProfile, userDocuments }) => {
  const letter = userDocuments?.find(
    ({ type }) => type === DOCUMENT_TYPES.letter
  )
  if (!letter) {
    return <VerifyIdBanner dismissable user={userProfile} />
  }
  return null
}

const schema = Yup.object({
  [fieldReceiverName]: Yup.mixed().required(),
})

type NexPayReceivePaymentFormProps = {
  teamMembers: Array<any>
  hideFooter?: boolean
  selectedCountryAbbreviation: string
}

const NexPayReceivePaymentForm: React.FC<NexPayReceivePaymentFormProps> = ({
  teamMembers,
  hideFooter,
  selectedCountryAbbreviation,
  ...rest
}) => {
  const formRef = React.useRef(null)
  const { next, prev, currentData, setFlowDataAttribute } =
    React.useContext(FlowContext)

  const handleOnChange = async (attribute, value) => {
    formRef?.current?.setFieldValue(attribute, value)
  }

  const handleSubmit = async () => {
    const form = formRef?.current
    if (formRef?.current.isValid) {
      await setFlowDataAttribute(
        fieldReceiverName,
        form.values?.[fieldReceiverName]
      )
      next()
    }
  }

  const handleNextBtn = async () => {
    await formRef?.current?.submitForm()
  }

  const paymentProfile = isEmpty( currentData?.data?.paymentProfile )
    ? currentData?.data?.newPaymentProfile
    : currentData?.data?.paymentProfile;

  return (
    <Form
      validationSchema={schema}
      initialValues={{ receiver: currentData?.data?.[fieldReceiverName] }}
      innerRef={formRef}
      onSubmit={handleSubmit}
      {...rest}
    >   
      {(form) => {
        return (
          <>
            {teamMembers &&
              teamMembers.map((member, index) => {

                const hasBankAccounts = hasBankAccountsValidation(member)

                if( !hasBankAccounts && isHero( member.role ) ) return <></>;

                // eslint-disable-next-line eqeqeq
                const userProfile = member?.userProfile?.email == paymentProfile?.email //
                  ? { ...member?.userProfile, ...paymentProfile }
                  : member?.userProfile;

                const isIdentityVerified = userProfile?.isIdentityVerified
                const hasLetter          = member?.userDocuments?.find( ({ type }) => type === DOCUMENT_TYPES.letter )?.status === 'Verified'
                const countryMatch       = userProfile?.nationality === selectedCountryAbbreviation
                const isValidNationality = userProfile?.nationality && userProfile?.number

                const disabled =
                  !isIdentityVerified ||
                  !hasLetter ||
                  !countryMatch ||
                  !isValidNationality ||
                  !hasBankAccounts

                return (
                  <>
                    <SidekickOption
                      key={`selected_member_option_${index}`}
                      selectedValue={form?.values?.receiver}
                      onSelect={() => handleOnChange(fieldReceiverName, member)}
                      disabled={disabled}
                      option={member}
                    />

                    {!hasBankAccounts && (
                      <Detail token="SK_DOESNT_HAS_BANK_ACCOUNT_YET" />
                    )}

                    {!countryMatch && isValidNationality && (
                      <Detail token="SK_COUNTRY_NOT_MATCH" />
                    )}

                    {!isValidNationality && (
                      <NationalityNumberBanner user={member?.userProfile} />
                    )}

                    {countryMatch && (
                      <CheckIdMissing
                        userProfile={member?.userProfile}
                        userDocuments={member?.userDocuments || []}
                      />
                    )}
                  </>
                )
              })}
            {!hideFooter && (
              <FormFooter>
                <Button button token="PREVIOUS_STEP" onClick={prev} />
                <FormButton onClick={handleNextBtn} token="NEXT" />
              </FormFooter>
            )}
          </>
        )
      }}
    </Form>
  )
}

export default NexPayReceivePaymentForm

