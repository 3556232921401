import React from 'react'

import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { useMutation } from '@apollo/client'
import {
  createNexPayFundsTransfer,
  createTransferMateFundsTransfer,
} from '../../../queries'
import countries from '../../../utils/countries'
import { calculateAmountInCents } from '../../../utils/funds'

import Button from '../../../components/Button'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import FormHeader from '../../../components/typography/FormHeader'
import FormFooter from '../../../components/form/FormFooter'
import Detail from '../../../components/typography/Detail'
import Text from '../../../components/typography/Text'
import SelectPaymentMethod from '../../../components/drawers/options/SelectPaymentMethod'
import PaymentProfileOption from '../../../components/lists/items/PaymentProfileOption'
import SidekickOption from '../../../components/lists/items/SidekicksOption'
import FlowContext from '../../../context/FlowContext'
import Flex from '../../../components/Flex'

type NexPayFundAccountReviewStepProps = {
  loading?: boolean
}

const NexPayFundAccountReviewStep: React.FC<
  NexPayFundAccountReviewStepProps
> = ({ loading: viewLoading }) => {
  const { next, prev, currentData, setFlowDataAttribute } =
    React.useContext(FlowContext)
  const [createNexPayTransfer, { loading: nexPayLoading, data: nexPayData }] =
    useMutation(createNexPayFundsTransfer)
  const [acceptTOS, setAccetpTOS] = React.useState<boolean>(true)

  const [
    createTransferMateTransfer,
    { loading: transferMateLoading, data: transferData },
  ] = useMutation(createTransferMateFundsTransfer)

  const { theme } = useTheme()

  const isTransferMate =
    currentData.data.paymentMethod.selectedQuote.provider === 'TransferMate'

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const handleSubmit = async () => {
    const { data } = currentData

    const variables = {
      paymentProfileId: data.paymentProfile.id,
      forUser: data.receiver.userProfile.id,
      amountInCents: calculateAmountInCents(data.amount),
      currency: data.paymentMethod.quotes.amount.currencyKey,
      input: {
        quoteId: data.paymentMethod.selectedQuote.quoteId,
        quoteVariantId: data.paymentMethod.selectedQuote.id,
        countryCode: countries.find(
          (country) => country.country === data.country
        )?.abbreviation,
      },
    }

    if (data.paymentMethod.selectedQuote.provider === 'NexPay') {
      await createNexPayTransfer({ variables })
    }

    if (data.paymentMethod.selectedQuote.provider === 'TransferMate') {
      await createTransferMateTransfer({ variables })
    }
  }

  React.useEffect(() => {
    if (!!nexPayData || !!transferData) {
      setFlowDataAttribute(
        'transactionId',
        nexPayData?.createNexPayFundsTransfer?.transactionId ||
          transferData?.createTransferMateFundsTransfer?.transactionId
      )
      next()
    }
  }, [nexPayData, transferData])

  const loading = viewLoading || nexPayLoading || transferMateLoading

  return (
    <FlowStepLayout loading={loading}>
      <FormHeader token="REVIEW_AND_CONFIRM" />
      <Text css={styles.details} token="VERIFY_DETAILS_BELOW_ARE_CORRECT" />

      <Detail uppercase token="WHO_IS_MAKING_THE_PAYMENT" />
      <PaymentProfileOption
        hideRadio
        option={currentData.data.paymentProfile}
      />
      <Detail uppercase token="WHO_WILL_RECEIVE_THESE_FUNDS" />
      <SidekickOption hideRadio option={currentData.data.receiver} />
      <Detail uppercase token="PAYMENT_METHOD" />
      <SelectPaymentMethod
        hideRadio
        option={currentData.data.paymentMethod.selectedQuote}
        amount={currentData.data.paymentMethod.quotes.amount}
      />

      <Flex vertical css={styles.columns}>
        <Detail uppercase token="AMOUNT_TO_BE_ADDED" />
        <Detail
          css={styles.price}
          currencySimbol={
            currentData.data.paymentMethod.quotes.amount.currencyKey
          }
          currency={currentData.data.amount}
        />
        <Detail uppercase token="AMOUNT_TO_PAY" />
        <Detail
          css={styles.price}
          currencySimbol={
            currentData.data.paymentMethod.selectedQuote.fromCurrency
          }
          currency={currentData.data.paymentMethod.selectedQuote.fromAmount}
        />
      </Flex>

      {isTransferMate && (
        <Flex centerY gap="1rem">
          <input
            type="checkbox"
            id="accept"
            name="accept"
            onChange={(e) => setAccetpTOS(!e.target.checked)}
          />
          <label htmlFor="accept">
            <Text token="TRANSFERMATE_TOS" />
            <Text
              token="TRANSFERMATE_TOS_LINK"
              as="a"
              target="_blank"
              href="https://www.transfermate.com/"
            />
          </label>
        </Flex>
      )}
      {!loading && (
        <FormFooter>
          <Button button token="PREVIOUS_STEP" onClick={prev} />
          <Button
            button
            disabled={isTransferMate ? acceptTOS : false}
            onClick={handleSubmit}
            token="PROCEED_WITH_PAYMENT"
          />
        </FormFooter>
      )}
    </FlowStepLayout>
  )
}

const computeStyles: TComputeStyles = ({ FULL_CONTRAST }: TTheme) => ({
  details: {
    marginBottom: 10,
  },
  columns: {
    flex: 1,
    alignItems: 'end',
  },
  price: {
    color: FULL_CONTRAST,
    fontSize: 20,
    marginBottom: 10,
  },
})

export default NexPayFundAccountReviewStep
