import React from 'react'

import Button from '../../../components/Button'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import FormHeader from '../../../components/typography/FormHeader'
import FormFooter from '../../../components/form/FormFooter'
import Detail from '../../../components/typography/Detail'
import Text from '../../../components/typography/Text'

import FlowContext from '../../../context/FlowContext'

const NexPayFundNewPaymentProfileThanksStep = () => {
  const { next } = React.useContext(FlowContext)

  return (
    <FlowStepLayout>
      <FormHeader token="PAYMENT_PROFILE_CREATED" />
      <Text token="THANK_YOU_FOR_CREATING_PAYMENT_PROFILE_DETAIL" />
      <Detail token="WE_WILL_LET_YOU_KNOW_WHEN_IT_IS_APPROVED" />

      <FormFooter>
        <Button button onClick={next} token="GO_BACK_TO_DASHBOARD" />
      </FormFooter>
    </FlowStepLayout>
  )
}

export default NexPayFundNewPaymentProfileThanksStep
