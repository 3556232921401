import { createContext } from 'react'

export type FlowContextProps = {
  next?: Function
  prev?: Function
  currentIndex?: number
  currentStep?: { title: string }
  steps?: JSX.Element[]
  name?: string
  cancel?: string | Function
  currentData?: { data: { [key: string]: any }; meta: { [key: string]: any } }
  currentOutlet?: JSX.Element
  setFlowData?: Function
  setFlowDataAttribute?: Function
  setFlowMeta?: Function
  setKeepDirty?: Function
  nextFlow?: Function
  setStep?: Function
  reloadSteps?: Function
  loading?: boolean
}

const FlowContext = createContext<FlowContextProps>({})

export default FlowContext
