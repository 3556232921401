import React from 'react'
import * as Yup from 'yup'

import Form from '../Form'

import FieldCard from '../../cards/FieldCard'
import Text from '../../typography/Text'
import { DocumentTypeItem } from '../../drawers/options/SelectDrawerDocumentTypeOption'
import SelectDrawerDocumentTypeOption from '../../drawers/options/SelectDrawerDocumentTypeOption'
import SelectDrawerField from '../fields/SelectDrawerField'
import FilesField from '../fields/FilesField'
import Detail from '../../typography/Detail'
import FormFooter from '../FormFooter'
import Button from '../Button'
import CountrySelectField from '../fields/CountrySelectField'

import { documentIdTypes } from './UploadDocumentForm'

type NexPayPaymentDocumentFormProps = {
  onSubmit: Function
  showCountry?: Boolean
  hideFooter?: boolean
  submitToken?: String
}

const NexPayPaymentProfileDocumentForm: React.FC<
  NexPayPaymentDocumentFormProps
> = ({
  onSubmit,
  showCountry = false,
  hideFooter = false,
  submitToken = 'NEXT',
  ...rest
}) => {
  const schema = Yup.object().shape({
    files: Yup.array().min(1).required('THIS_IS_A_REQUIRED_FIELD'),
    type: Yup.object().required('THIS_IS_A_REQUIRED_FIELD'),
    ...(showCountry
      ? { country: Yup.string().required('THIS_IS_A_REQUIRED_FIELD') }
      : {}),
  })

  const formRef = React.useRef(null)

  const handleSubmit = async () => {
    if (onSubmit) onSubmit(formRef?.current?.values)
  }

  const nextSubmit = () => formRef?.current?.submitForm()

  return (
    <Form
      initialValues={{
        files: undefined,
        type: undefined,
        ...(showCountry ? { country: undefined } : {}),
      }}
      innerRef={formRef}
      validateOnChange
      validationSchema={schema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {(form) => {
        return (
          <>
            <FieldCard label={<Text token="ID_DOCUMENT_FOR_PAYER" />}>
              <Detail token="ID_DOCUMENT_FOR_PAYER_DESCRIPTION" />

              {showCountry && (
                <CountrySelectField
                  label={<Text token="COUNTRY" />}
                  name="country"
                />
              )}

              <SelectDrawerField
                label="Document Type"
                name="type"
                titleToken="SELECT_TYPE_OF_DOCUMENT"
                result={(documentType) => (
                  <DocumentTypeItem documentType={documentType} />
                )}
                required
              >
                {documentIdTypes &&
                  documentIdTypes.map((documentType) => {
                    return (
                      <SelectDrawerDocumentTypeOption option={documentType} />
                    )
                  })}
              </SelectDrawerField>

              <FilesField
                name="files"
                accept=".pdf, .jpg, .png, .jpeg, .bmp, .tif"
              />
            </FieldCard>

            {!hideFooter && (
              <FormFooter>
                <Button button onClick={nextSubmit} token={submitToken} />
              </FormFooter>
            )}
          </>
        )
      }}
    </Form>
  )
}

export default NexPayPaymentProfileDocumentForm
