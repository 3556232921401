import React from 'react'
import { useTheme } from '@emotion/react'

import Text, { TextProps } from './Text'

type FieldTextType = {
  light?: boolean
  size?: number
} & TextProps

const FieldText: React.FC<FieldTextType> = ({ light, size = 16, ...rest }) => {
  const { theme } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, size, light }),
    [theme]
  )

  return <Text css={styles.root} {...rest} />
}

const computeStyles = ({ TEXT_PRIMARY, TEXT_PRIMARY_DARK, size, light }) => ({
  root: {
    color: light ? TEXT_PRIMARY : TEXT_PRIMARY_DARK,
    fontSize: size,
  },
})

export default FieldText
