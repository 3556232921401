import React from 'react'
import { useQuery } from '@apollo/client'

import CardLayoutLink from '../../components/layout/CardLayoutLink'
import TeamMemberCard from '../../components/cards/TeamMemberCard'
import Text from '../../components/typography/Text'
import useIsSidekick from '../../hooks/useIsSidekick'

import { getUserProfile } from '../../queries'

const SettingsProfileScene = ({ ...rest }) => {
  const { data, loading, error } = useQuery(getUserProfile)

  const isSK = useIsSidekick()

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      {data && (
        <TeamMemberCard
          user={data.userProfile}
          subtitle={<Text token={isSK ? 'SIDEKICK' : 'HERO_ACCOUNT_OWNER'} />}
          baseUrl="/settings/profile"
          showInvitationStatus={false}
          sidekick={isSK}
        />
      )}
    </CardLayoutLink>
  )
}

export default SettingsProfileScene
