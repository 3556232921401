import React from 'react'

import Form from '../Form'

import * as Yup from 'yup'
import SelectPaymentMethod from '../../drawers/options/SelectPaymentMethod'
import FlowContext from '../../../context/FlowContext'
import FormHeader from '../../typography/FormHeader'
import FormFooter from '../FormFooter'
import Button from '../Button'
import Detail from '../../typography/Detail'

export const paymentMethodFormField = 'paymentMethod'

const schema = Yup.object({
  [paymentMethodFormField]: Yup.mixed().required(),
})

type NexPayPaymentMethodProps = {
  quotes: Object
  loading?: Boolean
}

const NexPayPaymentMethod: React.FC<NexPayPaymentMethodProps> = ({
  quotes,
  loading,
  ...rest
}) => {
  const formRef = React.useRef(null)
  const { next, prev, currentData, setFlowDataAttribute } =
    React.useContext(FlowContext)

  const unavailablesQuotes = !quotes?.variants?.length

  const handleSubmit = async () => {
    if (formRef?.current.isValid) {
      await setFlowDataAttribute(paymentMethodFormField, {
        selectedQuote: formRef?.current?.values?.[paymentMethodFormField],
        quotes,
      })
      next()
    }
  }

  const handleNextBtn = async () => await formRef?.current?.submitForm()

  const handleChange = async (value) =>
    formRef?.current?.setFieldValue(paymentMethodFormField, value)

  if (unavailablesQuotes) {
    return (
      <>
        <Detail center token="NO_QUOTES" />
        {!loading && (
          <FormFooter>
            <Button button token="PREVIOUS_STEP" onClick={() => prev()} />
          </FormFooter>
        )}
      </>
    )
  }

  const sortedVariants = quotes.variants.sort((a,b) => a.rate - b.rate);

  return (
    <Form
      initialValues={{
        [paymentMethodFormField]: currentData?.data?.[paymentMethodFormField],
      }}
      innerRef={formRef}
      validateOnChange
      validationSchema={schema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {(form) => {
        return (
          <>
            <FormHeader token="SELECT_YOUR_METHOD_OF_PAYMENT" />
            <Detail
              token="ADDING_AMOUNT"
              interpolation={{
                moneyIdentifier: quotes?.amount?.currencyKey,
                money: `${quotes?.amount?.basicUnit}.${quotes?.amount?.fraction}`,
              }}
            />

            {sortedVariants?.length &&
              sortedVariants.map((card) => (
                <SelectPaymentMethod
                  key={card.id}
                  selectedValue={form?.values?.[paymentMethodFormField]}
                  onSelect={handleChange}
                  option={card}
                  amount={quotes.amount}
                />
              ))}
            {!loading && (
              <FormFooter>
                <Button button token="PREVIOUS_STEP" onClick={prev} />
                <Button
                  button
                  onClick={handleNextBtn}
                  token="NEXT"
                  disabled={unavailablesQuotes}
                />
              </FormFooter>
            )}
          </>
        )
      }}
    </Form>
  )
}

export default NexPayPaymentMethod
