import React from 'react'

import * as Yup from 'yup'
import { DateTime } from 'luxon'
import Form from '../Form'
import Field from '../Field'
import GenderSelectField from '../fields/GenderSelectField'
import FormButton from '../Button'
import FieldCard from '../../cards/FieldCard'
import DateField from '../fields/DateField'
import FormFooter from '../FormFooter'
import Text from '../../typography/Text'
import CountrySelectField from '../fields/CountrySelectField'

import { cleanNullProps } from '../../../utils/functions'
import PhoneField, {
  PHONE_VALIDATOR,
  PHONE_VALIDATOR_REQUIRED,
} from '../fields/PhoneField'

import { regionLabelDict } from './NewMemberInputDataForm'
import NATIONALITY_DICT from '../../../utils/nationality'

export type PersonalInformationFormProps = {
  user: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  hideContactInfo?: boolean
  isSk?: boolean
}

const fixedSchema = {
  givenName: Yup.string().required(),
  familyName: Yup.string().required(),
  email: Yup.string().email(),
  gender: Yup.string().required(),
  principalAddress: Yup.object().shape({
    addressLine1: Yup.string().required(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().required(),
    region: Yup.string().required(),
  }),
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  user,
  submit,
  disabled,
  loading,
  error,
  hideContactInfo,
  isSk,
  ...rest
}) => {
  const {
    id,
    givenName,
    middleNames,
    nationality,
    number,
    studentId,
    familyName,
    email,
    dateOfBirth,
    gender,
    locale,
    principalAddress,
    mobilePhoneNumber,
  } = user

  const { addressLine1, addressLine2, city, country, postalCode, region } =
    principalAddress || {}

  const defaultInitialValues = {
    id,
    givenName,
    middleNames,
    familyName,
    nationality,
    number,
    studentId,
    email,
    dateOfBirth,
    gender,
    locale,
    mobilePhoneNumber,
    principalAddress: {
      addressLine1,
      addressLine2,
      city,
      country,
      postalCode,
      region,
    },
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  const [numberField, setNumberField] = React.useState(
    NATIONALITY_DICT[nationality]
  )

  let numberValidation = numberField
    ? Yup.string().matches(numberField?.format, 'INVALID_FIELD')
    : Yup.string()

  let mobilePhoneNumberSK
  if (!hideContactInfo) {
    mobilePhoneNumberSK = PHONE_VALIDATOR
  }

  const conditionalValidations = isSk
    ? {
        mobilePhoneNumber: mobilePhoneNumberSK,
        number: numberValidation
          .nullable()
          .required('THIS_IS_A_REQUIRED_FIELD'),
        nationality: Yup.string()
          .nullable()
          .required('THIS_IS_A_REQUIRED_FIELD'),
        dateOfBirth: Yup.string()
          .test('DOB', 'SIDEKICK_MUST_BE_5_YEARS_OLD', (value) => {
            return DateTime.fromISO(value).diffNow('years').years < -5
          })
          .required('THIS_IS_A_REQUIRED_FIELD'),
      }
    : {
        mobilePhoneNumber: PHONE_VALIDATOR_REQUIRED,
        number: numberValidation,
        nationality: Yup.string(),
        dateOfBirth: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
      }

  const schema = Yup.object().shape({
    ...fixedSchema,
    number: numberValidation,
    ...conditionalValidations,
  })
  return (
    <Form
      onSubmit={handleSubmit}
      disabled={disabled}
      validationSchema={schema}
      loading={loading}
      initialValues={defaultInitialValues}
      {...rest}
    >
      {(form) => {
        if (form.values.nationality) {
          setNumberField(NATIONALITY_DICT[form.values.nationality])
        }
        return (
          <>
            <FieldCard label={<Text token="PERSONAL_INFORMATION" />}>
              <Field label={<Text token="FIRST_NAME" />} name="givenName" />
              {!isSk && (
                <Field
                  label={<Text token="MIDDLE_NAMES" />}
                  name="middleNames"
                />
              )}
              <Field label={<Text token="LAST_NAME" />} name="familyName" />

              <CountrySelectField
                label={<Text token="NATIONALITY" />}
                abbreviation
                name="nationality"
              />

              <Field
                disabled={!form.values.nationality}
                label={<Text token={number?.label || 'DOCUMENT_NUMBER'} />}
                name="number"
              />

              <GenderSelectField
                label={
                  <Text
                    token={
                      isSk
                        ? 'WHAT_DOES_YOUR_SIDEKICK_IDENTIFY_AS'
                        : 'WHAT_DO_YOU_IDENTIFY_AS'
                    }
                  />
                }
                name="gender"
                as="select"
              />

              <DateField
                label={<Text token="DATE_OF_BIRTH" />}
                name="dateOfBirth"
              />
            </FieldCard>

            {!hideContactInfo && (
              <FieldCard label={<Text token="CONTACT_INFO" />}>
                <Field label={<Text token="EMAIL" />} name="email" disabled />
                <PhoneField
                  label={<Text token="PHONE_NUMBER" />}
                  name="mobilePhoneNumber"
                />
              </FieldCard>
            )}

            <FieldCard label={<Text token="ADDRESS" />}>
              <Field
                label={<Text token="ADDRESS_LINE_1" />}
                name="principalAddress.addressLine1"
              />
              <Field
                label={<Text token="ADDRESS_LINE_2" />}
                name="principalAddress.addressLine2"
              />
              <Field
                label={<Text token="CITY" />}
                name="principalAddress.city"
              />
              <Field
                label={<Text token="POSTAL_OR_ZIP_CODE" />}
                name="principalAddress.postalCode"
              />
              <Field
                label={
                  <Text
                    token={
                      regionLabelDict[
                        form.values.principalAddress?.country?.toLowerCase?.()
                      ] || regionLabelDict.default
                    }
                  />
                }
                name="principalAddress.region"
              />
              <CountrySelectField
                label={<Text token="COUNTRY" />}
                name="principalAddress.country"
              />
            </FieldCard>

            <FormFooter>
              <FormButton token="SAVE" loading={loading} />
            </FormFooter>
          </>
        )
      }}
    </Form>
  )
}

export default PersonalInformationForm
