import React from 'react'
import get from 'lodash/get'

import FEATURES, { FeatureType } from '../utils/features'

import useCurrentUser from '../hooks/useCurrentUser'
import useTeamMembers from '../hooks/useTeamMembers'

export type WithFeatureProps = {
  feature: FeatureType
} & any

const withFeature =
  (Wrapped: any, hocFeature?: FeatureType) =>
  ({ feature, ...props }: WithFeatureProps) => {
    const { data: currentUser } = useCurrentUser()
    const { teamMembers } = useTeamMembers()

    const featureId = feature || hocFeature
    const hasTeamMembers = teamMembers?.length > 0

    if (featureId && currentUser && hasTeamMembers) {
      const foundConditions = get(FEATURES, featureId)

      if (foundConditions) {
        let result = true
        let condition = null

        for (const conditionIndex in foundConditions) {
          condition = foundConditions[conditionIndex]
          result = condition(currentUser)

          if (!result) break
        }

        if (!result) return null
      }
    }

    return <Wrapped {...props} />
  }

export default withFeature
