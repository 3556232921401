import React from 'react'
import { useMutation } from '@apollo/client'
import useCurrentUser from '../../../hooks/useCurrentUser'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import { paymentProfileFieldName } from '../../../components/form/forms/NexPayPayerIdentityForm'
import NexPayPaymentProfileForm from '../../../components/form/forms/NexPayPaymentProfileForm'
import FlowContext from '../../../context/FlowContext'
import DevButton from '../../../components/dev/DevButton'
import { findPaymentProfiles, addPaymentProfile } from '../../../queries'
import useRandomUser from '../../../hooks/useRandomUser'
import usePaymentProfiles from '../../../hooks/usePaymentProfiles'
import countries from 'src/utils/countries'


const defaultNationalityProps = {
  nationality: null,
  number: null,
  taxNumber: null,
}

type principalAddressProps = {
  addressLine1?: String
  city?: String
  country?: String
  postalCode?: String
  region?: String
}

type userDataType = {
  givenName?: String
  familyName?: String
  principalAddress?: principalAddressProps
  email?: String
  mobilePhoneNumber?: String
  dateOfBirth?: String
  nationality?: String
  number?: String
  taxNumber?: String
}

export const paymentProfileParser = (userData: userDataType = {}) => {
  if (!userData) return undefined
  const {
    givenName,
    familyName,
    principalAddress,
    email,
    mobilePhoneNumber,
    dateOfBirth,
    nationality = 'MX',
    number = '123654987789945677',
    taxNumber = '123654987789945677',
  } = userData

  return {
    givenName,
    familyName,
    ...(principalAddress || {}),
    nationality,
    number,
    email,
    phoneNumber: mobilePhoneNumber,
    dateOfBirth,
    taxNumber,
  }
}

type NexPayFundAddPaymentProfileProps = {
  newFromCurrent?: boolean
}

const NexPayFundAddPaymentProfile: React.FC<NexPayFundAddPaymentProfileProps> = ({ newFromCurrent })=> {
  const [{ data: randomUserData, loading: randomUserLoading }, getRandomUser] =
    useRandomUser()

  const { data: paymentProfiles, loading: paymentProfileLoading } =
    usePaymentProfiles()

  const [addNewPaymentProfile, { loading: addPaymentProfileLoading }] =
    useMutation(addPaymentProfile)

  const { currentData } = React.useContext(FlowContext)

  const { next, setFlowDataAttribute } = React.useContext(FlowContext)
  const { data: rawUser, loading: userLoading } = useCurrentUser()

  const user = { userProfile: {...defaultNationalityProps, ...rawUser?.userProfile } }

  const profiles = paymentProfiles?.paymentProfiles || []

  const loading =
    userLoading || paymentProfileLoading || addPaymentProfileLoading



  const handleNewPaymentProfile = async (values) => {
    const { data } = await addNewPaymentProfile({
      variables: {
        input: { ...values, locale: user.userProfile.locale },
      },
      update: (store, { data }) => {
        store.writeQuery({
          query: findPaymentProfiles,
          data: { paymentProfiles: [...profiles, data?.addPaymentProfile] },
        })
      },
    })

    await setFlowDataAttribute(
      paymentProfileFieldName,
      data?.addPaymentProfile,
      true
    )
    next()
  }

  const initial = newFromCurrent
    ? user?.userProfile
    : randomUserData;

  if ( initial && currentData?.data?.country ){

    const country = countries.find( ( { country } )=> country == currentData.data.country ) // eslint-disable-line eqeqeq
    initial.nationality = country.abbreviation
    initial.principalAddress = initial.principleAddress
      ? { country: country.country, ...initial.principleAddress }
      : { country: country.country }

  }


  return (
    <FlowStepLayout loading={loading}>
      {!newFromCurrent &&  <DevButton
        label="Fill with Random User"
        onClick={getRandomUser}
        loading={randomUserLoading}
        type="button"
        button
      />
      }

      {!randomUserLoading && (
        <NexPayPaymentProfileForm
          initialValues={paymentProfileParser(initial)}
          onSubmit={handleNewPaymentProfile}
          hideFooter={loading}
        />
      )}
    </FlowStepLayout>
  )
}

export default NexPayFundAddPaymentProfile
