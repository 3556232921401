import React from 'react'

import List from './List'
import SubscriptionPlanCard from '../cards/SubscriptionPlanCard'
import { SORTED_SUBSCRIPTIONS } from '../../utils/subscription'

const MONTHLY: string = 'PerOneMonth'
const ANNUALLY: string = 'PerOneYear'

const filters = {
  monthly: (subscription) => subscription?.billingPeriod === MONTHLY,
  annually: (subscription) => subscription?.billingPeriod === ANNUALLY,
}

type SubscriptionsListProps = {
  subscriptionPlans: Array<any>
  activeSubscriptionIndex: number
  filter?: keyof typeof filters
  select?: Function
}

const SubscriptionsList: React.FC<SubscriptionsListProps> = ({
  subscriptionPlans,
  activeSubscriptionIndex,
  filter = 'monthly',
  select = () => {},
}) => {
  const foundFilter = filters[filter]

  return (
    <List vertical gap="1rem">
      {!!subscriptionPlans?.length &&
        subscriptionPlans
          .filter(foundFilter)
          .map((subscription) => (
            <SubscriptionPlanCard
              callback={() => select(subscription.code)}
              key={subscription.code}
              subscription={subscription}
              disabled={
                SORTED_SUBSCRIPTIONS.findIndex(
                  (sortedSusbscription) =>
                    sortedSusbscription === subscription.code
                ) <= activeSubscriptionIndex
              }
              promotion={'FIRST_MONTH_FREE'}
            />
          ))}
    </List>
  )
}

export default SubscriptionsList
