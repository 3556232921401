import React from 'react'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import Form from '../Form'

import Field from '../Field'
import FieldCard from '../../cards/FieldCard'
import FormFooter from '../FormFooter'
import Button from '../Button'
import Text from '../../typography/Text'
import DateField from '../fields/DateField'
import PhoneField from '../fields/PhoneField'
import FlowContext from '../../../context/FlowContext'
import CountrySelectField from '../fields/CountrySelectField'
import NATIONALITY_DICT from '../../../utils/nationality'


const fixedSchema = {
  givenName: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  familyName: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  addressLine1: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  addressLine2: Yup.string(),
  email: Yup.string().email().required('THIS_IS_A_REQUIRED_FIELD'),
  city: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  phoneNumber: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  country: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  nationality: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  dateOfBirth: Yup.string()
    .test('DOB', 'PAYER_MUST_BE_18_YEARS_OLD', (value) => {
      return DateTime.fromISO(value).diffNow('years').years < -18
    })
    .required('THIS_IS_A_REQUIRED_FIELD'),
  postalCode: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  region: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  taxNumber: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
}

type NexPayPaymentProfileFormProps = {
  onSubmit: Function
  initialValues?: any
  disabled?: boolean
  hidePrevBtn?: boolean
  hideFooter?: boolean
}

const NexPayPaymentProfileForm: React.FC<NexPayPaymentProfileFormProps> = ({
  onSubmit = () => {},
  initialValues,
  disabled,
  hidePrevBtn,
  hideFooter,
  ...rest
}) => {
  const [number, setNumber] = React.useState(
    NATIONALITY_DICT[initialValues?.nationality]
  )
  const formRef = React.useRef(null)
  const { prev } = React.useContext(FlowContext)

  const handleSubmit = async () => {
    if (formRef?.current.isValid) {
      onSubmit(formRef.current.values)
    }
  }

  const numberValidation = number
    ? Yup.string()
        .matches(number?.format, 'INVALID_FIELD')
        .required('THIS_IS_A_REQUIRED_FIELD')
    : Yup.string().required('THIS_IS_A_REQUIRED_FIELD')

  const schema = Yup.object().shape({
    ...fixedSchema,
    number: numberValidation,
  })

  const handleNextBtn = async () => await formRef?.current?.submitForm()

  const labelToken = disabled ? 'PAYMENT_PROFILE' : 'PLEASE_REVIEW_DETAILS'

  return (
    <Form
      initialValues={
        initialValues || {
          givenName: undefined,
          familyName: undefined,
          nationality: undefined,
          number: undefined,
          addressLine1: undefined,
          addressLine2: undefined,
          email: undefined,
          city: undefined,
          phoneNumber: undefined,
          country: undefined,
          dateOfBirth: undefined,
          postalCode: undefined,
          region: undefined,
          taxNumber: undefined,
          locale: undefined,
        }
      }
      disabled={disabled}
      innerRef={formRef}
      validateOnChange
      validationSchema={schema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {(form) => {
        if (form.values.nationality) {
          setNumber(NATIONALITY_DICT[form.values.nationality])
        }
        return (
          <>
            <FieldCard label={<Text token={labelToken} />}>
              <Field label={<Text token="FIRST_NAME" />} name="givenName" />
              <Field label={<Text token="LAST_NAME" />} name="familyName" />
              <CountrySelectField
                abbreviation
                name="nationality"
                label={<Text token="NATIONALITY" />}
              />
              <Field
                disabled={!form.values.nationality}
                label={<Text token={number?.label || 'DOCUMENT_NUMBER'} />}
                name="number"
              />

              <Field label={<Text token="TAXPAYER_ID" />} name="taxNumber" />
              <DateField
                label={<Text token="DATE_OF_BIRTH" />}
                name="dateOfBirth"
              />
              <Field label={<Text token="ADDRESS_LINE_1" />} name="addressLine1" />
              <Field label={<Text token="ADDRESS_LINE_2" />} name="addressLine2" />
              <Field label={<Text token="CITY" />} name="city" />
              <Field label={<Text token="PROVINCE_STATE" />} name="region" />

              <Field
                label={<Text token="POSTAL_OR_ZIP_CODE" />}
                name="postalCode"
              />
              <CountrySelectField
                name="country"
                label={<Text token="COUNTRY" />}
              />
              <Field label={<Text token="EMAIL" />} name="email" />
              <PhoneField
                label={<Text token="PHONE_NUMBER" />}
                name="phoneNumber"
              />
            </FieldCard>

            {!hideFooter && (
              <FormFooter>
                {!hidePrevBtn && (
                  <Button button token="PREVIOUS_STEP" onClick={() => prev()} />
                )}
                <Button button onClick={handleNextBtn} token="NEXT" />
              </FormFooter>
            )}
          </>
        )
      }}
    </Form>
  )
}

export default NexPayPaymentProfileForm
