import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Flex from '../../../components/Flex'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import ComponentCard from '../../../components/cards/ComponentCard'
import useFundingRequests from '../../../hooks/useFundingRequests'
import Text from '../../../components/typography/Text'
import PendingRequestCard from './PendingRequestCard/PendingRequestCard'
import ProcessingPaymentCard from './ProcessingPaymentCard/ProcessingPaymentCard'
import CanceledPaymentCard from './CanceledPaymentCard/CanceledPaymentCard'
import useProcessingFundingRequests from '../../../hooks/useProcessingFundingRequests'
import useCanceledFundingRequests from '../../../hooks/useCanceledFundingRequests'
import CompletedPaymentCard from './CompletedPaymentCard/CompletedPaymentCard'
import useCompletedFundingRequests from '../../../hooks/useCompletedFundingRequests'
import useWaitForNotification from '../../../hooks/useWaitForNotification'

type FundingRequestsSceneProps = {
  title: JSX.Element
  subtitle?: JSX.Element
  back: string
}

const FundingRequestsScene: React.FC<FundingRequestsSceneProps> = ({
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [uploading, setUploading] = React.useState(false)
  const [canceling, setCanceling] = React.useState(false)

  const {
    data: fundingRequests,
    loading: fundingRequestLoading,
    refetch: refetchFundingRequest,
  } = useFundingRequests(false, true)

  const {
    data: processingFundingRequests,
    loading: processingFundingRequestLoading,
  } = useProcessingFundingRequests(false, true)

  const {
    data: canceledFundingRequests,
    loading: canceledFundingRequestLoading,
  } = useCanceledFundingRequests(false, true)

  const {
    data: completedFundingRequests,
    loading: completedFundingRequestLoading,
  } = useCompletedFundingRequests(false, true)

  const { data: foundNotification } = useWaitForNotification([
    'FundsTransferProcessing',
  ])

  const { data: foundCanceledNotification } = useWaitForNotification([
    'FundsTransferCanceled',
  ])

  React.useEffect(() => {
    if (!!foundNotification || !!foundCanceledNotification) {
      setCanceling(false)
      setUploading(false)
    }
  }, [foundNotification, foundCanceledNotification])

  const loading =
    fundingRequestLoading ||
    processingFundingRequestLoading ||
    canceledFundingRequestLoading ||
    completedFundingRequestLoading

  return (
    <CardLayoutLink
      loading={loading}
      processing={uploading || canceling}
      processingLabel={
        <Text
          token={uploading ? 'UPLOADING_PROOF_OF_PAYMENT' : 'CANCELING_PAYMENT'}
        />
      }
      {...rest}
    >
      <Flex vertical padding="1rem" gap="1rem" css={styles.content}>
        <ComponentCard
          css={styles.section}
          token="PENDING_PROOF_OF_PAYMENT"
          outsideLabel
          transparent
        >
          {fundingRequests.map((fundingRequest, index) => (
            <PendingRequestCard
              key={`pending_${index}`}
              fundingRequest={fundingRequest}
              setUploading={setUploading}
              setCanceling={setCanceling}
              refetch={refetchFundingRequest}
            />
          ))}
        </ComponentCard>
        <ComponentCard
          css={styles.section}
          token="PENDING_REVIEW"
          outsideLabel
          transparent
        >
          {processingFundingRequests.map((fundingRequest, index) => (
            <ProcessingPaymentCard
              key={`processing_${index}`}
              fundingRequest={fundingRequest}
            />
          ))}
        </ComponentCard>
        <ComponentCard
          css={styles.section}
          token="COMPLETE"
          outsideLabel
          transparent
        >
          {completedFundingRequests.map((fundingRequest, index) => (
            <CompletedPaymentCard
              key={`completed_${index}`}
              fundingRequest={fundingRequest}
            />
          ))}
        </ComponentCard>
        <ComponentCard
          css={styles.section}
          token="CANCELED"
          outsideLabel
          transparent
        >
          {canceledFundingRequests.map((fundingRequest, index) => (
            <CanceledPaymentCard
              key={`canceled_${index}`}
              fundingRequest={fundingRequest}
            />
          ))}
        </ComponentCard>
      </Flex>
    </CardLayoutLink>
  )
}

const computeStyles: TComputeStyles = ({ CARD_BORDER_DARK }: TTheme) => ({
  content: {
    boxSizing: 'border-box',
    width: '100%',
  },
  section: {
    borderRadius: 0,
    padding: '0 0 1rem',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${CARD_BORDER_DARK}`,
    },
  },
})

export default FundingRequestsScene
