import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { ReactI18NextChild } from 'react-i18next'
import clsx from 'clsx'

import Icon from '../Icon'

import withFeature from '../../hocs/withFeature'
import Link, { LinkProps } from '../Link'

export type ListItemLinkProps = {
  label?: string | ReactI18NextChild
  disabled?: boolean
  external?: boolean
} & LinkProps

const ListItemLink: React.FC<ListItemLinkProps> = ({
  label,
  to,
  disabled,
  className,
  external,
  children,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  return (
    <Link
      to={!disabled && to}
      css={styles.root}
      className={classNames}
      external={external}
      {...rest}
    >
      <div css={styles.label}>{label}</div>
      <div css={styles.content}>{children}</div>

      {to && <Icon icon="chevron" size={0.8} />}
    </Link>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  FIELD_BORDER,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
    borderBottom: `1px solid ${FIELD_BORDER}`,
    color: TEXT_PRIMARY_DARK,
    display: 'flex',
    fontSize: 14,
    padding: '20px 26px',
    textDecoration: 'none',

    '&:last-child': {
      borderBottom: 0,
    },
    '&.is-disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },

  label: {
    flex: '1 auto',
  },
  content: {
    display: 'flex',
    padding: '0 8px',
  },
})

export default withFeature(ListItemLink)
