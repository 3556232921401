import React from 'react'
import ComponentCard from '../cards/ComponentCard'
import Text from '../typography/Text'
import { isEmpty } from 'lodash'
import PaymentProfileDocumentItem, {
  PaymentProfileDocumentItemProps,
} from './items/PaymentProfileDocumentItem'

type PaymentProfileDocumentsListProps = {
  list: Array<PaymentProfileDocumentItemProps>
}

const DocumentsList: React.FC<PaymentProfileDocumentsListProps> = ({
  list,
}) => (
  <ComponentCard empty={isEmpty(list)} label={<Text token="DOCUMENTS"></Text>}>
    {list &&
      !!list?.length &&
      list.map((doc) => <PaymentProfileDocumentItem key={doc.id} {...doc} />)}
  </ComponentCard>
)

export default DocumentsList
