import { useEffect, useState } from 'react'
import { Trulioo, event } from '@trulioo/docv'

import { useMutation } from '@apollo/client'
import useAxios from 'axios-hooks'

import { storeVerificationTransaction } from '../queries/mutations'

export enum ID_VERIFICATION_STATUS {
  PENDING = 'PENDING',
  MATCH = 'MATCH',
  NOMATCH = 'NOMATCH',
}

export const elementTruliooID = 'trulioo-sdk'

const useTrulioo = (redirectUrl?: string) => {
  let shortCode
  const [status, setStatus] = useState<ID_VERIFICATION_STATUS>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>()
  const [data, setData] = useState<any>()
  const [exception, setException] = useState<any>()

  const [
    mutateVerification,
    { loading: verificationLoading, error: verificationError },
  ] = useMutation(storeVerificationTransaction)

  const [, request] = useAxios(process.env.REACT_APP_TRULIOO_URL, {
    manual: true,
  })

  const init = () => {
    const callbacks = new event.adapters.ListenerCallback({
      onComplete: async (success) => {
        const { transactionId } = success
        const { data: mutationData } = await mutateVerification({
          variables: {
            transactionId,
          },
        })
        setStatus(mutationData?.storeVerificationTransaction?.status)
        setData(success)
      },
      onError: (error) => {
        setError(error)
      },
      onException: (exception) => {
        setException(exception)
      },
    })

    const callbackOption = Trulioo.event().setCallbacks(callbacks)

    let workflowOption = Trulioo.workflow().setShortCode(shortCode)

    if (redirectUrl) {
      workflowOption = workflowOption.setRedirectUrl(redirectUrl)
    }

    Trulioo.initialize(workflowOption)
      .then(() => {
        Trulioo.launch(elementTruliooID, callbackOption)
          .then(() => {
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            setError(error)
          })
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
      })
  }

  useEffect(() => {
    const service = async () => {
      try {
        const { data } = await request()
        shortCode = data.shortCode
        init()
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }
    service()
  }, [])

  return {
    status,
    data,
    loading: verificationLoading || loading,
    error: verificationError || error,
    exception,
    refetch: init,
  }
}

export default useTrulioo
