import React from 'react'
import { useTheme, TTheme, TComputeStyles } from '@emotion/react'
import Button from './Button'

import Banner, { Props as BannerProps } from './Banner'

export type NationalityNumberBannerProps = {
  user: any
}

export type Props = NationalityNumberBannerProps & BannerProps

const NationalityNumberBanner: React.FC<Props> = ({ user, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Banner
      icon="checkCircle"
      css={styles.root}
      color={theme.SUCCESS}
      token={'EDIT_NATIONALITY'}
      interpolation={{ name: user.givenName, familyName: user.familyName }}
      actions={[
        <Button
          css={styles.redirect}
          token="COMPLETE"
          to={`/settings/team/${user.id}/personal-information`}
        />,
      ]}
      {...rest}
    />
  )
}

const computeStyles: TComputeStyles = (theme: TTheme) => ({
  root: {
    justifyContent: 'center',
  },
  redirect: {
    background: 'none',
    color: theme.LINK,
    width: 'min-content',
    padding: 0,
  },
})

export default NationalityNumberBanner
