import React from 'react'
import Flex from '../../Flex'
import ComponentCard from '../../cards/ComponentCard'
import DocumentIcon from '../../DocumentIcon'
import FieldText from '../../typography/FieldText'
import DocumentStatusPill from '../../DocumentStatusPill'

export type PaymentProfileDocumentItemProps = {
  id: string
  type: string
  status: string
}



const PaymentProfileDocumentItem: React.FC<PaymentProfileDocumentItemProps> = ({
  type,
  status,
}) => (
  <ComponentCard>
    <Flex centerY gap="0.5rem">
      <DocumentIcon
        iconColor="BLACK_900"
        color="BLUE_50"
        size={56}
        scale={1.5}
        document={{ type }}
      />
      <Flex vertical gap="0.25rem">
        <FieldText token={type}></FieldText>
        <Flex>
          <DocumentStatusPill status={status} />
        </Flex>
      </Flex>
    </Flex>
  </ComponentCard>
)

export default PaymentProfileDocumentItem
