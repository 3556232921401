import React from 'react'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

import SelectField from './SelectField'

import countries from '../../../utils/countries'
import { FieldProps } from '../Field'

type CountrySelectFieldProps = {
  abbreviation?: boolean
  disableList?: string[]
} & FieldProps

const CountrySelectField: React.FC<CountrySelectFieldProps> = ({
  abbreviation = false,
  disableList,
  ...rest
}) => {
  return (
    <SelectField {...rest}>
      {countries.map((country) => {
        let isDisabled = false
        if (disableList && !isEmpty(disableList)) {
          isDisabled = !!find(
            disableList,
            (disabledCountry) => disabledCountry === country.abbreviation
          )
        }

        return (
          <option
            key={`country_${country.code}_${country.abbreviation}`}
            value={abbreviation ? country.abbreviation : country.country}
            disabled={isDisabled}
          >
            {country.country}
          </option>
        )
      })}
    </SelectField>
  )
}

export default CountrySelectField
