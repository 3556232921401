import React, { PropsWithChildren } from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import { useField, Field as FormikField } from 'formik'
import isString from 'lodash/isString'
import get from 'lodash/get'
import clsx from 'clsx'

import FormContext from '../../context/FormContext'

import Grid from '../Grid'
import FieldStatus from './FieldStatus'
import Element from '../Element'
import Flex from '../Flex'
import Icon from '../Icon'

export type FieldProps = {
  label?: string | JSX.Element
  description?: string | JSX.Element
  type?: string
  as?: string
  name?: string
  hidden?: boolean
  required?: boolean
  disabled?: boolean
  checked?: boolean
  fieldStyles?: any
  className?: string
  currency?: any
  value?: any
  maxLength?: number
  placeholder?: string
  onBlur?: Function
  showRadioBorder?: boolean
  collapseStatus?: boolean
  component?: JSX.Element | React.FC<any>
} & PropsWithChildren

const Field: React.FC<FieldProps> = ({
  label,
  description,
  type,
  className,
  required,
  children,
  disabled,
  currency,
  fieldStyles,
  showRadioBorder,
  collapseStatus,
  ...rest
}) => {
  const fieldProps = {
    as: rest.as,
    name: rest.name,
    value: rest.value,
  }
  const { theme, sizes } = useTheme()
  const [field] = useField(fieldProps)
  const formState = React.useContext<any>(FormContext)
  const { name, hidden, as } = rest
  const { errors, submitCount, handleFieldUpdate } = formState

  const error = get(errors, name)

  const handleOnFieldChange = (value) => {
    if (handleFieldUpdate) handleFieldUpdate(name, value)
  }

  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const isRadio = type === 'radio'
  const isCheckbox = type === 'checkbox'
  const isSelect = as === 'select'

  const currencyValue = currency && isString(currency) ? currency : '$'

  const classNames = clsx({
    [className]: className,
    'is-disabled': formState.disabled || disabled,
    'is-loading': formState.loading,
    'is-radio': isRadio,
    'is-checkbox': isCheckbox,
    'is-selected': formState?.values?.[name] === rest?.value,
    'has-currency': currency,
  })
  const fieldClassNames = clsx({
    'is-disabled': formState.disabled || disabled,
    'is-loading': formState.loading,
    'is-radio': isRadio,
    'is-select': isSelect,
  })

  return (
    <>
      {!hidden && (
        <Element>
          <Grid
            as="label"
            gap={sizes.FIELD_GAP}
            css={styles.root}
            className={classNames}
          >
            {label && (
              <span>
                {label}
                {required ? '*' : ''}
              </span>
            )}

            {currency && (
              <Element css={styles.currency}> {currencyValue}</Element>
            )}
            {description && description}

            <Flex css={styles.fieldWrapper} className={fieldClassNames}>
              <FormikField
                className={fieldClassNames}
                css={{ ...styles.field, ...fieldStyles }}
                {...formState}
                {...field}
                {...rest}
                children={isSelect ? children : null}
                onChange={(e) => {
                  handleOnFieldChange(e.currentTarget.value)
                  return field.onChange(e)
                }}
                type={type}
                name={name}
              />
              {isSelect && (
                <Icon
                  icon="chevron"
                  className="select-chevron"
                  css={styles.dropdownChevron}
                />
              )}
            </Flex>
            {!isSelect && children}
          </Grid>
          <FieldStatus
            show={error && submitCount > 0}
            status={error}
            collapsable={collapseStatus}
          />
        </Element>
      )}
      {hidden && (
        <FormikField
          className={fieldClassNames}
          css={{ ...styles.field, ...fieldStyles }}
          {...formState}
          {...field}
          {...rest}
          children={isSelect ? children : null}
          type={type}
          name={name}
        />
      )}
    </>
  )
}

const computeStyles: TComputeStyles = (
  {
    PRIMARY_ALT,
    FIELD_BORDER,
    DISABLED,
    FIELD_DISABLED_BACKGROUND,
    FIELD_RADIO_UNSELECTED,
    FIELD_RADIO_SELECTED,
    TEXT_PRIMARY_LIGHT,
  }: TTheme,
  {
    FIELD_PADDING,
    FIELD_FONT_SIZE,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
  }: TSizes
) => ({
  root: {
    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: FIELD_BORDER_RADIUS,
    color: TEXT_PRIMARY_LIGHT,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    padding: FIELD_PADDING,
    position: 'relative',

    '&.is-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&.is-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&:focus-within': {
      border: `2px solid ${PRIMARY_ALT}`,
    },

    '&.is-radio': {
      display: 'grid',
      gridTemplateColumns: '1fr 21px',

      '& > :last-child': {
        width: 'auto',
      },
      '&.is-selected': {
        border: `2px solid ${FIELD_RADIO_SELECTED}`,
      },
    },

    '&.is-radio, &.is-checkbox': {
      border: 0,

      '&.is-disabled': {
        pointerEvents: 'none',
      },

      '&.is-loading': {
        backgroundColor: 'transparent',
        pointerEvents: 'none',
      },
    },
    '&.has-currency': {
      input: {
        paddingLeft: 15,
        width: 'calc(100% - 15px)',
      },
    },
  },
  currency: {
    fontSize: FIELD_FONT_SIZE - 1,
    left: 0,
    lineHeight: 1,
    position: 'absolute',
    textAlign: 'right',
    top: 46,
    width: 28,
  },

  field: {
    background: 'none',
    border: 0,
    flexGrow: 1,
    fontSize: FIELD_FONT_SIZE,
    outline: 0,
    width: '100%',
    '&.is-disabled': {
      '-webkit-text-fill-color': DISABLED,
      color: DISABLED,
    },

    '&.is-loading': {
      color: DISABLED,
    },
    '&.is-radio': {
      '-webkit-appearance': 'none',
      alignSelf: 'center',
      appearance: 'none',
      backgroundColor: 'transparent',
      border: `1px solid ${FIELD_RADIO_UNSELECTED}`,
      borderRadius: '50%',
      font: 'inherit',
      height: '20px',
      justifySelf: 'center',
      margin: 0,
      width: '20px',
      '&:checked': {
        border: `6px solid ${FIELD_RADIO_SELECTED}`,
      },
    },
  },
  fieldWrapper: {
    '&.is-select': {
      margin: 0,
      overflow: 'hidden',
      position: 'relative',
      '& .select-chevron': {
        transform: 'rotate(90deg)',
      },
      '&:hover:focus-within .select-chevron': {
        transform: 'rotate(-90deg)',
      },
    },
  },
  dropdownChevron: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    width: 14,
  },
})

export default Field
